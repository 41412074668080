import { useCallback, useRef, useState } from 'react'
import loadImage from 'blueimp-load-image'
import { b64toBlob } from '../../utils/convertToBlob'

export const useUpload = (setFilesNotUpload, setType, closeModal) => {
  const [image, setImage] = useState()
  const [isButtonModalDisabled, setIsButtonModalDisabled] = useState(false)
  const [isWebcamModal, setIsWebcamModal] = useState(false)
  const [preview, setPreview] = useState(null)

  const videoConstraints = {
    width: 640,
    height: 360,
    facingMode: 'user',
  }

  const webcamRef = useRef(null)

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImage(imageSrc)
  }, [webcamRef])

  const onDrop = (archive) => {
    const reader = new FileReader()

    reader.onload = async () => {
      loadImage(
        reader.result,
        (img) => {
          if (img && img.toDataURL && typeof img.toDataURL === 'function') {
            setPreview(img.toDataURL('image/jpeg'))
          } else if (img && img.currentSrc) {
            setPreview(img.currentSrc)
          }

          setFilesNotUpload([archive[0]])
          setType('camera')
        },
        {
          orientation: true,
          contain: true,
        },
      )

      setType('camera')
      setFilesNotUpload([archive[0]])
      setIsWebcamModal(false)

      closeModal?.()

      setIsButtonModalDisabled(false)
    }

    reader.readAsDataURL(archive[0])
  }

  const handleImage = () => {
    if (!image) {
      capture()
    } else {
      const blob = b64toBlob(image)
      setIsButtonModalDisabled(true)
      onDrop([blob])
    }
  }

  return [handleImage, image, webcamRef, videoConstraints, isButtonModalDisabled, isWebcamModal, setIsWebcamModal, preview]
}
