const numbers = {
  xs: 340,
  sm: 500,
  md: 800,
  lg: 1280,
  xl: 1920,
}

const media = {
  up: (bp) => `@media (min-width: ${numbers[bp]}px)`,
  down: (bp) => `@media (max-width: ${numbers[bp]}px)`,
}

export const theme = {
  colors: {
    orangePrimary: '#F56B00',
    orangePrimaryDark: '#D35C00',
    greyPrimary: '#575757',
    greyLight: '#CFCFCF',
    grey4: '#BDBDBD',
    white: '#FFFFFF',
    black: '#000000',
    orangeSecondary: '#FFD9BC',
    grey3: '#595E5E',
    grey2: '#E0E0E0',
    orange3: '#F4BD92',
    bluePrimary: '#0047FF',
    red500: '#F71701',
  },
  breakpoints: {
    ...numbers,
    ...media,
  },
}
