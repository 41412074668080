import { useEffect, useState } from 'react'
import { uploadImages } from '../../services/api'

export const useBasicInfo = (creditPath, goToNextScreen, setIsLoading, isMobile) => {
  const [files, setFiles] = useState([])
  const [filesUploaded, setFilesUploaded] = useState([])
  const [type, setType] = useState('upload')

  useEffect(() => {
    setFilesUploaded(creditPath?.readSelfie?.map(({ data, ...file }) => ({ url: data, ...file })))
  }, [creditPath])

  const onClickNextScreen = async () => {
    setIsLoading(true)
    const urls = await uploadImages(files)

    goToNextScreen({
      data: [...filesUploaded, ...urls],
      isMobile,
    })
  }

  return [onClickNextScreen, files, setFiles, filesUploaded, type, setType]
}
