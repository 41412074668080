import React, { useContext } from 'react'

import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import Button from '../../components/Button'
import { MobileHeader } from '../../components/MobileHeader'

import { useInvalidPathResponse } from './hooks'
import * as S from './styles'

export const InvalidPath = () => {
  const { creditPath, setIsLoadingGlobal } = useContext(TemplateContext)
  const [titleMessage, message, showButton] = useInvalidPathResponse(creditPath, setIsLoadingGlobal)

  return (
    <Template haveHeader>
      <MobileHeader />
      <S.Container>
        <S.Title>{titleMessage}</S.Title>

        <S.Subtitle>{message}</S.Subtitle>

        {showButton && (
          <S.MobileButtonWrapper>
            <Button
              text="Entrar em contato"
              footerButton
              onClick={() =>
                window
                  .open(
                    'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                    '_blank',
                  )
                  .focus()
              }
            />
          </S.MobileButtonWrapper>
        )}
      </S.Container>
    </Template>
  )
}
