import styled from 'styled-components'

export const Container = styled.header`
  height: 56px;
  background-color: ${(props) => props.theme?.colors?.greyPrimary};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 72px;
`
