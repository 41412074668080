import styled from 'styled-components'
import { theme } from '../../../theme'

export const Card = styled.div`
  max-width: 334px;
  height: 422px;

  border: 3px dashed ${theme.colors.orangeSecondary};
  box-sizing: border-box;
  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;

  margin-right: 20px;
`

export const CardTitle = styled.p`
  width: 100%;
  height: 28px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: 0.005em;
`

export const CardParagraph = styled.p`
  width: 292px;
  height: 72px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
`

export const CardButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;

  width: 184px;
  height: 40px;

  background: ${theme.colors.orangePrimary};
  border-radius: 4px;

  cursor: pointer;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;

  color: ${theme.colors.white};
`
