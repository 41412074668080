import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    padding: 30px 5%;
  }
`

export const Wrapper = styled.div`
  padding-top: 30px;
`

export const RowWrapper = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-between;
  padding-top: 30px;
  gap: 16px;

  ${theme.breakpoints.down('md')} {
    width: 100%;

    > input:nth-child(1) {
      margin-left: 16px;
    }
  }
`
