import React from 'react'
import { Modal } from '../Modal'
import Button from '../Button'

import VideoRecorder from '../video'

import * as S from './styles'

export const ModalVideo = ({
  handleVideo,
  setVideo,
  isButtonModalDisabled,
  setIsButtonModalDisabled,
  show,
  onDismiss,
  setType,
}) => {
  return (
    <>
      {show && (
        <Modal title="Gravar vídeo" show onDismiss={onDismiss}>
          <S.Wrapper>
            <S.WrapperWebcam>
              <VideoRecorder
                onRecordingComplete={(videoBlob) => {
                  setIsButtonModalDisabled(false)
                  setVideo(videoBlob)
                  setType('camera')
                }}
              />
            </S.WrapperWebcam>
            <S.WrapperButton>
              <Button text="Salvar vídeo" disabled={isButtonModalDisabled} onClick={() => handleVideo()} />
            </S.WrapperButton>
          </S.Wrapper>
        </Modal>
      )}
    </>
  )
}
