import styled from 'styled-components'
import { theme } from '../../../theme'

export const Container = styled.div`
  padding: 20px 72px 0 72px;
  ${theme.breakpoints?.down('sm')} {
    padding: 24px;
  }
`

export const ButtonBack = styled.a`
  cursor: pointer;
`

export const PageTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  margin: 25px 0 5px 0;
`

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  width: 320px;
  margin: 0 0 16px 0;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`

export const Title = styled.h2`
  margin: 40px 0;
  max-width: 580px;
  font-size: 24px;
  word-wrap: break-all;
  line-height: 24px;
`

export const ContainerInput = styled.div`
  margin-bottom: 20px;
`
