import React from 'react'

export const ImageUpload = () => (
  <svg width="108" height="83" viewBox="0 0 108 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="93" height="60" fill="#FFBF8E" />
    <rect x="14.5" y="23.5" width="92" height="59" fill="white" stroke="#767676" />
    <path
      d="M85.3577 43.1106L63.7154 57.3726L46.7726 54.4111C45.0412 54.0994 43.3098 54.3332 41.8258 54.9566L14 67.7379V82H107V51.8393L93.6436 43.1106C91.4176 41.6298 87.5838 41.6298 85.3577 43.1106Z"
      fill="white"
      stroke="#767676"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.2917 42.364C43.8311 38.6144 42.0394 34.3268 38.2898 32.7874C34.5402 31.2479 30.2525 33.0397 28.7131 36.7893C27.1737 40.5389 28.9655 44.8266 32.7151 46.366C36.4647 47.9054 40.7523 46.1136 42.2917 42.364Z"
      fill="white"
      stroke="#767676"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
