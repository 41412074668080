import styled, { css } from 'styled-components'

const dragActive = css`
  border-color: #f56b00;
`

const disableBorder = css`
  border: 3px dashed #ffd9bc;
`

const size = css`
  height: 311px;
  width: 385px;
`

const disabled = css`
  cursor: not-allowed;
`

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  border-radius: 20px;
  cursor: pointer;
  height: 94px;
  width: 334px;

  transition: height 0.5s;

  ${(props) => props.disabled && disabled};
  ${(props) => props.isDragActive && dragActive};
  ${(props) => !props.disableBorder && disableBorder};
  ${(props) => props.disableBorder && size};
`

export const UploadMessage = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  text-align: center;
  letter-spacing: 0.005em;

  color: #000000;
`

export const DefaultBackgroundContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`
