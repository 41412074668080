import React, { useContext } from 'react'

import { TemplateContext } from '../../components/template/context'
import Button from '../../components/Button'
import { Template } from '../../components/template'
import { history } from '../../navigation/history'

import { SanarProgressBar } from '../../components/SanarProgressBar'
import { CheckBox } from '../../components/Checkbox'

import { useGuarantorResume } from './hooks'
import * as S from './styles'

export const GuarantorResume = () => {
  const { setIsLoading, checkToken } = useContext(TemplateContext)
  const [isChecked, setIsChecked, resumeData, onConfirm] = useGuarantorResume(setIsLoading, checkToken)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar
          title="confirmação dos dados"
          percent={8}
          onClickBack={() => history.push(`/avalista/${localStorage.getItem('publicToken')}`)}
        />

        <S.Wrapper>
          <S.Title>Quem está te convidando para ser avalista:</S.Title>

          <S.InfoText>Nome: {resumeData.userName}</S.InfoText>
          <S.InfoText>CPF: {resumeData.userCPF}</S.InfoText>
          <S.InfoText>Data de Nascimento: {resumeData.userBithDate}</S.InfoText>
        </S.Wrapper>

        <S.Wrapper>
          <S.Title>Detalhes do recurso:</S.Title>

          <S.InfoText>Parceiro: {resumeData.partnerName}</S.InfoText>
          <S.InfoText>Produto: {resumeData.productName}</S.InfoText>
        </S.Wrapper>

        <S.Wrapper>
          <CheckBox
            text={
              <div onClick={(e) => e.stopPropagation()}>
                Li e estou de acordo com os{' '}
                <a target="_blank" rel="noopener noreferrer" no href="https://provi.com.br/termos-de-uso">
                  Termos de uso e a Política de privacidade
                </a>{' '}
                da Provi.
              </div>
            }
            checked={isChecked}
            onChange={() => setIsChecked((prevState) => !prevState)}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Button text="Confirmo" disabled={!isChecked} footerButton onClick={() => onConfirm()} />
        </S.Wrapper>
      </S.Container>
    </Template>
  )
}
