import styled, { css, keyframes } from 'styled-components'
import { theme } from '@provi/provi-components'

const size = css`
  height: 311px;
  width: 385px;
`

const border = css`
  border: 3px dashed #ffd9bc;
`

export const Container = styled.div`
  height: 94px;
  width: 334px;

  border-radius: 20px;

  transition: height 0.5s;

  position: relative;
  ${(props) => !props.file && border};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

  background-image: url(${({ file }) => file});
  background-repeat: no-repeat;
  background-size: cover;

  ${(props) => props.file && size};
`

export const animationHeight = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: 101%;
  }
`

export const Animation = styled.div`
  position: absolute;
  width: 100%;
  height: 101%;
  background-color: ${theme.colors.blue900};
  bottom: 0;
  margin-top: 10px;
  border-radius: 20px;
  animation-name: ${animationHeight};
  animation-duration: 3s;
  animation-timing-function: ease;

  ${theme.breakpoints.down('md')} {
    width: 101.3%;
  }
`

export const Text = styled.div`
  font-size: 12px;
  line-height: 30px;
  text-decoration: underline;
  color: ${theme.colors.blue900};
  width: 142px;
  text-align: center;
  font-family: 'CenturyGothicBold';
  margin: 15px;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
    line-height: 24px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;

  div {
    margin: 0 auto;
    border-radius: 8px;

    video {
      background-color: white;
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }
`

export const PreviewWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

export const Preview = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
`

export const WrapperButton = styled.div`
  margin: 20px auto !important;
`

export const WrapperWebcam = styled.div`
  width: 100%;
  height: 100%;
`
