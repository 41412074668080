import React from 'react'
import * as S from '../cardStyles'
import { theme } from '../../../../theme'

export const Footer = ({ collapsed, selected, labelFooter, labelInput, onClick, onChange }) => {
  return (
    <S.Footer background={selected ? theme.colors.orangePrimary : theme.colors.orangeSecondary}>
      {!collapsed ? (
        <>
          <S.RadioWrapper>
            <S.RadioInput>
              <input type="radio" value={labelInput} checked={selected} onChange={onChange} />
              <S.RadioControl />
            </S.RadioInput>
          </S.RadioWrapper>
          <S.LabelFooter onClick={onChange} color={selected && theme.colors.white}>
            {labelFooter}
          </S.LabelFooter>
        </>
      ) : (
        <S.LabelFooter onClick={onClick} width="100%" color={theme.colors.white}>
          Ver detalhes
        </S.LabelFooter>
      )}
    </S.Footer>
  )
}
