import React from 'react'
import * as S from '../cardStyles'

export const Description = ({ inconstantFlow, options }) => {
  return (
    <>
      {inconstantFlow
        ? options?.map(({ lastInstallment, initialInstallment, price: pricePortion }, index) =>
            lastInstallment == 1 ? (
              <S.Description key={index}>
                Primeira parcela de {pricePortion.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </S.Description>
            ) : (
              <S.Description key={index}>
                Parcela de {initialInstallment} a {lastInstallment} de{' '}
                {pricePortion.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </S.Description>
            ),
          )
        : options?.map((option, index) => <S.Description key={index}>{option}</S.Description>)}
    </>
  )
}
