import { useState, useEffect, useCallback } from 'react'
import moment from 'moment'

import { history } from '../../navigation/history'
import { getSlugPartner } from '../../utils/getSlugPartner'

export const useTeachingInfo = (creditPath, goToNextScreen) => {
  const [showNextButton, setShowNextButton] = useState(false)
  const [showInvalidInfoModal, setShowInvalidInfoModal] = useState(false)
  const [showInvalidDateModal, setShowInvalidDateModal] = useState(false)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [isSanarUpFinanciamento, setisSanarUpFinanciamento] = useState(false)
  const [rangeMessage, setRangeMessage] = useState(null)
  const [fields, setFields] = useState({
    medicineRadioValue: null,
    formationStep: null,
    previewDate: '',
    university: null,
    checkboxValue: false,
    financeRadioValue: null,
    financeName: '',
  })

  useEffect(() => {
    const partnerSlug = getSlugPartner()

    if (partnerSlug === 'sanarup-financiamento') setisSanarUpFinanciamento(true)
  }, [])

  const handleFormationStep = (value) => {
    const sanarUpHandler = {
      preIntern: { label: '3° e 4° ano', value: 'preIntern' },
      intern: { label: '5° e 6° ano', value: 'intern' },
      resident: { label: 'Residente', value: 'resident' },
    }

    const sanarUpFinanciamentoHandler = {
      intern: { label: '6° ano', value: 'intern' },
    }

    return isSanarUpFinanciamento ? sanarUpFinanciamentoHandler[value] : sanarUpHandler[value]
  }

  const handleValidation = useCallback(() => {
    const { previewDate, formationStep } = fields
    const { maxDateToAskAllowance, minDateToAskAllowance } = creditPath.readTeachingInformation
    const { maxPreIntern, minPreIntern, maxIntern, minIntern } = creditPath.readTeachingInformation

    if (isSanarUpFinanciamento) {
      const validationResultSanarUpFinanciamento = {
        intern: dateIsValid(previewDate, minDateToAskAllowance, maxDateToAskAllowance),
      }

      return !validationResultSanarUpFinanciamento[formationStep.value]
    }

    const validationResult = {
      preIntern: dateIsValid(previewDate, minPreIntern, maxPreIntern),
      intern: dateIsValid(previewDate, minIntern, maxIntern),
      resident: dateIsValid(previewDate, minIntern, maxPreIntern),
    }

    return !validationResult[formationStep.value]
  }, [creditPath, fields, isSanarUpFinanciamento])

  const handleCheckbox = useCallback(() => {
    if (!fields.checkboxValue) {
      setFields((prevState) => ({
        ...prevState,
        university: { value: null, label: 'Outro' },
        checkboxValue: !prevState.checkboxValue,
      }))
    } else {
      setFields((prevState) => ({
        ...prevState,
        university: null,
        checkboxValue: !prevState.checkboxValue,
      }))
    }
  }, [fields])

  useEffect(() => {
    if (creditPath) {
      const { readTeachingInformation } = creditPath

      if (isSanarUpFinanciamento) {
        setRangeMessage({
          minDate: readTeachingInformation?.minDateToAskAllowance,
          maxDate: readTeachingInformation?.maxDateToAskAllowance,
          show: false,
        })
      } else {
        setRangeMessage({
          minDate: readTeachingInformation?.minIntern,
          maxDate: readTeachingInformation?.maxPreIntern,
          show: false,
        })
      }

      setFields((prevState) => ({
        ...prevState,
        ...(readTeachingInformation?.choosedUniversityOptionId?.id && {
          university: {
            value: readTeachingInformation?.choosedUniversityOptionId?.id,
            label: readTeachingInformation?.universities.filter(
              (item) => readTeachingInformation?.choosedUniversityOptionId?.id === item.id,
            )[0].label,
          },
        }),
        ...(readTeachingInformation?.expectedGraduationDate && {
          previewDate: readTeachingInformation?.expectedGraduationDate,
        }),
        ...(readTeachingInformation?.existingStudentDebt?.data !== null && {
          financeRadioValue: readTeachingInformation?.existingStudentDebt?.data,
        }),
        ...(readTeachingInformation?.existingStudentDebt?.answer && {
          financeName: readTeachingInformation?.existingStudentDebt?.answer,
        }),
        formationStep: handleFormationStep(readTeachingInformation?.studentType),
      }))

      if (readTeachingInformation?.choosedUniversityOptionId?.answer === 'Outro' && !fields.checkboxValue) handleCheckbox()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditPath, isSanarUpFinanciamento])

  const dateIsValid = (userDate, minDate, maxDate) => {
    const date = moment(userDate, 'MM/YYYY')

    return date.isBetween(moment(minDate, 'MM/YYYY').subtract(1, 'month'), moment(maxDate, 'MM/YYYY').add(1, 'month'))
  }

  useEffect(() => {
    const { medicineRadioValue, formationStep, previewDate, university, financeRadioValue, financeName } = fields

    if (previewDate?.length === 7 && rangeMessage) {
      if (dateIsValid(previewDate, rangeMessage?.minDate, rangeMessage?.maxDate)) {
        setRangeMessage((prevState) => ({ ...prevState, show: false }))
      } else {
        setRangeMessage((prevState) => ({ ...prevState, show: true }))
      }
    }

    if (medicineRadioValue !== null && formationStep && previewDate?.length === 7 && university && financeRadioValue !== null) {
      if (financeRadioValue) {
        if (financeName?.length > 2) {
          setShowNextButton(true)
        } else setShowNextButton(false)
      } else if (financeRadioValue === false) setShowNextButton(true)
    } else setShowNextButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  const handleFields = (fieldName, value) => {
    setFields((prevProps) => ({
      ...prevProps,
      [fieldName]: value,
    }))
  }

  const validateFormationDate = useCallback(() => {
    const { university, financeName, financeRadioValue, checkboxValue, medicineRadioValue, formationStep, previewDate } = fields

    if (handleValidation()) return setShowInvalidDateModal(true)

    goToNextScreen({
      medicineStudent: medicineRadioValue,
      studentType: formationStep.value,
      expectedGraduationDate: previewDate,
      option: financeRadioValue,
      answer: financeRadioValue ? financeName : '',
      ...(!checkboxValue && { UniversityOptionId: university.value }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, goToNextScreen])

  const handleNextButton = useCallback(() => {
    const { formationStep, medicineRadioValue } = fields

    if (formationStep.value === 'not-allowed' || !medicineRadioValue) {
      if (isFirstTime) {
        setShowInvalidInfoModal(true)
        setIsFirstTime(false)
      } else {
        const partnerSlug = getSlugPartner()

        history.push(`/${partnerSlug}/entre-em-contato`)
      }
    } else {
      validateFormationDate()
    }
  }, [fields, isFirstTime, validateFormationDate])

  return [
    fields,
    handleFields,
    showNextButton,
    handleNextButton,
    showInvalidInfoModal,
    setShowInvalidInfoModal,
    showInvalidDateModal,
    setShowInvalidDateModal,
    handleCheckbox,
    rangeMessage,
    isSanarUpFinanciamento,
  ]
}
