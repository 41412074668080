import React from 'react'

export const ProviLogoNew = () => {
  return (
    <svg width="82" height="24" viewBox="0 0 82 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.8246 13.1639C39.8246 16.7517 37.1848 19.5211 33.9827 19.5211C32.344 19.5211 31.1458 18.9672 30.34 18.0517V23.9997H26.6721V7.14549H30.34V8.27799C31.1458 7.36247 32.344 6.80859 33.9827 6.80859C37.1848 6.80859 39.8246 9.57608 39.8246 13.1639ZM36.1568 13.1639C36.1568 11.3576 34.9335 10.227 33.2484 10.227C31.5613 10.227 30.34 11.3595 30.34 13.1639C30.34 14.9702 31.5632 16.1008 33.2484 16.1008C34.9354 16.1008 36.1568 14.9702 36.1568 13.1639Z"
        fill="#2647D7"
      />
      <path
        d="M49.2358 6.90381V10.996C47.7207 10.7562 45.5698 11.3577 45.5698 13.7407V19.1824H41.902V7.14554H45.5698V9.28872C46.0568 7.67467 47.6956 6.90381 49.2358 6.90381Z"
        fill="#2647D7"
      />
      <path
        d="M49.9683 13.1639C49.9683 9.57608 52.8515 6.80859 56.446 6.80859C60.0404 6.80859 62.9237 9.57798 62.9237 13.1639C62.9237 16.7517 60.0385 19.5211 56.446 19.5211C52.8535 19.5211 49.9683 16.7517 49.9683 13.1639ZM59.2577 13.1639C59.2577 11.4547 58.0345 10.3222 56.446 10.3222C54.8575 10.3222 53.6342 11.4547 53.6342 13.1639C53.6342 14.8731 54.8575 16.0056 56.446 16.0056C58.0364 16.0056 59.2577 14.8731 59.2577 13.1639Z"
        fill="#2647D7"
      />
      <path
        d="M76.3699 7.14551L71.8479 19.1842H67.693L63.171 7.14551H67.2292L69.7724 14.778L72.3155 7.14551H76.3699Z"
        fill="#2647D7"
      />
      <path
        d="M77.3478 3.91944C77.3478 2.73936 78.3507 1.75342 79.5469 1.75342C80.7432 1.75342 81.7461 2.74126 81.7461 3.91944C81.7461 5.09952 80.7432 6.08736 79.5469 6.08736C78.3507 6.08736 77.3478 5.09762 77.3478 3.91944ZM77.7149 7.14563H81.3828V19.1844H77.7149V7.14563Z"
        fill="#2647D7"
      />
      <path
        d="M21.3868 4.13615C16.7006 -1.1628 8.52807 -1.37026 3.56737 3.51375C1.16915 5.87772 -0.0173924 8.9802 0.00193249 12.077L0 12.0751V23.9996H12.107L12.1051 23.9977C15.4831 24.0186 18.8688 22.6672 21.3211 19.9378C25.3368 15.4668 25.3678 8.63759 21.3868 4.13615ZM15.4502 15.2175C13.6453 16.9952 10.7195 16.9952 8.91456 15.2175C7.10962 13.4398 7.10962 10.5581 8.91456 8.78034C10.7195 7.00261 13.6453 7.00261 15.4502 8.78034C17.2552 10.5581 17.2552 13.4398 15.4502 15.2175Z"
        fill="#2647D7"
      />
    </svg>
  )
}
