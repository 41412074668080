import React, { useContext } from 'react'

import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import { SanarProgressBar } from '../../components/SanarProgressBar'

import { useIntroDocument } from './hooks'
import { useGuarantorHooks } from './guarantorHooks'

import * as S from './styles'
import { isGuarantor } from '../../utils/isGuarantor'
import { history } from '../../navigation/history'

export const IntroDocument = () => {
  const { creditPath, goToNextScreen, checkToken } = useContext(TemplateContext)
  const isGuarantorScreen = isGuarantor()
  const [activeBtn, handleBtn] = useIntroDocument(creditPath, goToNextScreen)
  const [handleClick] = useGuarantorHooks(isGuarantorScreen, checkToken)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar
          title="Documento Pessoal"
          percent={isGuarantorScreen ? 60 : creditPath?.progress?.percentageCompleted}
          {...(isGuarantorScreen && { onClickBack: () => history.push('/avalista/inicio-documentos') })}
        />
        <S.Title>Primeiro precisamos de uma foto do seu documento</S.Title>
        <S.Subtitle>É importante que os dados do documento estejam legíveis</S.Subtitle>

        <S.DocTypeQuestion>Qual você prefere enviar?</S.DocTypeQuestion>
        <S.DocTypeContainer>
          <S.DocTypeButton
            isActive={activeBtn === 'RG'}
            onClick={isGuarantorScreen ? () => handleClick('RG') : () => handleBtn('RG')}
          >
            RG
          </S.DocTypeButton>
          <S.DocTypeButton
            isActive={activeBtn === 'CNH'}
            onClick={isGuarantorScreen ? () => handleClick('CNH') : () => handleBtn('CNH')}
          >
            CNH
          </S.DocTypeButton>
        </S.DocTypeContainer>
      </S.Container>
    </Template>
  )
}
