import React from 'react'

export const UploadCloud = () => (
  <svg width="157" height="105" viewBox="0 0 157 105" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1291:3854)">
      <path
        d="M139.309 57.7076C139.618 56.008 139.85 54.154 139.85 52.3772C139.85 36.3086 126.794 23.2529 110.726 23.2529C104.7 23.2529 99.1379 25.0298 94.58 28.1971C88.6315 13.9054 74.6488 3.86255 58.2713 3.86255C36.5633 3.86255 19.0269 21.3989 19.0269 43.1069C19.0269 43.5704 19.0269 44.0339 19.0269 44.5747C8.28884 47.6648 0.486328 57.4758 0.486328 69.141C0.486328 82.0422 10.0656 92.703 22.5033 94.4798V94.7888H137.996C148.193 94.7888 156.537 86.5228 156.537 76.2482C156.614 66.3599 148.966 58.4029 139.309 57.7076Z"
        fill="#CFCFCF"
        stroke="#CFCFCF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.309 54.2313C139.618 52.5317 139.85 50.6777 139.85 48.9009C139.85 32.8323 126.794 19.7766 110.726 19.7766C104.7 19.7766 99.1379 21.5534 94.58 24.7208C88.7088 10.4291 74.6488 0.38623 58.2713 0.38623C36.5633 0.38623 19.0269 17.9226 19.0269 39.6305C19.0269 40.0941 19.0269 40.5576 19.0269 41.0983C8.28884 44.1884 0.486328 53.9995 0.486328 65.6647C0.486328 78.5658 10.0656 89.2267 22.5033 91.0035V91.3125H137.996C148.193 91.3125 156.537 83.0465 156.537 72.7719C156.614 62.8836 148.966 54.9266 139.309 54.2313Z"
        fill="#FFBF8E"
        stroke="#CFCFCF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.892 39.9396H31.8496V103.982H95.892V39.9396Z"
        fill="white"
        stroke="#767676"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.9572 42.8751H34.8633V100.969H92.9572V42.8751Z"
        fill="#CFCFCF"
        stroke="#767676"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.438 62.4973L65.9188 76.6346L55.3352 73.699C54.2537 73.3899 53.1721 73.6217 52.2451 74.2397L34.8633 86.9091V101.046H92.9572V71.1496L84.6139 62.4973C83.2234 61.0295 80.8286 61.0295 79.438 62.4973Z"
        fill="white"
        stroke="#767676"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.155 61.3625C64.6944 57.6129 62.9027 53.3253 59.1531 51.7859C55.4034 50.2465 51.1158 52.0382 49.5764 55.7878C48.037 59.5375 49.8287 63.8251 53.5784 65.3645C57.328 66.9039 61.6156 65.1122 63.155 61.3625Z"
        fill="white"
        stroke="#767676"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.858 65.5102L103.54 39.8623L83.2227 65.5102H91.1797V104.059H115.901V65.5102H123.858Z"
        fill="#CFCFCF"
        stroke="#767676"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.995 65.5102L99.6006 39.8623L79.2832 65.5102H87.2402V104.059H112.038V65.5102H119.995Z"
        fill="white"
        stroke="#767676"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1291:3854">
        <rect width="156.9" height="104.445" fill="white" transform="translate(0.0996094)" />
      </clipPath>
    </defs>
  </svg>
)
