import React from 'react'
import Button from './button'

export const VideoInput = (shouldUseVideoInput, onOpenVideoInput, options, onTurnOnCamera) => {
  return shouldUseVideoInput ? (
    <Button onClick={onOpenVideoInput} data-qa="open-input">
      {(options && options.recordVideo) || 'Grava o vídeo'}
    </Button>
  ) : (
    <Button onClick={onTurnOnCamera} data-qa="turn-on-camera">
      {(options && options.turnOnCamera) || 'Ligar a câmera'}
    </Button>
  )
}
