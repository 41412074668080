import styled from 'styled-components'
import { theme } from '../../theme'

export const Container = styled.ul`
  height: 100%;
  width: 100%;
  > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
    height: 100%;
    width: 100%;
    & + li {
      margin-top: 15px;
    }
  }
`

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`

export const Preview = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 100%;
  width: 100%;
`

export const ContainerFeedback = styled.div`
  bottom: 0;
  background-color: rgb(0 0 0 / 70%);
  position: absolute;
  height: 30%;
  width: 100%;
  border-radius: 0 0 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextFeedback = styled.p`
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
  color: ${theme.colors.orangePrimary};
  font-size: 25px;
  display: flex;
  font-weight: 500;
  display: flex;
  align-items: center;
`
