import styled, { css } from 'styled-components'

import { theme } from '../../theme'

const ActiveButtonStyle = css`
  background: ${theme.colors.orangeSecondary};
  border-color: ${theme.colors.orangePrimary};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const Title = styled.h2`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 30px 0;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 24px;
    line-height: 34px;
  }
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  padding-bottom: 30px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const DocTypeContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 0;
`

export const DocTypeQuestion = styled.p`
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
`

export const DocTypeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;

  padding: 10px 0;

  width: 45%;
  border: 2px solid ${theme.colors.greyLight};
  border-radius: 6px;

  transition: 0.2s;

  &:hover {
    cursor: pointer;
  }

  ${({ isActive }) => isActive && ActiveButtonStyle}
`
