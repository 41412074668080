import React from 'react'
import * as S from './styles'

export const RenderDefault = (props) => {
  const { getInputProps, renderDragMessage, icon, isDragActive, title } = props

  return (
    <S.DropContainer {...props}>
      <input {...getInputProps()} />
      {renderDragMessage() || (
        <S.DefaultBackgroundContainer>
          {icon} <S.UploadMessage isDragActive={isDragActive}>{title}</S.UploadMessage>
        </S.DefaultBackgroundContainer>
      )}
    </S.DropContainer>
  )
}
