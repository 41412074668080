import axios from 'axios'

import { LOCALSTORAGE_TOKENS, ROOT_URL } from '../constants'
import * as Sentry from '@sentry/browser'

import firebase from './firebase'
import { toast } from 'react-toastify'
import { interceptApi } from '../utils/interceptApi'
import { getSlugPartner } from '../utils/getSlugPartner'
import { history } from '../navigation/history'

const api = axios.create({
  baseURL: ROOT_URL.credithpath_isa_allowance,
})

const userApi = axios.create({
  baseURL: ROOT_URL.user,
})

interceptApi(api)
interceptApi(userApi)

const partner = getSlugPartner()

export const getCreditPath = (partnerSlug, screenUniqueName) => {
  return new Promise(async (resolve, reject) => {
    const routeUniqueName = `/${screenUniqueName}`
    try {
      const { data } = await api.get(`/credit-paths/${partnerSlug}${screenUniqueName ? routeUniqueName : ''}`)

      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

export const postCreditPath = (body, partnerSlug, endpoint = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fullPath = endpoint || `/credit-paths/${partnerSlug}`
      const response = await api.post(fullPath, body)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

export const PhoneResendRewrite = (phone, partnerSlug) => {
  const body = {
    phone: phone,
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(`/rewrite-resend/${partnerSlug}`, body)
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

export const validateToken = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      api.defaults.headers.Authorization = token
      const response = await api.get('/auth')
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequestAuth',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

export const getAddressFromCep = async (zipCode) => {
  const cleanZipCode = zipCode.replace(/[^\d]+/g, '')

  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${cleanZipCode}/json/`)

    if (data?.erro) {
      throw new Error('CEP não encontrado.')
    }

    return data
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'errorCEP',
      message: 'ERROR INFO ' + err,
      level: Sentry.Severity.Warning,
    })
  }
}

export const uploadImages = async (array) => {
  let result = []
  await firebase.auth().signInAnonymously()
  const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
  if (array.length) {
    try {
      for (const item of array) {
        const now = new Date().getTime()

        const storagePath = `/${crid}/${now}`
        const uploadRef = firebase.storage().ref(storagePath)
        await uploadRef.put(item)

        const img = {
          url: await uploadRef.getDownloadURL(),
          typeExtension: item.type,
        }
        result.push(img)
      }
    } catch (err) {
      Sentry.captureException(err)

      toast.error('Não foi possivel enviar uma de suas imagens')
    }
  }
  return result
}

export const generateSignToken = (CreditRequestId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await userApi.post(`/provi-sign/${CreditRequestId}/generate/token`)

      resolve(data)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequest',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}

export const getCpfInfo = async () => {
  try {
    const { data } = await api.get(`/cpf/${partner}`)

    return data
  } catch (err) {
    if (err.response?.status === 401) history.push(`/${partner}/token-invalido`)
  }
}

export const sendCpfInfo = async (data) => {
  try {
    const response = await api.post(`/cpf/${partner}`, {
      data,
    })

    return response?.data
  } catch (err) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: 'ERROR INFO ' + err,
      level: Sentry.Severity.Warning,
    })
  }
}
