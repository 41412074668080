import React from 'react'

import { Modal } from '../../../components/Modal'
import { ModalText } from '../../cpf/components/CPFModal/styles'

export const ModalComponent = ({ showModal, setShowModal }) => {
  return (
    <Modal show={showModal} title="O que é o comprovante de matrícula?" onDismiss={() => setShowModal(false)}>
      <ModalText>
        O comprovante de matrícula é o documento que indicas as disciplinas nas quais você está matrículado, contendo todo o
        registro das suas atividades acadêmicas.
      </ModalText>
      <ModalText>
        Normalmente, a solicitação desse documento é feita no departamento de coordenação do curso ou pelo portal digital da sua
        universidade.
      </ModalText>
    </Modal>
  )
}
