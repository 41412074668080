import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import Button from '../../components/Button'
import { TemplateContext } from '../../components/template/context'
import { Subtitle } from '../../components/Texts'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { usePreDocument } from './hooks'
import { ModalComponent } from './Modal'

export const PreDocument = () => {
  const { creditPath, goToNextScreen, isMobile } = useContext(TemplateContext)
  const [modal, setModal] = usePreDocument()

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="DOCUMENTOS" canBack={false} />
        <S.ContainerInputs>
          <Subtitle>Para concluir a sua solicitação, você vai precisar enviar:</Subtitle>
          <S.List>
            <S.Item>Selfie com documento</S.Item>
            <S.Item>RG/CNH</S.Item>
            <S.Item>Comprovante de matrícula</S.Item>
            <S.Item>Histórico acadêmico</S.Item>
          </S.List>
          {isMobile && <S.Link onClick={() => setModal(true)}>O que são esses documentos?</S.Link>}
        </S.ContainerInputs>
        <Button text="Enviar documentos" disabled={false} footerButton onClick={() => goToNextScreen()} />
        {!isMobile && <S.Link onClick={() => setModal(true)}>O que são esses documentos?</S.Link>}
      </S.Container>
      <ModalComponent showModal={modal} setShowModal={setModal} />
    </Template>
  )
}
