import React from 'react'

export const TakePicture = () => (
  <svg width="62" height="58" viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.3221 12.5456C2.3221 8.75383 2.3221 4.96207 2.3221 1.1703C1.95056 1.54479 1.53258 1.9661 1.16105 2.3406C4.92285 2.3406 8.68464 2.3406 12.4464 2.3406C13.9326 2.3406 13.9326 0 12.4464 0C8.68464 0 4.92285 0 1.16105 0C0.510861 0 0 0.514931 0 1.1703C0 4.96207 0 8.75383 0 12.5456C0 14.0436 2.3221 14.0436 2.3221 12.5456Z"
      fill="#E0E0E0"
    />
    <path
      d="M12.4464 55.6594C8.68464 55.6594 4.92285 55.6594 1.16105 55.6594C1.53258 56.0339 1.95056 56.4552 2.3221 56.8297C2.3221 53.0379 2.3221 49.2462 2.3221 45.4544C2.3221 43.9564 0 43.9564 0 45.4544C0 49.2462 0 53.0379 0 56.8297C0 57.4851 0.510861 58 1.16105 58C4.92285 58 8.68464 58 12.4464 58C13.9326 58 13.9326 55.6594 12.4464 55.6594Z"
      fill="#E0E0E0"
    />
    <path
      d="M62.0005 12.5456C62.0005 8.75383 62.0005 4.96207 62.0005 1.1703C62.0005 0.514931 61.4896 0 60.8395 0C57.0777 0 53.3159 0 49.5541 0C48.0679 0 48.0679 2.3406 49.5541 2.3406C53.3159 2.3406 57.0777 2.3406 60.8395 2.3406C60.4679 1.9661 60.0499 1.54479 59.6784 1.1703C59.6784 4.96207 59.6784 8.75383 59.6784 12.5456C59.6784 14.0436 62.0005 14.0436 62.0005 12.5456Z"
      fill="#E0E0E0"
    />
    <path
      d="M49.5541 58C53.3159 58 57.0777 58 60.8395 58C61.4896 58 62.0005 57.4851 62.0005 56.8297C62.0005 53.0379 62.0005 49.2462 62.0005 45.4544C62.0005 43.9564 59.6784 43.9564 59.6784 45.4544C59.6784 49.2462 59.6784 53.0379 59.6784 56.8297C60.0499 56.4552 60.4679 56.0339 60.8395 55.6594C57.0777 55.6594 53.3159 55.6594 49.5541 55.6594C48.0679 55.6594 48.0679 58 49.5541 58Z"
      fill="#E0E0E0"
    />
    <path
      d="M9.24132 20.7377C9.38065 20.6909 9.33421 20.5036 9.38065 20.41C9.89151 18.678 11.3312 17.5077 13.096 17.5077C14.4428 17.5077 15.7896 17.5077 17.1365 17.5077C17.6938 17.5077 18.1117 17.3204 18.4833 16.9459C19.5979 15.7756 20.7589 14.6521 21.8735 13.4818C22.7095 12.6392 23.6848 12.2179 24.8923 12.2179C28.5147 12.2179 32.1836 12.2179 35.8061 12.2179C36.9672 12.2179 37.9889 12.6392 38.7784 13.4818C39.893 14.6521 41.0541 15.7756 42.1687 16.9459C42.5402 17.3672 43.0046 17.5545 43.5619 17.5545C44.8159 17.5545 46.1162 17.5545 47.3702 17.5545C49.7851 17.5545 51.4571 19.2397 51.4571 21.6739C51.4571 28.6957 51.4571 35.6707 51.4571 42.6925C51.4571 45.0799 49.7851 46.7651 47.4166 46.7651C35.9919 46.7651 24.5672 46.7651 13.1889 46.7651C11.2383 46.7651 9.84507 45.6885 9.28776 43.816C9.24132 43.7224 9.28776 43.6287 9.14844 43.5819C9.14844 40.82 9.14844 38.0113 9.14844 35.2494C9.28776 35.109 9.24132 34.9685 9.24132 34.8281C9.24132 32.5811 9.24132 30.3341 9.24132 28.0872C9.24132 25.9338 9.24132 23.8273 9.24132 21.6739C9.24132 21.5335 9.28776 21.3462 9.14844 21.2526C9.24132 21.0654 9.24132 20.9249 9.24132 20.7377ZM30.3724 44.2373C36.0383 44.2373 41.7042 44.2373 47.3702 44.2373C48.5312 44.2373 49.0885 43.7224 49.0885 42.5521C49.0885 35.5771 49.0885 28.6021 49.0885 21.6271C49.0885 20.5504 48.5312 19.9887 47.5095 19.9887C46.2091 19.9887 44.9087 19.9887 43.6084 19.9887C42.4473 19.9887 41.4256 19.5674 40.5896 18.7248C39.475 17.5545 38.314 16.431 37.1994 15.2607C36.8278 14.8394 36.3634 14.6521 35.8061 14.6521C32.2301 14.6521 28.6076 14.6521 25.0316 14.6521C24.4743 14.6521 24.0563 14.8394 23.6848 15.2139C22.5702 16.3842 21.4091 17.5077 20.2481 18.678C19.4121 19.5206 18.4368 19.9419 17.2293 19.9419C15.9754 19.9419 14.675 19.9419 13.4211 19.9419C12.26 19.9419 11.7492 20.5036 11.7492 21.6739C11.7492 28.6021 11.7492 35.5303 11.7492 42.4584C11.7492 43.7224 12.2136 44.2373 13.4675 44.2373C19.087 44.2373 24.7529 44.2373 30.3724 44.2373Z"
      fill="#F56B00"
    />
    <path
      d="M9.33507 28.0872C9.33507 30.3341 9.33507 32.5811 9.33507 34.8281C9.33507 34.9685 9.38151 35.1558 9.24219 35.2494C9.24219 32.862 9.24219 30.4746 9.24219 28.0872C9.28863 28.0872 9.28863 28.0872 9.33507 28.0872Z"
      fill="#F56B00"
    />
    <path
      d="M9.33507 28.0872C9.28863 28.0872 9.28863 28.0872 9.24219 28.0872C9.24219 25.7934 9.24219 23.5464 9.24219 21.2526C9.38151 21.3931 9.33507 21.5335 9.33507 21.6739C9.33507 23.7805 9.33507 25.9338 9.33507 28.0872Z"
      fill="#F56B00"
    />
    <path
      d="M14.489 12.2179C14.907 12.2179 15.3714 12.2179 15.7894 12.2179C16.486 12.2179 16.9969 12.7328 16.9969 13.3882C17.0433 14.0904 16.5789 14.6521 15.8822 14.699C14.907 14.7458 13.9317 14.7458 12.9564 14.699C12.3062 14.6521 11.8418 14.0436 11.8418 13.435C11.8882 12.7797 12.3991 12.2647 13.0493 12.2647C13.5601 12.2179 14.0246 12.2179 14.489 12.2179Z"
      fill="#F56B00"
    />
    <path
      d="M39.6156 30.8023C39.6156 35.9048 35.5287 40.071 30.4201 40.071C25.3115 40.071 21.2246 35.9516 21.2246 30.8023C21.2246 25.6998 25.3579 21.4867 30.4201 21.5335C35.4823 21.5335 39.6156 25.6529 39.6156 30.8023ZM37.1078 30.8023C37.1078 27.0573 34.089 24.0145 30.4201 24.0145C26.7048 24.0145 23.686 27.0573 23.686 30.8023C23.686 34.5472 26.7048 37.5432 30.4201 37.5432C34.1355 37.5432 37.1078 34.5472 37.1078 30.8023Z"
      fill="#F56B00"
    />
  </svg>
)
