import styled from 'styled-components'

import { theme as ProviTheme } from '@provi/provi-components'
import { theme } from '../../theme'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  ${ProviTheme.breakpoints.down('md')} {
    background: ${ProviTheme.colors.white100};
  }
`

export const Header = styled.header`
  max-width: 100vw;
  padding: 80px 72px;

  ${ProviTheme.breakpoints.down('md')} {
    padding: 40px 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;

  padding-left: 72px;

  display: flex;
  justify-content: center;

  max-width: 500px;
  height: 50%;

  ${ProviTheme.breakpoints.down('md')} {
    padding: 24px 24px 50px 24px;
    width: 100%;
    height: 80%;
    justify-content: space-evenly;
  }
`

export const Title = styled.h2`
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;

  padding-top: 30px;

  > span {
    color: ${theme.colors.orangePrimary};

    word-break: break-all;
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const SubTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  padding: 40px 0;
`

export const Link = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;

  color: ${theme.colors.orangePrimary};
  cursor: pointer;

  width: 100%;
  text-align: center;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 200px;
    text-align: start;
  }
`

export const ContainerLogo = styled.span`
  ${ProviTheme.breakpoints.down('md')} {
    display: none;
  }
  ${ProviTheme.breakpoints.down('sm')} {
    display: inline;
  }
`

export const ContainerLogoProvi = styled.span`
  position: absolute;
  bottom: 30px;
  left: 72px;

  @media screen and (max-width: 990px) {
    display: none;
  }
`
