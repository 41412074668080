import React from 'react'
import * as S from './styles'
import { ContainerFeedback, TextFeedback } from '../../../components/File/styles'

export const PreviewContainer = ({ preview }) => {
  return (
    <S.CardContainer file={preview}>
      <ContainerFeedback>
        <TextFeedback>Upload concluído</TextFeedback>
      </ContainerFeedback>
    </S.CardContainer>
  )
}
