import React from 'react'

import Button from '../../../../components/Button'
import InvalidDocument from '../../../../assets/images/invalid-document.png'
import * as S from './styles'

export const InvalidAccount = () => {
  return (
    <S.Container>
      <S.InvalidDocImg src={InvalidDocument} alt="" />
      <S.Wrapper>
        <S.Title>Não conseguimos validar os seus dados bancários.</S.Title>
        <S.Subtitle>Por esse motivo não conseguimos seguir com a sua solicitação.</S.Subtitle>
        <S.Description>Caso precise de ajuda, entre em contato com o nosso suporte.</S.Description>
        <Button
          text="Entrar em contato"
          width="220px"
          footerButton
          onClick={() =>
            window
              .open(
                'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                '_blank',
              )
              .focus()
          }
        />
      </S.Wrapper>
    </S.Container>
  )
}
