import styled from 'styled-components'
import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const List = styled.ul`
  list-style: none;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  margin-top: 20px;
  margin-bottom: 40px;

  letter-spacing: 0.0075em;
`

export const Item = styled.div`
  &:before {
    content: '';
    background-color: ${theme.colors.orange3};
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 10px;
  }
  display: flex;
  align-items: center;

  & + & {
    margin-top: 20px;
  }
`

export const Wrapper = styled.div`
  padding-top: 20px;
`
