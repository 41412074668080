import React from 'react'
import { UploadSelfie } from '../../../assets/svg/UploadSelfie'
import Upload from '../../../components/Upload'
import { useUpload } from '../../../utils/useUpload'

export const Mobile = ({ setFiles, filesUploaded }) => {
  const [onUpload, preview] = useUpload(setFiles)

  return (
    <Upload
      onUpload={onUpload}
      file={preview}
      filesUploaded={filesUploaded[filesUploaded.length - 1]}
      icon={<UploadSelfie />}
      title="Tirar a foto"
    />
  )
}
