import styled from 'styled-components'
import { theme } from '../../theme'

export const StyledInput = styled.div`
  div {
    label {
      div {
        border: 2px solid #cfcfcf;
        :focus-within {
          outline: 2px auto ${theme.colors.orangePrimary};
        }
        > input {
          ::placeholder {
            color: ${theme.colors.greyLight};
          }
        }
      }
    }
  }
`
