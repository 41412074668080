import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { history } from '../../navigation/history'
import { getGuarantorInfo, putGuarantorInfo } from '../../services/guarantor.api'

export const useGuarantorAddress = (checkToken, setIsLoading, isGuarantor) => {
  const [data, setData] = useState('')

  isGuarantor && checkToken()

  useEffect(() => {
    async function getData() {
      const { data: responseData } = await getGuarantorInfo()

      setData(responseData)
    }
    isGuarantor && getData()
  }, [isGuarantor])

  const guarantorNextScreen = async (fields) => {
    try {
      setIsLoading(true)

      await putGuarantorInfo({ ...data, address: fields })

      history.push('/avalista/inicio-documentos')
    } catch {
      toast.error('Houve ao adicionar endereço, tente novamente')
    } finally {
      setIsLoading(false)
    }
  }

  return { data, guarantorNextScreen }
}
