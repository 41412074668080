import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import Button from '../../components/Button'
import { TemplateContext } from '../../components/template/context'
import { useRGBack } from './hooks'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import Upload from '../../components/Upload'
import { RGBackIcon } from '../../assets/svg/RGBackIcon'
import { useUpload } from '../../utils/useUpload'
import { GuarantorHooks } from './guarantorHooks'
import { history } from '../../navigation/history'
import { isGuarantor } from '../../utils/isGuarantor'

export const RGBack = () => {
  const { creditPath, goToNextScreen, setIsLoading, checkToken } = useContext(TemplateContext)
  const isGuarantorScreen = isGuarantor()
  const [onClickNextScreen, files, setFiles, filesUploaded, setFilesUploaded] = useRGBack(
    creditPath,
    goToNextScreen,
    setIsLoading,
  )
  const [onUpload, preview] = useUpload(setFiles)
  const [handleSubmit] = GuarantorHooks(isGuarantorScreen, files, setFilesUploaded, setIsLoading, checkToken)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar
          percent={isGuarantorScreen ? 66.66 : creditPath?.progress?.percentageCompleted}
          title="DOCUMENTO PESSOAL"
          {...(isGuarantorScreen && { onClickBack: () => history.push('/avalista/selecionar-documento') })}
        />
        <S.Title>Envie a parte de trás do seu RG</S.Title>
        <S.Text>As informações devem estar legíveis</S.Text>
        <S.ContainerInputs>
          <Upload
            onUpload={onUpload}
            file={preview}
            filesUploaded={filesUploaded[filesUploaded.length - 1]}
            title="Fazer upload"
            icon={<RGBackIcon />}
          />
        </S.ContainerInputs>
        <Button
          text="Avançar"
          disabled={!files?.length && !filesUploaded.length}
          footerButton
          onClick={isGuarantorScreen ? handleSubmit : onClickNextScreen}
        />
      </S.Container>
    </Template>
  )
}
