import React from 'react'
import * as S from './styles'
import { useUpload } from './hooks'
import { TakePicture } from '../../assets/svg/TakePicture'
import { ModalCamera } from '../ModalCamera'
import { UploadMessage } from '../Upload/styles'
import { ContainerFeedback, TextFeedback } from '../File/styles'

export const WebcamWrapper = ({ disabled, setFilesNotUpload, type, setType }) => {
  const [handleImage, image, webcamRef, videoConstraints, isButtonModalDisabled, isWebcamModal, setIsWebcamModal, preview] =
    useUpload(setFilesNotUpload, setType)

  return (
    <>
      <ModalCamera
        image={image}
        webcamRef={webcamRef}
        videoConstraints={videoConstraints}
        isButtonModalDisabled={isButtonModalDisabled}
        handleImage={handleImage}
        setIsWebcamModal={setIsWebcamModal}
        isWebcamModal={isWebcamModal}
      />
      <S.Container disabled={disabled} file={preview} onClick={() => !disabled && setIsWebcamModal(true)}>
        {!preview || !type === 'camera' ? (
          <>
            <TakePicture />
            <UploadMessage>Tirar a foto</UploadMessage>
          </>
        ) : (
          <ContainerFeedback>
            <TextFeedback>Upload concluído</TextFeedback>
          </ContainerFeedback>
        )}
      </S.Container>
    </>
  )
}
