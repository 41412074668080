import React from 'react'
import { FeedbackIcon } from '../../assets/svg/FeedbackIcon'
import { FlexWrapper, TextFooter } from '../../screensGuarantor/upload/Card/styles'
import { TextFeedback } from '../File/styles'
import * as S from './styles'

export const GuarantorUploaded = ({ props, icon, onClickFooter, getInputProps }) => {
  return (
    <S.GuarantorContainerUploaded isVideo disableCursor {...props}>
      <input {...getInputProps()} />
      {icon}
      <FlexWrapper>
        <FeedbackIcon />
        <TextFeedback>Upload concluído</TextFeedback>
      </FlexWrapper>
      <TextFooter onClick={onClickFooter}>Gravar outro vídeo</TextFooter>
    </S.GuarantorContainerUploaded>
  )
}
