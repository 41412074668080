import React from 'react'

import { Modal } from '../../../components/Modal'
import { ModalText } from '../../../screens/cpf/components/CPFModal/styles'

export const ModalComponent = ({ showModal, setShowModal }) => {
  return (
    <Modal show={showModal} title="Por que precisamos desse comprovante?" onDismiss={() => setShowModal(false)}>
      <ModalText>Essa parte do processo é muito importante para a sua segurança digital.</ModalText>
      <ModalText>
        Através da foto ou vídeo anti-fraude nós conseguimos confirmar que é você mesmo quem está preenchendo essa solicitação e
        assim evitamos qualquer tipo de fraude.
      </ModalText>
    </Modal>
  )
}
