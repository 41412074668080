import React from 'react'
import { MadePossibleProvi } from '../../assets/svg/MadePossibleProvi'
import { SanarLogo } from '../../assets/svg/SanarLogo'

import * as S from './styles'

const Header = () => {
  return (
    <S.Container>
      <SanarLogo />
      <MadePossibleProvi />
    </S.Container>
  )
}

export default Header
