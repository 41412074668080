import React, { forwardRef } from 'react'

import * as S from './styles'

export const LargeText = forwardRef((props, ref) => {
  return (
    <S.LargeText ref={ref} {...props}>
      {props.children}
    </S.LargeText>
  )
})
