import styled from 'styled-components'

import { theme } from '../../../src/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  color: ${theme.colors.black};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 10px 0;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 20px;
  }
`

export const ArrowWrapper = styled.div`
  cursor: pointer;
`
