import styled from 'styled-components'
import { theme } from '../../theme'

export const ContainerInputs = styled.div`
  padding-top: ${({ paddingTop }) => paddingTop + 'px'};
  display: flex;
  flex-direction: column;
`

export const Link = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: ${theme.colors.orangePrimary};
  cursor: pointer;
  margin-top: 30px;
`

export const Wrapper = styled.div`
  padding-top: 30px;
`
