import { useEffect, useState } from 'react'

import { LOCALSTORAGE_TOKENS, userBlockedReasons } from '../../constants'
import { removeMask } from '../../utils/removeMask'
import { getCpfInfo, sendCpfInfo } from '../../services/api'
import { getPathname } from '../../utils/getPathName'
import { navigateWithParams } from '../../utils/navigateWithParams'
import { getSlugPartner } from '../../utils/getSlugPartner'

export const useCpf = (setIsLoading) => {
  const [value, setValue] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    async function getInfo() {
      const data = await getCpfInfo()
      setValue(data?.data?.data)
    }

    getInfo()
  }, [])

  const onClickNextScreen = async () => {
    setIsLoading(true)
    const response = await sendCpfInfo(removeMask(value))

    const partner = getSlugPartner()

    setIsLoading(false)
    if (response?.userCanContinue === false) {
      return navigateWithParams(`/${partner}/elegibilidade/${userBlockedReasons[response?.reason]}`)
    }

    if (response?.currentScreen && response?.token) {
      localStorage.setItem(LOCALSTORAGE_TOKENS.token, response?.token)
      const pathname = getPathname(response?.currentScreen)

      window.location.href = `/${partner}/${pathname}`
    }
  }

  return [value, setValue, onClickNextScreen, isChecked, setIsChecked, showModal, setShowModal]
}
