import styled, { css } from 'styled-components'
import { theme } from '../../../theme'

const border = css`
  border: 3px dashed ${theme.colors.orangeSecondary};
`

const disableCursorComponent = css`
  cursor: auto;
`

export const CardContainer = styled.div`
  height: 246px;
  width: 334px;

  border-radius: 20px;

  margin-top: 40px;

  transition: height 0.5s;

  position: relative;

  ${(props) => !props.file && border};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ disableCursor }) => disableCursor && disableCursorComponent}

  background-image: url(${({ file }) => file});
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  padding: 20px;

  > svg {
    margin-top: 20px;
  }
`

export const CardTitle = styled.div`
  width: 100%;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;

  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;

  color: ${theme.colors.orangePrimary};
`

export const FlexWrapper = styled.div`
  display: flex;

  align-items: center;

  > svg {
    margin-right: 10px;
  }
`

export const TextFooter = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  cursor: pointer;

  color: ${theme.colors.greyPrimary};
`
