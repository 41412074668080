import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;

  div {
    margin: 0 auto;
    border-radius: 8px;

    video {
      background-color: white;
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }
`

export const WrapperButton = styled.div`
  margin: 20px auto !important;
`

export const WrapperWebcam = styled.div`
  width: 100%;
  height: 100%;
`
