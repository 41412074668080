import styled from 'styled-components'
import medicCheers from '../../assets/images/medic-cheers.png'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 94vh;

  background: url(${medicCheers});
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    padding: 1% 5%;
  }

  @media (max-width: ${theme.breakpoints.md}px) {
    background: none;
    justify-content: flex-start;
  }
`

export const MobileHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

  @media (min-width: ${theme.breakpoints.md}px) {
    display: none;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  color: ${theme.colors.orangePrimary};
  font-size: 28px;
  line-height: 38px;

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 36px;
    line-height: 46px;
  }
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 26px;
    line-height: 36px;
  }
`

export const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  & + & {
    padding-top: 15px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const Disclaimer = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 23px;

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 16px;
    line-height: 36px;
  }
`

export const Wrapper = styled.div`
  padding-top: 30px;

  @media (min-width: ${theme.breakpoints.md}px) {
    max-width: 40%;
  }
`
