import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { getCreditPath, postCreditPath } from '../../services/api'
import { getPathname } from '../../utils/getPathName'
import { getSlugPartner } from '../../utils/getSlugPartner'
import * as Sentry from '@sentry/browser'
import { navigateWithParams } from '../../utils/navigateWithParams'
import { LOCALSTORAGE_TOKENS } from '../../constants'
import { ERRORS } from '../../constants/errors'
import { JSONPARSER } from '../../utils/logger'
import { history } from '../../navigation/history'
import { isGuarantor } from '../../utils/isGuarantor'

export const useToken = () => {
  const checkToken = () => {
    const check = localStorage.getItem('guarantorToken')

    if (!check) {
      history.push(`/avalista/${localStorage.getItem('publicToken')}`)
    }
  }

  return [checkToken]
}
export const writeIncompleteAnalysis = async () => {
  try {
    const {
      data: { nextScreen },
    } = await postCreditPath({}, getSlugPartner(), '/retry/eligibility')
    const pathname = getPathname(nextScreen)

    navigateWithParams(`/${getSlugPartner()}/${pathname}`)
  } catch ({ response }) {
    const message = response.data?.errors?.[0]?.message || response.data?.error?.message

    return { ...response, message }
  }
}

export const useSendingInfo = (creditPath, setCreditPath, setIsLoading) => {
  const goToNextScreen = async (data = {}, showLoading = true) => {
    if (showLoading) setIsLoading(true)

    try {
      const {
        data: { nextScreen },
      } = await postCreditPath(data, getSlugPartner())
      const response = await getCreditPath(getSlugPartner())
      setCreditPath(response)
      Sentry.addBreadcrumb({
        category: 'goToNextScreen',
        message: 'Next Screen ' + nextScreen,
        level: Sentry.Severity.Info,
      })
      const pathname = getPathname(nextScreen)

      navigateWithParams(`/${getSlugPartner()}/${pathname}`)
    } catch ({ response }) {
      const message = response.data?.errors?.[0]?.message || response.data?.error?.message

      return { ...response, message }
    } finally {
      setIsLoading(false)
    }
  }

  const goToPreviousScreen = async () => {
    setIsLoading(true)
    const previousScreen = creditPath && creditPath.progress && creditPath.progress.previousScreenUniqueName

    try {
      const response = await getCreditPath(getSlugPartner(), previousScreen)
      setCreditPath(response)
      Sentry.addBreadcrumb({
        category: 'goToPreviousScreen',
        message: 'Previous Screen ' + previousScreen,
        level: Sentry.Severity.Info,
      })

      const previousPathname = getPathname(previousScreen)
      navigateWithParams(`/${getSlugPartner()}/${previousPathname}`)
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error.message)
        return false
      }
    } finally {
      setIsLoading(false)
    }
  }
  return [goToNextScreen, goToPreviousScreen]
}

export const useCreditPath = (setIsLoadingGlobal, setCreditPath) => {
  useEffect(() => {
    const getData = async (firstTry) => {
      const isGuarantorScreen = isGuarantor()
      const paths = ['/cpf', '/elegibilidade']
      const screenRedirectBypass = paths.filter((path) => window?.location.pathname.includes(path))

      if (isGuarantorScreen || screenRedirectBypass.length > 0) return setIsLoadingGlobal(false)

      const dataUser = JSONPARSER(localStorage.getItem(LOCALSTORAGE_TOKENS.dataUser))
      const partnerSlug = dataUser?.partnerSlug || 'sanar'

      try {
        setIsLoadingGlobal(true)
        const response = await getCreditPath(partnerSlug)
        setCreditPath(response)
        const pathname = getPathname(response.progress.currentScreenUniqueName)
        navigateWithParams(`/${partnerSlug}/${pathname}`)

        setIsLoadingGlobal(false)
      } catch (error) {
        return setTimeout(async () => {
          if (firstTry) {
            return getData(false)
          }

          if (error.response?.data.errors[0].message === ERRORS.financingNotFound) {
            navigateWithParams(`/${partnerSlug}/cpf`)
          } else {
            navigateWithParams(`/${partnerSlug}/token-invalido`)
          }

          setIsLoadingGlobal(false)
        }, 2000)
      }
    }
    getData(true)
  }, [setIsLoadingGlobal, setCreditPath])
}
