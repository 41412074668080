import React from 'react'

export const UploadIcon = () => (
  <svg width="71" height="58" viewBox="0 0 71 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_173:1243)">
      <path
        d="M44.8631 23.3162C42.1579 20.9514 38.0794 20.9099 35.3742 23.2332L23.5964 33.3147C23.3467 33.5222 23.0137 33.5222 22.764 33.3562L14.5653 27.5479C12.7757 26.3033 10.3619 26.5522 8.94689 28.2117L1.58053 36.6753C1.66377 37.1316 1.66377 37.588 1.58053 38.0858C1.53892 38.3763 1.4973 39.2475 1.41406 39.7039C1.45568 39.7039 1.53892 39.7039 1.58053 39.7039C1.83024 39.7039 2.03833 39.7454 2.24642 39.7868V39.4549L10.6532 29.7468C11.2775 29 12.3595 28.8755 13.1503 29.4564L21.3906 35.2647C22.5143 36.0944 24.0542 36.0115 25.1362 35.0987L36.9141 25.0172C38.7452 23.4406 41.492 23.4406 43.3232 25.0587L43.9475 25.6395L44.1972 25.4735C44.3636 25.3906 44.4885 25.3076 44.6134 25.2246C44.9463 25.1001 45.2792 24.9757 45.6538 24.8927L46.4445 24.7268L44.8631 23.3162Z"
        fill="#F56B00"
      />
      <path
        d="M29.0897 20.9514C32.7937 20.9514 35.8318 17.9227 35.8318 14.2303C35.8318 10.5379 32.7937 7.5093 29.0897 7.5093C25.3858 7.5093 22.3477 10.5379 22.3477 14.2303C22.3477 17.9227 25.3858 20.9514 29.0897 20.9514ZM24.7199 14.2303C24.7199 11.824 26.6759 9.83262 29.1314 9.83262C31.5452 9.83262 33.5428 11.7825 33.5428 14.2303C33.5428 16.6366 31.5868 18.628 29.1314 18.628C26.6759 18.5866 24.7199 16.6366 24.7199 14.2303Z"
        fill="#F56B00"
      />
      <path
        d="M45.5713 35.3891C45.4465 35.4721 45.3632 35.5551 45.3216 35.6381L42.2835 38.6252C42.0754 38.8326 41.9922 39.0816 41.9922 39.372C41.9922 39.6624 42.0754 39.9113 42.2835 40.1187C42.4916 40.3262 42.7413 40.4092 43.0326 40.4092C43.324 40.4092 43.5737 40.2847 43.7401 40.1187L45.1135 38.7496V44.309C45.1135 44.8898 45.5713 45.3462 46.154 45.3462C46.7366 45.3462 47.1944 44.8898 47.1944 44.309V38.7496L48.5678 40.1187C48.984 40.5336 49.6499 40.5336 50.0244 40.1187C50.2325 39.9113 50.3158 39.6624 50.3158 39.372C50.3158 39.0816 50.1909 38.8326 50.0244 38.6252L46.154 34.7668L45.5713 35.3891Z"
        fill="#F56B00"
      />
      <path
        d="M53.0204 41.9027C52.4378 41.9027 51.98 42.3591 51.98 42.9399L52.0216 47.0887H40.327V42.9399C40.327 42.3591 39.8692 41.9027 39.2865 41.9027C38.7039 41.9027 38.2461 42.3591 38.2461 42.9399V47.2132C38.2461 48.2918 39.1201 49.1631 40.2021 49.1631H52.0216C53.1453 49.1631 54.0609 48.2504 54.0609 47.1302V42.9399C54.0609 42.3591 53.6031 41.9027 53.0204 41.9027Z"
        fill="#F56B00"
      />
      <path
        d="M70.7512 45.0143C69.7108 40.0773 65.2993 36.7582 60.3468 36.5923C59.8057 36.5923 59.2231 36.5923 58.682 36.5923C57.8497 32.319 55.2694 28.7096 51.6902 26.5937V10.0401C51.6902 7.96566 51.7735 5.89127 51.6902 3.81688C51.5238 0.912731 49.2348 0.0414864 46.7793 0.0414864H24.1809C17.4804 0.0414864 10.7383 -0.165953 3.99623 0.0414864C1.41592 0.16595 0.0425365 1.94993 0.000918685 4.4392C-0.0406991 7.09442 0.000918685 9.79113 0.000918685 12.4464V38.9156C0.000918685 41.6538 -0.0406991 44.4335 0.000918685 47.1717C0.0425365 49.6609 1.29107 51.5279 3.95461 51.6109C8.86551 51.7768 13.7764 51.6938 18.7289 51.6524C20.2688 55.0129 23.5566 57.3777 27.2606 57.7926C28.8004 57.9585 30.3819 57.8755 31.9218 57.8755C38.4142 57.8755 44.8649 57.8755 51.3573 57.8755C55.7272 57.8755 61.5537 58.7468 65.549 56.5064C69.5859 54.2246 71.7084 49.495 70.7512 45.0143ZM8.69904 49.5365C7.11757 49.5365 5.28638 49.7854 3.7049 49.5365C1.45754 49.1631 2.08181 46.01 2.08181 44.3505V9.91559C2.08181 7.96566 1.8321 5.89127 2.08181 3.94134C2.37313 1.701 4.70373 2.15737 6.36844 2.15737H40.5783C42.8257 2.15737 45.1979 1.94993 47.4869 2.15737C49.6926 2.36481 49.6094 3.94134 49.6094 5.64234V25.5565C47.9863 24.9342 46.2383 24.5608 44.4071 24.5608C37.2489 24.5608 31.464 29.7883 30.1322 36.5508C26.886 36.5923 23.8895 36.9657 21.3092 39.289C18.4792 41.8197 17.2723 45.8026 18.0631 49.4535H8.69904V49.5365ZM62.2612 55.5522C59.015 56.299 55.1861 55.7597 51.94 55.7597C46.4048 55.7597 40.9112 55.7597 35.3761 55.7597C31.8385 55.7597 27.1774 56.4235 24.0144 54.5565C19.9359 52.1087 18.6041 46.6323 21.1844 42.608C23.4734 39.0401 27.0941 38.6667 30.923 38.6667C31.5472 38.6667 31.8802 38.1688 31.9634 37.6295C32.7541 31.4478 38.0812 26.6767 44.3655 26.6767C50.6498 26.6767 55.9353 31.4478 56.7676 37.6295C56.8509 38.1688 57.1838 38.6667 57.8081 38.6667C58.6404 38.6667 59.5144 38.6667 60.3468 38.6667C64.7999 38.7911 68.5871 42.1102 68.8784 46.6323C69.1697 50.7811 66.3397 54.6395 62.2612 55.5522Z"
        fill="#E0E0E0"
      />
    </g>
    <defs>
      <clipPath id="clip0_173:1243">
        <rect width="71" height="58" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
