import { useEffect } from 'react'
import { history } from '../../navigation/history'

export const useGuarantorHooks = (isGuarantor, checkToken) => {
  useEffect(() => {
    isGuarantor && checkToken()
  }, [isGuarantor, checkToken])

  const handleClick = (type) => {
    if (!type) return

    const obj = {
      RG: () => history.push('/avalista/rg-parte-tras'),
      CNH: () => history.push('/avalista/carteira-nacional-habilitacao'),
    }

    return obj[type]()
  }

  return [handleClick]
}
