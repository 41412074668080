import React, { useContext } from 'react'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import { useGuarantorNegativeFeedback } from './hooks'

import * as S from './styles'
import { LinkBottom } from '../../components/LinkBottom'
import { ModalComponent } from './Modal'

export const GuarantorNegativeFeedback = () => {
  const { creditPath, isMobile, goToNextScreen } = useContext(TemplateContext)

  const [onClickNextScreen, modal, setModal, text] = useGuarantorNegativeFeedback(creditPath, goToNextScreen)

  return (
    <Template>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="AVALISTA " />
        <S.Title>Infelizmente, você não pode seguir {!isMobile && <br />} com esse avalista.</S.Title>
        <S.Text>
          Você ainda pode tentar mais {text} como avalista.{!isMobile && <br />} Lembre-se, o avalista precisa ter um bom
          histórico {!isMobile && <br />} de crédito.
        </S.Text>
        <LinkBottom
          isMobile={isMobile}
          onClick={onClickNextScreen}
          onClickLink={() => setModal(true)}
          textLink="Por que precisamos de outro avalista?"
          text="Adicionar outro"
        />
      </S.Container>
      <ModalComponent showModal={modal} setShowModal={setModal} />
    </Template>
  )
}
