import { theme } from '@provi/provi-components'
import styled, { css } from 'styled-components'
import { theme as localThemes } from '../../theme'

const dragActive = css`
  border: 3px dashed #f56b00;
`

const border = css`
  border: 3px dashed #ffd9bc;
`

const color = css`
  color: #f56b00;
`

const disableCursorComponent = css`
  cursor: auto;
`

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  border-radius: 20px;
  cursor: pointer;
  height: 311px;

  transition: border 0.5s;

  @media (min-width: ${theme.breakpoints.md}px) {
    width: 385px;
  }

  ${(props) => !props.disableBorder && border};
  ${(props) => props.isDragActive && dragActive};
`

export const GuarantorContainer = styled(DropContainer)`
  height: 246px;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  margin-top: 40px;
  width: 100%;

  border: 3px dashed #ffd9bc;

  @media (min-width: ${theme.breakpoints.md}px) {
    width: 334px;

    margin-right: 20px;
  }
`

export const GuarantorContainerUploaded = styled(GuarantorContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;

  ${({ disableCursor }) => disableCursor && disableCursorComponent}
`

export const UploadMessage = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  text-align: center;
  letter-spacing: 0.005em;

  transition: color 0.5s;

  ${(props) => props.isDragActive && color};
`

export const DefaultBackgroundContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
`

export const ContainerDefault = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  padding: 20px;

  > svg {
    margin-top: 20px;
  }

  > p {
    width: 100%;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;

    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;

    color: ${localThemes.colors.orangePrimary};
  }
`
