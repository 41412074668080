import React, { useContext } from 'react'

import { Template } from '../../components/template'
import Button from '../../components/Button'
import { InputSanar } from '../../components/InputSanar'
import { TemplateContext } from '../../components/template/context'
import { SanarProgressBar } from '../../components/SanarProgressBar'

import { AvalistaModal } from './components/AvalistaModal'
import { useGuarantorCPF } from './hooks'
import * as S from './styles'

export const GuarantorCpf = () => {
  const { goToNextScreen, creditPath } = useContext(TemplateContext)
  const [value, setValue, onClickNextScreen, showModal, setShowModal] = useGuarantorCPF(goToNextScreen)

  return (
    <Template haveHeader>
      <S.Container>
        <div>
          <SanarProgressBar title="Indicação Avalista" percent={creditPath?.progress?.percentageCompleted} />

          <S.Wrapper>
            <S.Title>Qual o CPF do seu avalista?</S.Title>
          </S.Wrapper>

          <S.Wrapper>
            <S.Description>
              Você pode indicar até 3 CPF’s diferentes, então lembre-se de colocar alguém próximo com bom histórico de crédito.
            </S.Description>
          </S.Wrapper>

          <S.Wrapper>
            <InputSanar
              placeholder="000.000.000-00"
              mask="999.999.999-99"
              width="80%"
              onChange={(e) => setValue(e.target.value)}
              value={value}
              label="CPF"
            />
          </S.Wrapper>
        </div>

        <div>
          <S.ButtonWrapper>
            <Button text="Avançar" disabled={value?.length < 14} footerButton onClick={() => onClickNextScreen()} />
          </S.ButtonWrapper>

          <S.Link onClick={() => setShowModal(true)}>O que é um avalista?</S.Link>
        </div>
      </S.Container>

      <AvalistaModal showModal={showModal} setShowModal={setShowModal} />
    </Template>
  )
}
