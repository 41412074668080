import React from 'react'

import { Template } from '../../components/template'
import { Title } from '../../components/Texts'

import * as S from './styles'

export const Home = () => {
  return (
    <Template haveHeader>
      <S.Container>
        <Title>Home</Title>
      </S.Container>
    </Template>
  )
}
