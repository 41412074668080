import styled from 'styled-components'

import { theme } from '../../../../theme'

export const ModalText = styled.p`
  color: ${theme.colors.black};
  font-size: 14px;
  margin-bottom: 20px;

  > span {
    font-weight: bold;
  }
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
`

export const LinkWrapper = styled.div`
  margin-top: 40px;
`
