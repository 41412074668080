import { useState, useCallback, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'

import { messageLimitError, statusCodeTimeout, messages } from './constants'
import { getSlugPartner } from '../../utils/getSlugPartner'

export const useBankAccountScreen = (creditPath, goToNextScreen) => {
  const [showNextButton, setShowNextButton] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [isChecking, setIsChecking] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)
  const [bankOptions, setBankOptions] = useState([])
  const [isSanarUpFinanciamento, setisSanarUpFinanciamento] = useState(false)
  const [fields, setFields] = useState({
    bank: null,
    agency: '',
    agencyDigit: '',
    account: '',
    accountDigit: '',
    accountType: null,
  })
  const count = useRef(0)

  useEffect(() => {
    const partnerSlug = getSlugPartner()

    if (partnerSlug === 'sanarup-financiamento') setisSanarUpFinanciamento(true)
  }, [])

  useEffect(() => {
    const { bank, agency, account, accountDigit, accountType } = fields

    if (bank && agency?.length > 3 && account?.length > 3 && accountDigit && accountType) {
      setShowNextButton(true)
    } else setShowNextButton(false)
  }, [fields])

  useEffect(() => {
    const current = creditPath?.readBankAccount?.current
    setBankOptions(creditPath?.readBankAccount?.options)

    if (current)
      setFields((prevState) => ({
        ...prevState,
        ...(current?.FinancialInstitution && {
          bank: {
            value: current?.FinancialInstitution?.id,
            label: current?.FinancialInstitution?.name,
          },
          ...current,
        }),
      }))
  }, [creditPath])

  const handleFields = useCallback((fieldName, value) => {
    setFields((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }))
  }, [])

  const messageLimitHandler = () => {
    setIsChecking(false)
    setIsBlocked(true)
  }

  const handleNextButton = useCallback(async () => {
    const { bank, agency, agencyDigit, account, accountDigit, accountType } = fields
    setIsChecking(true)
    setShowWarningModal(false)

    try {
      const error = await goToNextScreen(
        {
          FinancialInstitutionId: bank.value,
          agency,
          account,
          accountDigit,
          accountType,
          agencyDigit: agencyDigit.length > 0 ? agencyDigit : '0',
          noVerify: count.current >= 2,
        },
        false,
      )

      if (error) throw new Error(JSON.stringify(error))

      setIsChecking(false)
    } catch (err) {
      const { message, status } = JSON.parse(err.message)

      const retryPostBankAccount = () => {
        toast.warning('Aguarde enquanto validamos suas informações...')
        count.current = count.current + 1
        return handleNextButton()
      }

      const errorHandler = {
        [messageLimitError]: messageLimitHandler,
        [statusCodeTimeout]: count.current < 2 && retryPostBankAccount,
      }

      const errorCallback = errorHandler[message] || errorHandler[status]

      if (errorCallback) return errorCallback()

      if (!messages.includes(message)) {
        toast.error('Ops! Alguma coisa deu errado na validação dos seus dados bancários.')
      }

      setIsChecking(false)
    }
  }, [fields, goToNextScreen])

  return [
    fields,
    handleFields,
    showNextButton,
    bankOptions,
    showWarningModal,
    setShowWarningModal,
    isChecking,
    handleNextButton,
    isBlocked,
    isSanarUpFinanciamento,
  ]
}
