import React from 'react'
import * as S from './styles'
import { ModalText } from '../../../screens/cpf/components/CPFModal/styles'
import { GuarantorVideo } from '../../../assets/svg/GuarantorVideo'

export const Video = ({ name }) => {
  return (
    <>
      <S.Title>Grave o vídeo selfie dizendo: </S.Title>
      <ModalText>
        <br />
        {`"Sou avalista de ${name} na Provi"`}
      </ModalText>
      <S.ImageWrapper>
        <GuarantorVideo />
      </S.ImageWrapper>
    </>
  )
}
