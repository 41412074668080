import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;

  width: 100%;
  min-height: 94vh;
  padding: 9% 5% 5% 5%;

  @media (max-width: ${theme.breakpoints.md}px) {
    align-items: center;
    justify-content: center;
  }
`
