import React from 'react'

import { Modal } from '../../../../components/Modal'
import Button from '../../../../components/Button'

import * as S from './styles'

export const AvalistaModal = ({ showModal, setShowModal }) => {
  return (
    <Modal show={showModal} title="O que é um avalista de crédito?" onDismiss={() => setShowModal(false)}>
      <S.ModalText>
        Avalista é aquele que aceita ser responsável pelo pagamento das parcelas de um financiamento ou empréstimo feito por
        outra pessoa.
      </S.ModalText>

      <S.ModalStrong>Requisítos:</S.ModalStrong>

      <S.ModalText>
        - Tem que ter mais de 18 anos
        <br />- Alguém de sua confiança, de preferência com o mesmo sobrenome
        <br />- Sem pendência financeira no nome
      </S.ModalText>

      <S.ModalWrapper>
        <Button text="Entendi" width="220px" mobileWidth="220px" onClick={() => setShowModal(false)} />
      </S.ModalWrapper>
    </Modal>
  )
}
