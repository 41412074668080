import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { history } from '../../navigation/history'
import { getGuarantorInfo, putGuarantorInfo } from '../../services/guarantor.api'

export const useBasicInfo = (checkToken, setIsLoading) => {
  const [inputName, setInputName] = useState('')
  const [data, setData] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [showBirthDateInput, setShowBirthDateInput] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  checkToken()

  useEffect(() => {
    async function getData() {
      const { data: responseData } = await getGuarantorInfo()

      setInputName(responseData?.fullName)
      setData(responseData)
      if (!responseData?.birthDate) setShowBirthDateInput(true)
    }
    getData()
  }, [])

  useEffect(() => {
    if (showBirthDateInput) {
      if (inputName?.length > 2 && birthDate?.length < 9) return setIsButtonDisabled(true)
    }

    setIsButtonDisabled(false)
  }, [inputName, birthDate, showBirthDateInput])

  const onClickNextScreen = async () => {
    try {
      setIsLoading(true)

      const { data: putData } = await putGuarantorInfo({ ...data, fullName: inputName, ...(birthDate && { birthDate }) })

      if (putData?.error === false) {
        history.push('/avalista/email')
      }
    } catch {
      toast.error('Houve um erro ao confirmar seus dados, tente novamente')
    } finally {
      setIsLoading(false)
    }
  }

  return { inputName, setInputName, onClickNextScreen, data, showBirthDateInput, birthDate, setBirthDate, isButtonDisabled }
}
