import React, { useContext } from 'react'
import { ToastContainer } from 'react-toastify'

import { Template } from '../../components/template'
import { Title, Subtitle, Link } from '../../components/Texts'
import Button from '../../components/Button'
import { CheckBox } from '../../components/Checkbox'
import { CPFModal } from './components/CPFModal'
import { TemplateContext } from '../../components/template/context'

import * as S from './styles'
import { InputSanar } from '../../components/InputSanar'
import { useCpf } from './hooks'

export const Cpf = () => {
  const { setIsLoading } = useContext(TemplateContext)
  const [value, setValue, onClickNextScreen, isChecked, setIsChecked, showModal, setShowModal] = useCpf(setIsLoading)

  return (
    <Template haveHeader>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        position="top-right"
      />
      <S.Container>
        <S.LeftWrapper>
          <Title>Digite o seu CPF para começar.</Title>
          <Subtitle>CPF</Subtitle>
          <InputSanar
            placeholder="000.000.000-00"
            mask="999.999.999-99"
            width="80%"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />

          <S.CheckboxWrapper>
            <CheckBox
              text={
                <div onClick={(e) => e.stopPropagation()}>
                  Li e estou de acordo com os{' '}
                  <a target="_blank" rel="noopener noreferrer" no href="https://provi.com.br/termos-de-uso">
                    Termos de uso e a Política de privacidade
                  </a>{' '}
                  da Provi.
                </div>
              }
              checked={isChecked}
              onChange={() => setIsChecked((prevState) => !prevState)}
            />
          </S.CheckboxWrapper>

          <S.ButtonWrapper>
            <Button
              text="Avançar"
              disabled={!isChecked || value?.length < 11}
              footerButton
              onClick={() => onClickNextScreen()}
            />
          </S.ButtonWrapper>

          <Link onClick={() => setShowModal(true)}>Por que precisamos do seu CPF?</Link>

          <CPFModal showModal={showModal} setShowModal={setShowModal} />
        </S.LeftWrapper>
      </S.Container>
    </Template>
  )
}
