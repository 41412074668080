import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;

  width: 100%;
  min-height: 94vh;
  padding: 9% 5% 5% 5%;

  @media (max-width: ${theme.breakpoints.md}px) {
    align-items: center;
    justify-content: center;
  }
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 70%;
  max-width: 600px;

  @media (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
  }
`

export const ButtonWrapper = styled.div`
  margin: 30px 0;
`

export const CheckboxWrapper = styled.div`
  width: 70%;
  margin-top: 40px;

  @media (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
  }
`
