import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const Title = styled.h2`
  font-size: 22px;
  line-height: 32px;
  padding: 20px 0;
  font-weight: 600;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 26px;
    line-height: 36px;
  }
`

export const Subtitle = styled.p`
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 20px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const UploadWrapper = styled.div`
  padding: 40px 0;

  @media (min-width: ${theme.breakpoints.sm}px) {
    padding: none;
  }
`
