import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 20px;

  @media (min-width: ${theme.breakpoints.md}px) {
    display: none;
  }
`
