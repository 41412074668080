import React from 'react'

export const LoadingSanarImage = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.15" cx="15.5234" cy="15.5234" r="5.38462" transform="rotate(-45 15.5234 15.5234)" fill="#F56B00" />
    <circle opacity="0.7" cx="64.4768" cy="64.4766" r="5.38462" transform="rotate(-45 64.4768 64.4766)" fill="#F56B00" />
    <circle cx="39.9999" cy="5.38462" r="5.38462" fill="#F56B00" />
    <circle opacity="0.6" cx="39.9999" cy="74.6151" r="5.38462" fill="#F56B00" />
    <circle opacity="0.4" cx="5.38462" cy="40.0001" r="5.38462" transform="rotate(-90 5.38462 40.0001)" fill="#F56B00" />
    <circle opacity="0.8" cx="74.6153" cy="40.0001" r="5.38462" transform="rotate(-90 74.6153 40.0001)" fill="#F56B00" />
    <circle
      opacity="0.9"
      r="5.38462"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 64.4768 15.5234)"
      fill="#F56B00"
    />
    <circle
      opacity="0.5"
      r="5.38462"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15.5234 64.4766)"
      fill="#F56B00"
    />
  </svg>
)
