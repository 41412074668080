import React, { useEffect, useState } from 'react'

import { Modal } from '../../../../components/Modal'
import Button from '../../../../components/Button'
import { getSlugPartner } from '../../../../utils/getSlugPartner'

import * as S from './styles'

export const InvalidInfoModal = ({ show, setShow }) => {
  const [isSanarUpFinanciamento, setisSanarUpFinanciamento] = useState(false)

  useEffect(() => {
    const partnerSlug = getSlugPartner()

    if (partnerSlug === 'sanarup-financiamento') setisSanarUpFinanciamento(true)
  }, [])

  return (
    <Modal show={show} title="Fique de olho!" onDismiss={() => setShow(false)}>
      <S.ModalText>
        {isSanarUpFinanciamento ? 'O Financiamento' : 'A Mesada'} de Sanar UP é destinada apenas para pessoas que cursam
        medicina e estão {isSanarUpFinanciamento ? 'no 6° ano.' : 'entre o 3º e o 6º ano ou na residência.'}
      </S.ModalText>
      <S.ModalText>Você terá que apresentar um comprovante de matrícula e o seu histórico escolar.</S.ModalText>

      <S.ModalWrapper>
        <Button text="Entendi" width="220px" mobileWidth="220px" onClick={() => setShow(false)} />
      </S.ModalWrapper>
    </Modal>
  )
}
