import React from 'react'
import { LoadingSanarImage } from '../../assets/svg/LoadingSanarImage'
import * as S from './styles'

export const SanarLoading = () => {
  return (
    <S.Container>
      <LoadingSanarImage />
    </S.Container>
  )
}
