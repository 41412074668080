import React from 'react'
import { SelectorV3 as Selector } from '@provi/provi-components'
import { brazilianStates } from '../../screens/address/states'
import { useWindowSize } from '../../utils/useWindowSize'
import * as S from './style'

export const SelectorFieldAddress = ({
  fieldName,
  label,
  placeholder,
  selectorValue,
  setFieldValue,
  setFieldTouched,
  validateForm,
  hasError,
  hasTouched,
  handleEnterKey,
  getObjectFromAcronym,
  brazilianStatesSelector,
  fieldWidth,
}) => {
  const { width } = useWindowSize()

  if (width && width < 800) {
    return (
      <S.SelectorWrapper>
        <S.LabelSelector>{label}</S.LabelSelector>
        <S.SelectContent
          name={fieldName}
          id="input-state"
          placeholder={placeholder}
          value={selectorValue}
          onChange={(event) => {
            getObjectFromAcronym(event.target.value)
          }}
        >
          <option value="" hidden>
            Selecionar estado
          </option>
          {brazilianStates.map((state) => (
            <option key={state.acronym} value={state.acronym}>
              {state.label}
            </option>
          ))}
        </S.SelectContent>
      </S.SelectorWrapper>
    )
  }

  return (
    <Selector
      id="input-state"
      label={label}
      name={fieldName}
      placeholder={placeholder}
      className="cy-select-state"
      isSearchable={false}
      value={brazilianStatesSelector}
      options={brazilianStates}
      onChange={(event) => {
        setFieldValue(fieldName, event.acronym)
      }}
      onBlur={() => {
        setFieldTouched(fieldName)
        validateForm()
      }}
      isValid={!hasError && hasTouched}
      hasError={hasError && hasTouched}
      onKeyDown={handleEnterKey}
      width={fieldWidth}
    />
  )
}
