import React, { forwardRef } from 'react'

import * as S from './styles'

export const Link = forwardRef((props, ref) => {
  return (
    <S.Link ref={ref} {...props}>
      {props.children}
    </S.Link>
  )
})
