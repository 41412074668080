import { useEffect, useState } from 'react'

export const useBasicInfo = (creditPath, goToNextScreen) => {
  const [value, setValue] = useState(null)
  const [answer, setAnswer] = useState(null)

  const options = [
    'Pagar mensalidade',
    'Pagar aluguel e contas da casa',
    'Complementar Renda',
    'Comprar EPIs',
    'Cursos preparatórios',
    'Pagar formatura',
    'Lazer',
    'Outros',
  ]

  useEffect(() => {
    if (creditPath?.readCourseObjective?.data) {
      setValue(options.includes(creditPath?.readCourseObjective?.data) ? creditPath?.readCourseObjective?.data : 'Outros')
    }

    if (creditPath?.readCourseObjective?.data === 'Outro') {
      setAnswer(creditPath?.readCourseObjective?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditPath])

  const disabled = () => {
    if (value === 'Outros') return !answer

    return !value
  }

  const onClickNextScreen = () => {
    goToNextScreen({
      data: value === 'Outros' ? answer : value,
    })
  }

  return [value, answer, options, setValue, setAnswer, disabled, onClickNextScreen]
}
