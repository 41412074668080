import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import { InputSanar } from '../../components/InputSanar'
import Button from '../../components/Button'
import { usePhone } from './hooks'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { history } from '../../navigation/history'
import { TemplateContext } from '../../components/template/context'
import { Title } from '../../components/Texts'

export const Phone = () => {
  const { checkToken, setIsLoading } = useContext(TemplateContext)
  const [phone, setPhone, onClickNextScreen] = usePhone(checkToken, setIsLoading)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar onClickBack={() => history.push('/avalista/confirmacao-email')} title="celular" percent={40} />
        <S.ContainerInputs>
          <Title>Qual o número do seu celular?</Title>
          <S.Subtitle>Vamos te enviar um código de confirmação</S.Subtitle>
          <InputSanar
            label="Celular"
            width="370px"
            mobileWidth="370px"
            mask="(99) 99999-9999"
            placeholder="(10) 98765-4321"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </S.ContainerInputs>
        <Button text="Avançar" disabled={phone?.length < 8} footerButton onClick={onClickNextScreen} />
      </S.Container>
    </Template>
  )
}
