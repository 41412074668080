import { Address, PhoneVerification, RGFront, RGBack, CNH, IntroDocument } from '../screens'
import {
  Phone,
  Email,
  GuarantorIntroduction,
  GuarantorBasicInfo,
  PreDocument,
  GuarantorResume,
  GuarantorSendEmail,
  Analysis,
  AntiFraud,
  Upload,
} from '../screensGuarantor'

export const routesSanar = [
  {
    path: '/avalista/concluido',
    component: Analysis,
  },
  {
    path: '/avalista/resumo',
    component: GuarantorResume,
  },
  {
    path: '/avalista/foto-ou-video',
    component: AntiFraud,
  },
  {
    path: '/avalista/selecionar-documento',
    component: IntroDocument,
  },
  {
    path: '/avalista/rg-parte-frente',
    component: RGFront,
  },
  {
    path: '/avalista/rg-parte-tras',
    component: RGBack,
  },
  {
    path: '/avalista/carteira-nacional-habilitacao',
    component: CNH,
  },
  {
    path: '/avalista/inicio-documentos',
    component: PreDocument,
  },
  {
    path: '/avalista/endereco',
    component: Address,
  },
  {
    path: '/avalista/celular',
    component: Phone,
  },
  {
    path: '/avalista/email',
    component: Email,
  },
  {
    path: '/avalista/confirmacao',
    component: GuarantorBasicInfo,
  },
  {
    path: '/avalista/verificacao-email',
    component: GuarantorSendEmail,
  },
  {
    path: '/avalista/foto-ou-video/:type',
    component: Upload,
  },
  { path: '/avalista/confirmacao-celular', component: PhoneVerification },
  { path: '/avalista/token/:token/:screen', component: GuarantorIntroduction },
  {
    path: '/avalista/:token',
    component: GuarantorIntroduction,
  },
]
