import React from 'react'

import { ProviLogoNew } from '../../assets/svg/ProviLogoNew'
import { SanarLogoCinza } from '../../assets/svg/SanarLogoCinza'

import * as S from './styles'

export const MobileHeader = () => {
  return (
    <S.Container>
      <SanarLogoCinza />
      <ProviLogoNew />
    </S.Container>
  )
}
