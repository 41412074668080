import React, { useState } from 'react'
import { useSendingInfo, useCreditPath, useToken } from './hooks'
import { TemplateContext } from './context'
import { useScreenMeasure } from '../../utils/useScreenMesure'

export const TemplateProvider = (props) => {
  const [creditPath, setCreditPath] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(true)

  const [isMobile] = useScreenMeasure()
  const [goToNextScreen, goToPreviousScreen] = useSendingInfo(creditPath, setCreditPath, setIsLoading)
  useCreditPath(setIsLoadingGlobal, setCreditPath)
  const [checkToken] = useToken()

  const value = {
    isMobile,
    goToNextScreen,
    goToPreviousScreen,
    isLoading,
    setIsLoading,
    isLoadingGlobal,
    setIsLoadingGlobal,
    creditPath,
    checkToken,
    setCreditPath,
  }

  return <TemplateContext.Provider value={value}>{props.children}</TemplateContext.Provider>
}
