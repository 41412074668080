import React, { useState, useEffect } from 'react'

import { Template } from '../../components/template'
import Button from '../../components/Button'
import { Paragraph, LargeText } from '../../components/Texts'
import { getSlugPartner } from '../../utils/getSlugPartner'

import * as S from './styles'

export const NotAMedic = () => {
  const [isSanarUpFinanciamento, setisSanarUpFinanciamento] = useState(false)

  useEffect(() => {
    const partnerSlug = getSlugPartner()

    if (partnerSlug === 'sanarup-financiamento') setisSanarUpFinanciamento(true)
  }, [])

  return (
    <Template haveHeader>
      <S.Container>
        <S.Wrapper>
          <LargeText>
            Por enquanto, {isSanarUpFinanciamento ? 'o Financiamento' : 'a Mesada de Sanar UP'} está disponível apenas para
            estudantes de medicina{' '}
            {isSanarUpFinanciamento ? 'do 6° ano da graduação.' : 'entre o 3º e o 6º ano da graduação ou na residência médica.'}
          </LargeText>
        </S.Wrapper>

        <S.Wrapper>
          <Paragraph>Por esse motivo, não conseguimos seguir com a sua solicitação.</Paragraph>
        </S.Wrapper>

        <S.Wrapper>
          <Paragraph>Caso precise de ajuda, entre em contato com o nosso suporte.</Paragraph>
        </S.Wrapper>

        <S.Wrapper>
          <Button
            text="Entrar em contato"
            onClick={() =>
              window
                .open(
                  'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                  '_blank',
                )
                .focus()
            }
            footerButton
          />
        </S.Wrapper>
      </S.Container>
    </Template>
  )
}
