import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getSlugPartner } from '../../utils/getSlugPartner'

import { sanarUp, sanarUpFinanciamento } from './messages'

export const useEligibilityResponse = (setIsLoadingGlobal) => {
  const [titleMessage, setTitleMessage] = useState('')
  const [message, setMessage] = useState('')
  const [messagePt2, setMessagePt2] = useState('')
  const [showButton, setShowButton] = useState(false)
  const { reason } = useParams()

  useEffect(() => {
    localStorage.clear()

    const partnerSlug = getSlugPartner()

    const messages = partnerSlug === 'sanarup-financiamento' ? sanarUpFinanciamento : sanarUp

    const reasonHandler = {
      'menor-de-idade': {
        title: messages.titles.unapproved,
        message: messages.subtitles.underage,
        messagePt2: '',
        button: false,
      },
      'situacao-irregular': {
        title: messages.titles.unapproved,
        message: messages.subtitles.unapproved,
        messagePt2: '',
        button: false,
      },
      'pagamento-em-atraso': {
        title: messages.titles.pendingPayment,
        message: messages.subtitles.pendingPaymentOne,
        messagePt2: '',
        button: true,
      },
      'pagamentos-em-atraso': {
        title: messages.titles.pendingPayment,
        message: messages.subtitles.pengingPaymentTwo,
        messagePt2: '',
        button: true,
      },
      'nao-medico': {
        title: messages.titles.notMedic,
        message: messages.subtitles.notMedic,
        messagePt2: messages.subtitles.notMedic2,
        button: true,
      },
    }

    setTitleMessage(reasonHandler[reason].title)
    setMessage(reasonHandler[reason].message)
    setShowButton(reasonHandler[reason].button)
    setMessagePt2(reasonHandler[reason].messagePt2)

    setIsLoadingGlobal(false)
  }, [setIsLoadingGlobal, reason])

  return [titleMessage, message, showButton, messagePt2]
}
