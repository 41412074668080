import React from 'react'

import { Modal } from '../../../components/Modal'
import Button from '../../../components/Button'
import { ModalWrapper } from '../../../screens/cpf/components/CPFModal/styles'
import { Video } from './Video'
import { Photo } from './Photo'

export const StepOne = ({ type = 'photo', show, onDismiss, next, name }) => {
  const obj = {
    video: <Video />,
    photo: <Photo name={name} />,
  }

  return (
    <Modal show={show} title="1° passo" onDismiss={onDismiss}>
      {obj[type] || <></>}
      <ModalWrapper>
        <Button text="Avançar" width="220px" mobileWidth="220px" onClick={next} />
      </ModalWrapper>
    </Modal>
  )
}
