import React from 'react'
import * as S from './styles'
import Button from '../Button'
import { ArrayContent } from './arrayContent'

export const LinkBottom = (props) => {
  const { children, isMobile, onClickLink, onClick, disabled, textLink, text = 'Avançar', paddingTop = 50 } = props

  if (textLink instanceof Array) return <ArrayContent {...props} />

  return (
    <>
      <S.ContainerInputs paddingTop={paddingTop}>
        {children}
        {isMobile && <S.Link onClick={onClickLink}>{textLink}</S.Link>}
      </S.ContainerInputs>
      <S.Wrapper>
        <Button text={text} disabled={disabled} footerButton onClick={onClick} />
      </S.Wrapper>
      {!isMobile && <S.Link onClick={onClickLink}>{textLink}</S.Link>}
    </>
  )
}
