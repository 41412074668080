import React, { useContext } from 'react'

import { SanarProgressBar } from '../../components/SanarProgressBar'
import { Template } from '../../components/template'
import * as S from './styles'
import { InputSanar } from '../../components/InputSanar'
import Button from '../../components/Button'
import { TemplateContext } from '../../components/template/context'
import { useBasicInfo } from './hooks'
import { SelectInput } from '../../components/SelectInput'

export const CourseObjective = () => {
  const { creditPath, goToNextScreen } = useContext(TemplateContext)

  const [value, answer, options, setValue, setAnswer, disabled, onClickNextScreen] = useBasicInfo(creditPath, goToNextScreen)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="Objetivo" />

        <S.ContainerInputs>
          <S.Title>O que pretende fazer com o dinheiro que receber?</S.Title>
          <SelectInput
            onValueChange={({ value: selectedValue }) => setValue(selectedValue)}
            options={options.map((option) => ({ label: option, value: option }))}
            value={value && { label: value, value: value }}
            placeholder="Selecione a opção desejada"
            label="Objetivo"
            width="390px"
          />
          {value === 'Outros' && (
            <InputSanar
              label="Detalhe o seu plano"
              width="370px"
              mobileWidth="370px"
              placeholder="Eu pretendo..."
              value={answer}
              onChange={(event) => setAnswer(event.target.value)}
            />
          )}
        </S.ContainerInputs>
        <Button text="Avançar" disabled={disabled()} footerButton onClick={() => onClickNextScreen()} />
      </S.Container>
    </Template>
  )
}
