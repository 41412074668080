import styled from 'styled-components'

import { theme } from '../../../theme'

export const Subtitle = styled.p`
  color: ${theme.colors.black};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 10px 0;
`
