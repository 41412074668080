export const ERRORS = {
  default: 'Ops, ocorreu algum erro 😔',
  financingNotFound: 'Não foi possível identificar seu financiamento. Atualize sua página e tente novamente.',
  introduction: {
    cpfInvalid: 'CPF inválido',
    token: 'Ocorreu um erro na url por favor tente novamente',
    auth: 'Erro ao autenticar',
  },
  auth: {
    invalidToken: 'Token inválido',
    expiredToken: 'Seu token expirou, por favor entre em um link válido',
    notAuthorized: 'Ação não autorizada',
    invalidCpf: 'CPF Inválido',
    alreadyInAnalysis: 'Você não pode acessar essa plataforma porque suas informações já estão em análise.',
    alreadyApproved: 'Você não pode acessar essa plataforma porque suas informações já foram aprovadas.',
  },
}

export const errorMessages = {
  requiredField: 'Campo obrigatório',
  unexpectedError: 'Um erro inesperado aconteceu',
  birthDate: 'Digite uma data de nascimento válida',
  emailInvalid: 'Informe um endereço de e-mail válido',
  confirmEmail: 'O e-mail deve ser igual ao informado no campo anterior',
  cpf: 'Informe um número de CPF com 11 dígitos',
  invalidCPF: 'Digite um CPF válido',
  phone: 'Informe um número de celular válido',
  cep: 'Informe um CEP válido com 8 dígitos',
  fullName: 'Informe nome e sobrenome como no documento',
  rg: 'Informe um RG válido',
  city: 'Informe uma cidade válida',
  district: 'Informe um bairro válido',
  street: 'Informe um endereço válido',
  streetNumber: 'Informe um número válido',
  cardNumber: 'Informe um número de cartão válido',
  cardName: 'Informe o nome do titular como escrito no cartão',
}

export const requiredErrorMessages = {
  cpf: 'Informe seu CPF',
  fullName: 'Informe seu nome',
  phone: 'Informe um número de celular',
  emailInvalid: 'Informe seu endereço de e-mail',
  confirmEmail: 'Informe seu e-mail novamente',
  cep: 'Informe seu CEP',
  state: 'Informe seu estado',
  city: 'Informe sua cidade',
  district: 'Informe seu bairro',
  street: 'Informe seu endereço',
  streetNumber: 'Informe o número do endereço',
  rg: 'Informe seu RG',
  cardNumber: 'Informe o número do cartão',
  cardExpireDate: 'Informe a data de validade do cartão',
  cardCvv: 'Informe o código de segurança do cartão',
  cardName: 'Informe o nome do titular do cartão',
  guarantorCpf: 'Informe o CPF da pessoa avalista',
  holderCpf: 'Informe o CPF do titular do cartão',
  holderBirthDate: 'Informe a data de nascimento do titular do cartão',
  wrongFormatBirthDate: 'Informe data, mês e ano de nascimento',
}
