import React, { useContext } from 'react'

import { SanarLogo2 } from '../../assets/svg/SanarLogo2'
import { ProviLogo } from '../../assets/svg/ProviLogo'
import { MadePossibleProvi2 } from '../../assets/svg/MadePossibleProvi2'
import { TemplateContext } from '../../components/template/context'

import { useGuarantorSendEmail } from './hooks'
import { EmailGuarantorModal } from './components/emailGuarantorModal'
import * as S from './styles'

export const GuarantorSendEmail = () => {
  const { isMobile, setIsLoading, checkToken } = useContext(TemplateContext)
  const [email, showModal, setShowModal, handleEmail] = useGuarantorSendEmail(checkToken, setIsLoading)

  return (
    <>
      <S.Container>
        <S.Header>
          <SanarLogo2 />
          <S.ContainerLogo>{isMobile ? <ProviLogo /> : <></>}</S.ContainerLogo>
        </S.Header>
        <S.Content>
          <div>
            <S.Title>
              Enviamos um link para o e-mail <br />
              <span>{email}</span>
            </S.Title>

            <S.SubTitle>Esse link é o acesso para retomar a sua solicitação de onde você parou.</S.SubTitle>
          </div>

          <S.Link onClick={handleEmail}>Não recebi o e-mail</S.Link>
        </S.Content>
        <S.ContainerLogoProvi>
          <MadePossibleProvi2 />
        </S.ContainerLogoProvi>
      </S.Container>

      <EmailGuarantorModal show={showModal} setShow={setShowModal} />
    </>
  )
}
