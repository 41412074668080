import { useState, useEffect } from 'react'
import moment from 'moment'

import { getCreditRequestInfo, acceptedTermsAndConditions } from '../../services/guarantor.api'
import { history } from '../../navigation/history'

export const useGuarantorResume = (setIsLoading, checkToken) => {
  const [isChecked, setIsChecked] = useState(false)
  const [resumeData, setResumeData] = useState({
    userName: '',
    userCPF: '',
    userBithDate: '',
    partnerName: '',
    productName: '',
  })

  checkToken()

  useEffect(() => {
    async function load() {
      setIsLoading(true)
      const response = await getCreditRequestInfo()

      if (response) {
        const { data } = response

        setResumeData({
          userName: data?.user.fullName,
          userCPF: data?.user.cpf,
          userBithDate: moment(data?.user.birthDate).format('DD/MM/YYYY'),
          partnerName: data?.product.partner,
          productName: data?.product.course,
        })
      }

      setIsLoading(false)
    }

    load()
  }, [setIsLoading])

  const onConfirm = async () => {
    await acceptedTermsAndConditions()
    history.push('/avalista/confirmacao')
  }

  return [isChecked, setIsChecked, resumeData, onConfirm]
}
