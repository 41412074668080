import React from 'react'

export const SchoolRecordIcon = () => (
  <svg width="137" height="186" viewBox="0 0 137 186" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_135:1260)">
      <path
        d="M0.212677 185.27L136.34 185.27L136.34 0.232124L0.212617 0.232169L0.212677 185.27Z"
        fill="#EAEAEA"
        stroke="#7F7F7F"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.75"
        d="M6.32865 177.806L130.812 177.806L130.812 7.52821L6.3286 7.52825L6.32865 177.806Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path opacity="0.25" d="M11.5535 80.4664L125 80.4664L125 69.1694L11.5535 69.1694L11.5535 80.4664Z" fill="#7F7F7F" />
      <path opacity="0.25" d="M11.5535 96.4872L125 96.4872L125 85.1902L11.5535 85.1902L11.5535 96.4872Z" fill="#7F7F7F" />
      <path opacity="0.25" d="M11.5535 137.654L125 137.654L125 109.736L11.5535 109.736L11.5535 137.654Z" fill="#7F7F7F" />
      <path opacity="0.25" d="M11.5535 64.8954L125 64.8954L125 36.9774L11.5535 36.9774L11.5535 64.8954Z" fill="#7F7F7F" />
      <path opacity="0.25" d="M11.5535 152.74L125 152.74L125 141.443L11.5535 141.443L11.5535 152.74Z" fill="#7F7F7F" />
      <path opacity="0.25" d="M11.5535 168.761L125 168.761L125 157.464L11.5535 157.464L11.5535 168.761Z" fill="#7F7F7F" />
      <path
        d="M35.881 19.3045V21.8583H38.8243V19.3045H39.6034V25.4508H38.8243V22.5941H35.881V25.4508H35.0586V19.3045H35.881Z"
        fill="black"
      />
      <path
        d="M41.9408 19.7806C41.9408 20.0403 41.7677 20.2568 41.4214 20.2568C41.1184 20.2568 40.9453 20.0403 40.9453 19.7806C40.9453 19.4777 41.1617 19.2612 41.4647 19.2612C41.7677 19.2612 41.9408 19.4777 41.9408 19.7806ZM41.0752 25.4075V20.9926H41.8543V25.4075H41.0752Z"
        fill="black"
      />
      <path
        d="M43.0663 24.5851C43.2828 24.7583 43.7156 24.8881 44.1052 24.8881C44.6678 24.8881 44.9708 24.5851 44.9708 24.2389C44.9708 23.8493 44.7544 23.6329 44.1484 23.4165C43.3693 23.1135 42.9798 22.6807 42.9798 22.1612C42.9798 21.4687 43.5425 20.8627 44.4947 20.8627C44.9275 20.8627 45.3171 20.9926 45.5768 21.1224L45.3604 21.6851C45.1872 21.5553 44.8843 21.4254 44.4514 21.4254C43.9753 21.4254 43.7156 21.6851 43.7156 22.0314C43.7156 22.3777 43.9753 22.5508 44.538 22.7672C45.3171 23.0702 45.7066 23.4598 45.7066 24.109C45.7066 24.8881 45.1007 25.4075 44.0619 25.4075C43.5857 25.4075 43.1529 25.2777 42.8066 25.1045L43.0663 24.5851Z"
        fill="black"
      />
      <path
        d="M47.7409 19.7374V20.9926H48.8662V21.6418H47.7409V24.0224C47.7409 24.5851 47.914 24.8881 48.3468 24.8881C48.5632 24.8881 48.6931 24.8448 48.823 24.8448L48.8662 25.4508C48.6931 25.4941 48.4767 25.5807 48.1737 25.5807C47.7841 25.5807 47.4812 25.4508 47.308 25.2344C47.0916 25.018 47.005 24.6284 47.005 24.109V21.6418H46.3125V21.0359H47.005V19.9971L47.7409 19.7374Z"
        fill="black"
      />
      <path
        d="M53.7131 23.1568C53.7131 24.8016 52.5878 25.4941 51.5057 25.4941C50.2937 25.4941 49.3848 24.6284 49.3848 23.2433C49.3848 21.7717 50.337 20.906 51.5489 20.906C52.8907 20.906 53.7131 21.815 53.7131 23.1568ZM50.2504 23.2001C50.2504 24.1523 50.8131 24.8881 51.5922 24.8881C52.3713 24.8881 52.934 24.1523 52.934 23.2001C52.934 22.4642 52.5445 21.512 51.6355 21.512C50.7266 21.512 50.2504 22.3777 50.2504 23.2001ZM52.7609 19.1314L51.6355 20.4299H51.0728L51.8519 19.1314H52.7609Z"
        fill="black"
      />
      <path
        d="M54.7523 22.3777C54.7523 21.8583 54.7523 21.4254 54.709 20.9926H55.4015L55.4448 21.8583H55.4881C55.7045 21.2523 56.1806 20.906 56.7 20.906C56.7866 20.906 56.8732 20.906 56.9165 20.9493V21.6851C56.8299 21.6851 56.7433 21.6419 56.6568 21.6419C56.0941 21.6419 55.7045 22.0747 55.5747 22.6807C55.5747 22.8105 55.5314 22.8971 55.5314 23.0702V25.4075H54.7523V22.3777Z"
        fill="black"
      />
      <path
        d="M58.6479 19.7806C58.6479 20.0403 58.4747 20.2568 58.1285 20.2568C57.8255 20.2568 57.6523 20.0403 57.6523 19.7806C57.6523 19.4777 57.8688 19.2612 58.1717 19.2612C58.4314 19.2612 58.6479 19.4777 58.6479 19.7806ZM57.7389 25.4075V20.9926H58.518V25.4075H57.7389Z"
        fill="black"
      />
      <path
        d="M63.0193 25.2344C62.8029 25.3642 62.3268 25.4941 61.7641 25.4941C60.4223 25.4941 59.5566 24.5851 59.5566 23.2433C59.5566 21.9016 60.4656 20.906 61.9372 20.906C62.4134 20.906 62.8462 21.0359 63.0626 21.1224L62.8895 21.7284C62.7163 21.5986 62.4134 21.512 61.9805 21.512C60.985 21.512 60.4223 22.2478 60.4223 23.1568C60.4223 24.1956 61.0716 24.8016 61.9372 24.8016C62.4134 24.8016 62.6731 24.6717 62.9328 24.5851L63.0193 25.2344Z"
        fill="black"
      />
      <path
        d="M67.8674 23.1568C67.8674 24.8016 66.7421 25.4941 65.66 25.4941C64.448 25.4941 63.5391 24.6284 63.5391 23.2433C63.5391 21.7717 64.4913 20.906 65.7032 20.906C67.0018 20.906 67.8674 21.815 67.8674 23.1568ZM64.3615 23.2001C64.3615 24.1523 64.9241 24.8881 65.7032 24.8881C66.4824 24.8881 67.045 24.1523 67.045 23.2001C67.045 22.4642 66.6555 21.512 65.7465 21.512C64.8376 21.512 64.3615 22.3777 64.3615 23.2001Z"
        fill="black"
      />
      <path
        d="M73.235 25.4075L73.1917 24.8448H73.1484C72.8887 25.1911 72.4126 25.4941 71.8066 25.4941C70.8977 25.4941 70.4648 24.8881 70.4648 24.2389C70.4648 23.1568 71.4171 22.5941 73.1051 22.5941V22.5075C73.1051 22.1613 73.0186 21.4687 72.1096 21.4687C71.7201 21.4687 71.2872 21.5986 70.9842 21.815L70.8111 21.2956C71.1574 21.0792 71.7201 20.906 72.2395 20.906C73.5813 20.906 73.9275 21.815 73.9275 22.7239V24.3687C73.9275 24.7583 73.9275 25.1045 74.0141 25.4075H73.235ZM73.1051 23.1568C72.2395 23.1568 71.244 23.2866 71.244 24.1523C71.244 24.6717 71.5902 24.9314 71.9798 24.9314C72.5425 24.9314 72.932 24.5851 73.0619 24.1956C73.1051 24.109 73.1051 24.0225 73.1051 23.9359V23.1568Z"
        fill="black"
      />
      <path
        d="M78.3416 25.2344C78.1252 25.3642 77.6491 25.4941 77.0864 25.4941C75.7446 25.4941 74.8789 24.5851 74.8789 23.2433C74.8789 21.9016 75.7879 20.906 77.2595 20.906C77.7356 20.906 78.1685 21.0359 78.3849 21.1224L78.2117 21.7284C78.0386 21.5986 77.7356 21.512 77.3028 21.512C76.3073 21.512 75.7446 22.2478 75.7446 23.1568C75.7446 24.1956 76.3938 24.8016 77.2595 24.8016C77.6923 24.8016 77.9953 24.6717 78.255 24.5851L78.3416 25.2344Z"
        fill="black"
      />
      <path
        d="M81.6745 25.4075L81.6312 24.8448H81.5879C81.3282 25.1911 80.8521 25.4941 80.2461 25.4941C79.3371 25.4941 78.9043 24.8881 78.9043 24.2389C78.9043 23.1568 79.8565 22.5941 81.5446 22.5941V22.5075C81.5446 22.1613 81.458 21.4687 80.5491 21.4687C80.1595 21.4687 79.7267 21.5986 79.4237 21.815L79.2506 21.2956C79.5968 21.0792 80.1595 20.906 80.6789 20.906C82.0207 20.906 82.367 21.815 82.367 22.7239V24.3687C82.367 24.7583 82.367 25.1045 82.4536 25.4075H81.6745ZM81.5879 23.1568C80.7222 23.1568 79.7267 23.2866 79.7267 24.1523C79.7267 24.6717 80.073 24.9314 80.4625 24.9314C81.0252 24.9314 81.4147 24.5851 81.5446 24.1956C81.5879 24.109 81.5879 24.0225 81.5879 23.9359V23.1568Z"
        fill="black"
      />
      <path
        d="M87.4323 18.9583V24.2821C87.4323 24.6717 87.4323 25.1045 87.4755 25.4075H86.7397L86.6964 24.6284C86.4367 25.1045 85.9173 25.4941 85.1815 25.4941C84.0994 25.4941 83.3203 24.5851 83.3203 23.2433C83.3203 21.7717 84.2293 20.8627 85.3114 20.8627C86.0039 20.8627 86.4367 21.1657 86.6532 21.5553V18.915H87.4323V18.9583ZM86.6532 22.8105C86.6532 22.7239 86.6532 22.5941 86.6099 22.4642C86.48 21.9448 86.0472 21.5553 85.4412 21.5553C84.6188 21.5553 84.1427 22.2911 84.1427 23.2433C84.1427 24.1523 84.5755 24.8881 85.4412 24.8881C85.9606 24.8881 86.48 24.5418 86.6099 23.9359C86.6532 23.806 86.6532 23.7195 86.6532 23.5896V22.8105Z"
        fill="black"
      />
      <path
        d="M89.2068 23.3732C89.2068 24.4553 89.8994 24.8881 90.7218 24.8881C91.2845 24.8881 91.6307 24.8016 91.9337 24.6717L92.0636 25.2344C91.7606 25.3642 91.2845 25.4941 90.5919 25.4941C89.2501 25.4941 88.4277 24.5851 88.4277 23.2866C88.4277 21.9881 89.2068 20.906 90.5054 20.906C91.9337 20.906 92.3233 22.1612 92.3233 22.9836C92.3233 23.1568 92.3233 23.2866 92.28 23.3732H89.2068ZM91.5442 22.7672C91.5442 22.2478 91.3277 21.4687 90.4188 21.4687C89.5964 21.4687 89.2501 22.2045 89.2068 22.7672H91.5442ZM90.7651 19.1314L91.6307 20.4299H91.0248L90.5054 19.5642L89.9859 20.4299H89.38L90.2457 19.1314H90.7651Z"
        fill="black"
      />
      <path
        d="M93.3187 22.2045C93.3187 21.7717 93.3187 21.3821 93.2754 20.9926H93.9679L94.0112 21.6851H94.0545C94.3142 21.2523 94.7037 20.8627 95.4396 20.8627C96.0455 20.8627 96.4784 21.209 96.6948 21.7284C96.8246 21.4687 96.9978 21.2956 97.1709 21.1657C97.4306 20.9493 97.7336 20.8627 98.1231 20.8627C98.6858 20.8627 99.5515 21.2523 99.5515 22.7672V25.3642H98.7724V22.9404C98.7724 22.0747 98.4694 21.5986 97.8202 21.5986C97.3873 21.5986 96.9978 21.9448 96.8679 22.3344C96.8246 22.4642 96.8246 22.5941 96.8246 22.7239V25.4508H96.0888V22.8105C96.0888 22.118 95.7858 21.5986 95.1799 21.5986C94.6605 21.5986 94.3142 21.9881 94.1843 22.3777C94.1411 22.5075 94.1411 22.6374 94.1411 22.7672V25.4075H93.362V22.2045H93.3187Z"
        fill="black"
      />
      <path
        d="M101.802 19.7806C101.802 20.0403 101.629 20.2568 101.283 20.2568C100.98 20.2568 100.807 20.0403 100.807 19.7806C100.807 19.4777 101.023 19.2612 101.326 19.2612C101.586 19.2612 101.802 19.4777 101.802 19.7806ZM100.893 25.4075V20.9926H101.672V25.4075H100.893Z"
        fill="black"
      />
      <path
        d="M106.174 25.2344C105.957 25.3642 105.481 25.4941 104.918 25.4941C103.577 25.4941 102.711 24.5851 102.711 23.2433C102.711 21.9016 103.62 20.906 105.092 20.906C105.568 20.906 106 21.0359 106.217 21.1224L106.044 21.7284C105.871 21.5986 105.568 21.512 105.135 21.512C104.139 21.512 103.577 22.2478 103.577 23.1568C103.577 24.1956 104.226 24.8016 105.092 24.8016C105.568 24.8016 105.827 24.6717 106.087 24.5851L106.174 25.2344Z"
        fill="black"
      />
      <path
        d="M111.022 23.1568C111.022 24.8016 109.896 25.4941 108.814 25.4941C107.602 25.4941 106.693 24.6284 106.693 23.2433C106.693 21.7717 107.646 20.906 108.858 20.906C110.156 20.906 111.022 21.815 111.022 23.1568ZM107.516 23.2001C107.516 24.1523 108.078 24.8881 108.858 24.8881C109.637 24.8881 110.199 24.1523 110.199 23.2001C110.199 22.4642 109.81 21.512 108.901 21.512C107.992 21.512 107.516 22.3777 107.516 23.2001Z"
        fill="black"
      />
      <path
        d="M16.8797 29.0866H12.4215C12.2916 29.0866 12.2051 29.0001 12.2051 28.8702V17.2269C12.2051 17.1403 12.2484 17.0971 12.2916 17.0538L14.5424 15.8418C14.5857 15.7985 14.6722 15.7985 14.7588 15.8418L17.0096 17.0538C17.0961 17.0971 17.0961 17.1403 17.0961 17.2269V28.8702C17.0961 29.0001 17.0096 29.0866 16.8797 29.0866ZM12.6379 28.6538H16.7066V17.3568L14.6722 16.2314L12.6379 17.3568V28.6538Z"
        fill="black"
      />
      <path
        d="M24.0203 29.0866H16.8785C16.7487 29.0866 16.6621 29.0001 16.6621 28.8702V20.0403C16.6621 19.9538 16.7054 19.9105 16.7487 19.8672L20.2979 17.8762C20.3412 17.8329 20.4278 17.8329 20.5144 17.8762L24.0636 19.8672C24.1069 19.9105 24.1502 19.9538 24.1502 20.0403V28.8702C24.1935 29.0001 24.1069 29.0866 24.0203 29.0866ZM17.0949 28.6538H23.8039V20.1702L20.4711 18.309L17.1382 20.1702V28.6538H17.0949Z"
        fill="black"
      />
      <path
        d="M24.0211 21.4687C23.9778 21.4687 23.9345 21.4687 23.9345 21.4254L20.4718 19.4777L17.0091 21.4254C16.9225 21.4687 16.7927 21.4254 16.7494 21.3389C16.7061 21.2523 16.7494 21.1224 16.836 21.0791L20.3852 19.0881C20.4285 19.0448 20.5151 19.0448 20.6016 19.0881L24.1509 21.0791C24.2375 21.1224 24.2808 21.2523 24.2375 21.3389C24.1509 21.4254 24.0643 21.4687 24.0211 21.4687Z"
        fill="black"
      />
      <path
        d="M28.4793 29.0866H24.0211C23.8913 29.0866 23.8047 29.0001 23.8047 28.8702V17.2269C23.8047 17.1403 23.848 17.0971 23.8913 17.0538L26.142 15.8418C26.1853 15.7985 26.2719 15.7985 26.3584 15.8418L28.6092 17.0538C28.6957 17.0971 28.6957 17.1403 28.6957 17.2269V28.8702C28.6957 29.0001 28.6092 29.0866 28.4793 29.0866ZM24.1942 28.6538H28.2629V17.3568L26.2286 16.2314L24.1942 17.3568V28.6538Z"
        fill="black"
      />
      <path
        d="M28.4804 18.6553C28.4371 18.6553 28.3938 18.6553 28.3938 18.612L26.2729 17.4433L24.1087 18.612C24.0221 18.6553 23.8923 18.612 23.849 18.5254C23.8057 18.4388 23.849 18.309 23.9356 18.2657L26.1863 17.0538C26.2296 17.0105 26.3162 17.0105 26.4027 17.0538L28.6535 18.2657C28.7401 18.309 28.7833 18.4388 28.7401 18.5254C28.6102 18.612 28.5669 18.6553 28.4804 18.6553Z"
        fill="black"
      />
      <path
        d="M20.4727 22.7239C19.7368 22.7239 19.1309 22.118 19.1309 21.3821C19.1309 20.6463 19.7368 20.0403 20.4727 20.0403C21.2085 20.0403 21.8144 20.6463 21.8144 21.3821C21.8144 22.118 21.2085 22.7239 20.4727 22.7239ZM20.4727 20.4299C19.9532 20.4299 19.5204 20.8627 19.5204 21.3821C19.5204 21.9015 19.9532 22.3344 20.4727 22.3344C20.9921 22.3344 21.4249 21.9015 21.4249 21.3821C21.4249 20.8627 20.9921 20.4299 20.4727 20.4299Z"
        fill="black"
      />
      <path
        d="M21.5983 29.0866H19.3043C19.1745 29.0866 19.0879 29.0001 19.0879 28.8702V25.3642C19.0879 25.2344 19.1745 25.1478 19.3043 25.1478H21.5983C21.7282 25.1478 21.8148 25.2344 21.8148 25.3642V28.8702C21.8148 29.0001 21.7282 29.0866 21.5983 29.0866ZM19.5207 28.6538H21.3819V25.5806H19.5207V28.6538Z"
        fill="black"
      />
      <path
        d="M19.0009 24.6717H17.7457C17.6159 24.6717 17.5293 24.5851 17.5293 24.4553V23.4165C17.5293 23.2866 17.6159 23.2001 17.7457 23.2001H19.0009C19.1308 23.2001 19.2174 23.2866 19.2174 23.4165V24.4553C19.2174 24.5851 19.1308 24.6717 19.0009 24.6717ZM17.9189 24.2389H18.7845V23.5896H17.9189V24.2389Z"
        fill="black"
      />
      <path
        d="M21.0791 24.6717H19.8238C19.694 24.6717 19.6074 24.5851 19.6074 24.4553V23.4165C19.6074 23.2866 19.694 23.2001 19.8238 23.2001H21.0791C21.2089 23.2001 21.2955 23.2866 21.2955 23.4165V24.4553C21.2955 24.5851 21.2089 24.6717 21.0791 24.6717ZM20.0403 24.2389H20.9059V23.5896H20.0403V24.2389Z"
        fill="black"
      />
      <path
        d="M23.1982 24.6717H21.943C21.8131 24.6717 21.7266 24.5851 21.7266 24.4553V23.4165C21.7266 23.2866 21.8131 23.2001 21.943 23.2001H23.1982C23.3281 23.2001 23.4146 23.2866 23.4146 23.4165V24.4553C23.3713 24.5851 23.2848 24.6717 23.1982 24.6717ZM22.1161 24.2389H22.9818V23.5896H22.1161V24.2389Z"
        fill="black"
      />
      <path
        d="M23.199 26.6627H22.3766C22.2467 26.6627 22.1602 26.5762 22.1602 26.4463V25.4075C22.1602 25.2777 22.2467 25.1911 22.3766 25.1911H23.199C23.3288 25.1911 23.4154 25.2777 23.4154 25.4075V26.4463C23.3721 26.5329 23.2855 26.6627 23.199 26.6627ZM22.5497 26.2299H22.9825V25.5807H22.5497V26.2299Z"
        fill="black"
      />
      <path
        d="M23.199 28.3508H22.3766C22.2467 28.3508 22.1602 28.2642 22.1602 28.1344V27.0956C22.1602 26.9657 22.2467 26.8792 22.3766 26.8792H23.199C23.3288 26.8792 23.4154 26.9657 23.4154 27.0956V28.1344C23.3721 28.2642 23.2855 28.3508 23.199 28.3508ZM22.5497 27.918H22.9825V27.2687H22.5497V27.918Z"
        fill="black"
      />
      <path
        d="M18.5681 26.6627H17.7457C17.6159 26.6627 17.5293 26.5762 17.5293 26.4463V25.4075C17.5293 25.2777 17.6159 25.1911 17.7457 25.1911H18.5681C18.698 25.1911 18.7845 25.2777 18.7845 25.4075V26.4463C18.7845 26.5329 18.698 26.6627 18.5681 26.6627ZM17.9189 26.2299H18.3517V25.5807H17.9189V26.2299Z"
        fill="black"
      />
      <path
        d="M18.5681 28.3508H17.7457C17.6159 28.3508 17.5293 28.2642 17.5293 28.1344V27.0956C17.5293 26.9657 17.6159 26.8792 17.7457 26.8792H18.5681C18.698 26.8792 18.7845 26.9657 18.7845 27.0956V28.1344C18.7845 28.2642 18.698 28.3508 18.5681 28.3508ZM17.9189 27.918H18.3517V27.2687H17.9189V27.918Z"
        fill="black"
      />
      <path
        d="M27.2683 20.3433H25.234C25.1041 20.3433 25.0176 20.2568 25.0176 20.1269V18.9582C25.0176 18.8284 25.1041 18.7418 25.234 18.7418H27.2683C27.3982 18.7418 27.4847 18.8284 27.4847 18.9582V20.1269C27.4847 20.2568 27.3982 20.3433 27.2683 20.3433ZM25.4071 19.9538H27.0519V19.218H25.4071V19.9538Z"
        fill="black"
      />
      <path
        d="M27.2683 22.3344H25.234C25.1041 22.3344 25.0176 22.2478 25.0176 22.118V20.9493C25.0176 20.8194 25.1041 20.7329 25.234 20.7329H27.2683C27.3982 20.7329 27.4847 20.8194 27.4847 20.9493V22.118C27.4847 22.2478 27.3982 22.3344 27.2683 22.3344ZM25.4071 21.9448H27.0519V21.209H25.4071V21.9448Z"
        fill="black"
      />
      <path
        d="M27.2683 24.3254H25.234C25.1041 24.3254 25.0176 24.2389 25.0176 24.109V22.9403C25.0176 22.8105 25.1041 22.7239 25.234 22.7239H27.2683C27.3982 22.7239 27.4847 22.8105 27.4847 22.9403V24.109C27.4847 24.2389 27.3982 24.3254 27.2683 24.3254ZM25.4071 23.9359H27.0519V23.2H25.4071V23.9359Z"
        fill="black"
      />
      <path
        d="M27.2683 26.3165H25.234C25.1041 26.3165 25.0176 26.2299 25.0176 26.1001V24.9314C25.0176 24.8015 25.1041 24.715 25.234 24.715H27.2683C27.3982 24.715 27.4847 24.8015 27.4847 24.9314V26.1001C27.4847 26.2299 27.3982 26.3165 27.2683 26.3165ZM25.4071 25.9269H27.0519V25.1911H25.4071V25.9269Z"
        fill="black"
      />
      <path
        d="M27.2683 28.3075H25.234C25.1041 28.3075 25.0176 28.221 25.0176 28.0911V26.9225C25.0176 26.7926 25.1041 26.706 25.234 26.706H27.2683C27.3982 26.706 27.4847 26.7926 27.4847 26.9225V28.1344C27.4847 28.221 27.3982 28.3075 27.2683 28.3075ZM25.4071 27.918H27.0519V27.1822H25.4071V27.918Z"
        fill="black"
      />
      <path
        d="M16.8804 18.3956C16.8372 18.3956 16.7939 18.3956 16.7939 18.3523L14.673 17.1836L12.5521 18.3523C12.4655 18.3956 12.3357 18.3523 12.2924 18.2657C12.2491 18.1791 12.2924 18.0493 12.3789 18.006L14.6297 16.7941C14.673 16.7508 14.7595 16.7508 14.8461 16.7941L17.0969 18.006C17.1834 18.0493 17.2267 18.1791 17.1834 18.2657C17.0536 18.3956 16.967 18.3956 16.8804 18.3956Z"
        fill="black"
      />
      <path
        d="M15.7117 20.1269H13.6774C13.5475 20.1269 13.4609 20.0403 13.4609 19.9105V18.7418C13.4609 18.612 13.5475 18.5254 13.6774 18.5254H15.7117C15.8415 18.5254 15.9281 18.612 15.9281 18.7418V19.9105C15.8848 20.0403 15.7983 20.1269 15.7117 20.1269ZM13.8505 19.6941H15.4953V18.9582H13.8505V19.6941Z"
        fill="black"
      />
      <path
        d="M15.7117 22.118H13.6774C13.5475 22.118 13.4609 22.0314 13.4609 21.9015V20.7329C13.4609 20.603 13.5475 20.5165 13.6774 20.5165H15.7117C15.8415 20.5165 15.9281 20.603 15.9281 20.7329V21.9015C15.8848 22.0314 15.7983 22.118 15.7117 22.118ZM13.8505 21.6851H15.4953V20.9493H13.8505V21.6851Z"
        fill="black"
      />
      <path
        d="M15.7117 24.109H13.6774C13.5475 24.109 13.4609 24.0224 13.4609 23.8926V22.7239C13.4609 22.5941 13.5475 22.5075 13.6774 22.5075H15.7117C15.8415 22.5075 15.9281 22.5941 15.9281 22.7239V23.8926C15.8848 24.0224 15.7983 24.109 15.7117 24.109ZM13.8505 23.6762H15.4953V22.9403H13.8505V23.6762Z"
        fill="black"
      />
      <path
        d="M15.7117 26.1001H13.6774C13.5475 26.1001 13.4609 26.0135 13.4609 25.8836V24.715C13.4609 24.5851 13.5475 24.4986 13.6774 24.4986H15.7117C15.8415 24.4986 15.9281 24.5851 15.9281 24.715V25.8836C15.8848 26.0135 15.7983 26.1001 15.7117 26.1001ZM13.8505 25.6672H15.4953V24.9314H13.8505V25.6672Z"
        fill="black"
      />
      <path
        d="M15.7117 28.0911H13.6774C13.5475 28.0911 13.4609 28.0045 13.4609 27.8747V26.706C13.4609 26.5762 13.5475 26.4896 13.6774 26.4896H15.7117C15.8415 26.4896 15.9281 26.5762 15.9281 26.706V27.8747C15.8848 28.0045 15.7983 28.0911 15.7117 28.0911ZM13.8505 27.6583H15.4953V26.9224H13.8505V27.6583Z"
        fill="black"
      />
      <path
        d="M20.4724 18.2657C20.3426 18.2657 20.256 18.1791 20.256 18.0493V17.1836H18.6978C18.6112 17.1836 18.5679 17.1403 18.5247 17.0971C18.4814 17.0105 18.4814 16.9672 18.5247 16.8806L18.8276 16.4478L18.5247 16.015C18.4814 15.9717 18.4814 15.8851 18.5247 15.7985C18.5679 15.712 18.6112 15.6687 18.6978 15.6687H20.4724C20.6023 15.6687 20.6888 15.7553 20.6888 15.8851V18.0493C20.6455 18.1791 20.559 18.2657 20.4724 18.2657ZM19.0441 16.7508H20.2127V16.0582H19.0441L19.1739 16.2747C19.2172 16.3612 19.2172 16.4478 19.1739 16.4911L19.0441 16.7508Z"
        fill="black"
      />
      <path
        d="M20.4723 16.4478C20.3424 16.4478 20.2559 16.3612 20.2559 16.2314V15.3657C20.2559 15.2359 20.3424 15.1493 20.4723 15.1493C20.6021 15.1493 20.6887 15.2359 20.6887 15.3657V16.2314C20.6454 16.3612 20.5588 16.4478 20.4723 16.4478Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_135:1260">
        <rect width="136.56" height="185.471" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
