import React from 'react'

const ErrorView = () => (
  <div>
    Erro ao gravar o seu video
    <br />
    <br />
    Autorize a camera e reinicie o navegador
  </div>
)

export default ErrorView
