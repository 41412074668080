import * as Sentry from '@sentry/browser'
import { LOCALSTORAGE_TOKENS } from '../constants'

export const logger = (props) => {
  const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
  const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
  if (props && props.error) {
    return Sentry.withScope((scope) => {
      scope.setUser({ token: token, id: crid })
      if (props.info) {
        Object.keys(props.info).forEach((key) => {
          scope.setExtra(key, props.info[key])
        })
      }
    })
  }
  Sentry.addBreadcrumb({
    category: 'ErrorRequest',
    message: 'ERROR INFO ' + props,
    level: Sentry.Severity.Warning,
  })

  Sentry.setUser({
    token: token,
    CRID: JSONPARSER(crid),
  })
}

export const setLogger = (screen = window.location.pathname) => {
  const crid = localStorage.getItem(LOCALSTORAGE_TOKENS.crid)
  const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)

  Sentry.setTag('Screen', screen)
  Sentry.setExtra('screen', screen)

  Sentry.setUser({
    token: token,
    id: JSONPARSER(crid),
  })
}

export const JSONPARSER = (str) => {
  try {
    return JSON.parse(str)
  } catch (_) {
    return str
  }
}
