import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Timer from './timer'
import Countdown from './countdown'
import { ButtonLogic } from './buttons-logic'

const ActionsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
`

const Actions = (props) => {
  const {
    isVideoInputSupported,
    isInlineRecordingSupported,
    thereWasAnError,
    isRecording,
    isCameraOn,
    streamIsReady,
    isConnecting,
    isRunningCountdown,
    isReplayingVideo,
    countdownTime,
    timeLimit,
    useVideoInput,
    options,
    onTurnOnCamera,
    onOpenVideoInput,
    onStartRecording,
    onStopRecording,
    onStopReplaying,
  } = props
  const renderContent = () => {
    const shouldUseVideoInput = !isInlineRecordingSupported && isVideoInputSupported

    if ((!isInlineRecordingSupported && !isVideoInputSupported) || thereWasAnError || isConnecting || isRunningCountdown) {
      return null
    }

    return ButtonLogic({
      isCameraOn,
      streamIsReady,
      isReplayingVideo,
      useVideoInput,
      onStartRecording,
      onStopRecording,
      onStopReplaying,
      isRecording,
      options,
      onOpenVideoInput,
      shouldUseVideoInput,
      onTurnOnCamera,
    })
  }

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </div>
  )
}

Actions.propTypes = {
  isVideoInputSupported: PropTypes.bool,
  isInlineRecordingSupported: PropTypes.bool,
  thereWasAnError: PropTypes.bool,
  isRecording: PropTypes.bool,
  isCameraOn: PropTypes.bool,
  streamIsReady: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isRunningCountdown: PropTypes.bool,
  countdownTime: PropTypes.number,
  timeLimit: PropTypes.number,
  showReplayControls: PropTypes.bool,
  isReplayingVideo: PropTypes.bool,
  useVideoInput: PropTypes.bool,
  options: PropTypes.objectOf(PropTypes.string),

  onTurnOnCamera: PropTypes.func,
  onTurnOffCamera: PropTypes.func,
  onOpenVideoInput: PropTypes.func,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
  onPauseRecording: PropTypes.func,
  onResumeRecording: PropTypes.func,
  onStopReplaying: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default Actions
