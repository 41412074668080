import React from 'react'
import * as S from './styles'

export const VideoContainer = ({ icon, getInputProps, ...props }) => {
  return (
    <S.GuarantorContainer isVideo {...props}>
      <input {...getInputProps()} />
      <S.ContainerDefault>
        <p>Fazer upload</p> {icon}
      </S.ContainerDefault>
    </S.GuarantorContainer>
  )
}
