import { useEffect, useState } from 'react'

export const useInvalidPathResponse = (creditPath, setIsLoadingGlobal) => {
  const [titleMessage, setTitleMessage] = useState('')
  const [message, setMessage] = useState('')
  const [showButton, setShowButton] = useState(false)
  const reason = creditPath?.readInvalidPath?.reason

  useEffect(() => {
    localStorage.clear()
    setTitleMessage('Poxa, não podemos completar o seu pedido.')
    setMessage(creditPath?.readInvalidPath?.reason)
    setShowButton(true)

    setIsLoadingGlobal(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoadingGlobal, reason])

  return [titleMessage, message, showButton]
}
