import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const Title = styled.h1`
  color: ${theme.colors.orangePrimary};
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 24px;
    line-height: 34px;
  }
`

export const Wrapper = styled.div`
  max-width: 600px;
  padding-top: 30px;
`

export const InfoText = styled.p`
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  padding-top: 15px;

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 28px;
  }
`
