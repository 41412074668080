import styled, { css } from 'styled-components'
import { Button } from '@material-ui/core'
import { theme } from '../../theme'

const handleColors = (disabled) => {
  if (disabled) {
    return css`
      &.MuiButton-contained.Mui-disabled {
        background: ${theme.colors.grey4};
      }
      .MuiButton-label {
        color: ${theme.colors.white};
      }
    `
  }

  return css`
    background-color: ${theme.colors.orangePrimary};
    .MuiButton-label {
      color: ${theme.colors.white};
    }
    .MuiTouchRipple-ripple {
      color: ${theme.colors.orangePrimary};
    }
    :hover {
      background-color: ${theme.colors.orangePrimaryDark};
    }
    :focus {
      background-color: ${theme.colors.orangePrimaryDark};
    }
  `
}

const resizeButton = (footerButton) => {
  if (footerButton) {
    return css`
      ${theme.breakpoints?.down('sm')} {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        min-width: 100vw;
        z-index: 200;
        .MuiButton-root {
          border-radius: 0;
        }
      }
    `
  }
}

export const ButtonContainer = styled.div`
  * {
    box-sizing: border-box;
  }
  box-sizing: border-box;
  width: fit-content;

  height: 48px;
  border-radius: 4px;
  min-width: ${({ buttonWidth }) => buttonWidth};
  height: ${({ buttonHeight }) => buttonHeight};
  ${theme.breakpoints?.down('md')} {
    min-width: ${({ mobileWidth }) => mobileWidth};
  }
  .MuiButton-root {
    ${({ disabled }) => handleColors(disabled)}
  }
  .MuiButton-label {
    color: ${theme.colors.orangePrimaryDark};
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-transform: none;
  }
  :focus,
  :focus-within {
    outline: 2px auto ${theme.colors.orangePrimaryDark};
    outline-offset: 2px;
  }
  ${({ footerButton }) => resizeButton(footerButton)}
`

export const ButtonWrapper = styled(Button)`
  border-radius: 4px;
  width: 100%;
  height: 100%;
`
