import styled from 'styled-components'
import { theme } from '../../../theme'

export const Card = styled.div`
  width: 100%;
  height: 174px;

  max-width: 312px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-around;

  border: 3px dashed ${theme.colors.orangeSecondary};
  box-sizing: border-box;
  border-radius: 20px;
`

export const CardTitle = styled.p`
  width: 46px;
  height: 28px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  letter-spacing: 0.005em;

  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
`
