import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import { useAntiFraud } from './hooks'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { history } from '../../navigation/history'
import { TemplateContext } from '../../components/template/context'
import { Title } from '../../components/Texts'
import { GuarantorPhoto } from '../../assets/svg/GuarantorPhoto'
import { Card } from './Card'
import { GuarantorVideo } from '../../assets/svg/GuarantorVideo'
import { GuarantorVideoMobile } from '../../assets/svg/GuarantorVideoMobile'
import { GuarantorPhotoMobile } from '../../assets/svg/GuarantorPhotoMobile'
import { ModalComponent } from './Modal'
import { StepOne } from './StepOne'
import { StepTwo } from './StepTwo'

export const AntiFraud = () => {
  const { checkToken, setIsLoading } = useContext(TemplateContext)
  const [modal, setShowModal, type, setType, next, step, clear, name] = useAntiFraud(checkToken, setIsLoading)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar onClickBack={() => history.push('/avalista/rg-parte-frente')} title="foto ou vídeo" percent={86.66} />
        <S.ContainerInputs>
          <Title>Em qual formato você prefere enviar?</Title>
          <S.SubTitle>Vamos te auxiliar no envio ;)</S.SubTitle>
          <br />
          <S.RowWrapper>
            <Card
              title="Foto"
              image={<GuarantorPhoto />}
              mobileImage={<GuarantorPhotoMobile />}
              paragraph={`Tire uma foto de você mesmo segurando um papel escrito “Sou avalista de ${name} na Provi.`}
              onClickButton={() => setType('photo')}
              buttonLabel="Tirar foto"
            />
            <Card
              title="Vídeo"
              image={<GuarantorVideo />}
              mobileImage={<GuarantorVideoMobile />}
              paragraph={`Grave um vídeo dizendo “ Sou avalista de ${name} na Provi.`}
              onClickButton={() => setType('video')}
              buttonLabel="Gravar vídeo"
            />
          </S.RowWrapper>
          <S.Link onClick={() => setShowModal(true)}>Por que precisamos desse comprovante?</S.Link>
        </S.ContainerInputs>
      </S.Container>
      <StepOne type={type} show={!!type && step === 1} onDismiss={clear} next={next} name={name} />
      <StepTwo type={type} show={!!type && step === 2} onDismiss={clear} next={next} name={name} />
      <ModalComponent showModal={modal} setShowModal={setShowModal} />
    </Template>
  )
}
