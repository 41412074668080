import { useEffect, useState } from 'react'

export const useGuarantorNegativeFeedback = (creditPath, goToNextScreen) => {
  const [modal, setModal] = useState(false)
  const [text, setText] = useState('duas pessoas')

  const onClickNextScreen = () => {
    goToNextScreen()
  }

  useEffect(() => {
    if (creditPath?.progress?.currentScreenUniqueName === 'secondGuarantorNegativeFeedback') {
      setText('uma pessoa')
    } else {
      setText('duas pessoas')
    }
  }, [creditPath])

  return [onClickNextScreen, modal, setModal, text]
}
