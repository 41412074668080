import React, { useContext } from 'react'

import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { UploadSelfie } from '../../assets/svg/UploadSelfie'
import Button from '../../components/Button'

import * as S from './styles'

export const IntroSelfie = () => {
  const { creditPath, goToNextScreen } = useContext(TemplateContext)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="Foto com documento" />
        <S.Title>Precisamos de uma foto sua segurando o seu documento</S.Title>

        <S.TipsForSelfie>
          <li>Não cubra o seu rosto com o documento ou outros acessórios</li>
          <li>Tire a foto em um lugar bem iluminado</li>
        </S.TipsForSelfie>

        <S.SVGWrapper>
          <UploadSelfie />
        </S.SVGWrapper>

        <Button text="Estou pronto" footerButton onClick={() => goToNextScreen()} />
      </S.Container>
    </Template>
  )
}
