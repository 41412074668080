import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 94vh;
  padding: 9% 5% 5% 5%;

  @media (max-width: ${theme.breakpoints.md}px) {
    align-items: center;
    min-height: auto;
  }
`

export const Title = styled.h1`
  color: ${theme.colors.orangePrimary};
  font-size: 32px;
  line-height: 42px;
  font-weight: 600;
  max-width: 600px;

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 26px;
    line-height: 36px;
  }
`

export const Subtitle = styled.p`
  color: ${theme.colors.black};
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  max-width: 600px;
  padding-top: 40px;

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const MobileButtonWrapper = styled.div`
  display: block;

  @media (min-width: ${theme.breakpoints.sm}px) {
    display: none;
  }
`
