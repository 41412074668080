import React from 'react'
import Button from '../../../components/Button'

import { Modal } from '../../../components/Modal'
import { ModalText, ModalWrapper } from '../../cpf/components/CPFModal/styles'

export const ModalComponent = ({ showModal, setShowModal, onClickNextScreen }) => {
  return (
    <Modal show={showModal} title="O que é um avalista de crédito?" onDismiss={() => setShowModal(false)}>
      <ModalText>
        Avalista é aquele que aceita ser responsável pelo pagamento das parcelas de um financiamento ou empréstimo feito por
        outra pessoa
      </ModalText>
      <ModalText>
        <span>Requisitos:</span> <br /> <br />- Tem que ter mais de 18 anos <br />- Alguém de sua confiança, de preferência com
        o mesmo sobrenome
        <br />- Sem pendência financeira no nome
      </ModalText>

      <ModalWrapper>
        <Button text="Avançar" width="220px" mobileWidth="220px" onClick={onClickNextScreen} />
      </ModalWrapper>
    </Modal>
  )
}
