import React from 'react'

import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

import File from '../File'
import { RenderDefault } from './renderDefault'
import { RenderGuarantor } from './renderGuarantor'

export default function Upload({
  onUpload,
  file = null,
  filesUploaded,
  title = '',
  icon,
  isGuarantor,
  isVideo,
  disabled,
  onClickFooter,
  type = null,
  maxFiles = 1,
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles,
    onDropAccepted: onUpload,
    onDropRejected: () => toast.error('Erro no upload da sua foto'),
    accept: type || (isVideo ? 'video/*' : 'image/*'),
    disabled,
  })

  const renderDragMessage = () => {
    if (file || filesUploaded) return <File uploadedFile={file || filesUploaded} />
  }

  const props = { isDragActive: isDragActive, disableBorder: file || filesUploaded, ...getRootProps(), disabled, isVideo }

  if (isGuarantor) return RenderGuarantor({ ...props, getInputProps, renderDragMessage, icon, onClickFooter })

  return RenderDefault({ ...props, getInputProps, renderDragMessage, icon, title })
}
