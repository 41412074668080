import React, { useContext } from 'react'

import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import Button from '../../components/Button'
import Upload from '../../components/Upload'
import { useUpload } from '../../utils/useUpload'

import { CNHIcon } from '../../assets/svg/CNH'

import { useCNHScreen } from './hooks'

import * as S from './styles'
import { isGuarantor } from '../../utils/isGuarantor'
import { GuarantorHooks } from './guarantorHooks'
import { history } from '../../navigation/history'

export const CNH = () => {
  const { creditPath, goToNextScreen, setIsLoading, checkToken } = useContext(TemplateContext)
  const isGuarantorScreen = isGuarantor()
  const [files, setFiles, filesUploaded, onClickNextScreen, setFilesUploaded] = useCNHScreen(
    creditPath,
    goToNextScreen,
    setIsLoading,
  )
  const [handleSubmit] = GuarantorHooks(isGuarantorScreen, files, setFilesUploaded, setIsLoading, checkToken)
  const [onUpload, preview] = useUpload(setFiles)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar
          title={isGuarantorScreen ? 'Documento pessoal' : 'Foto com documento'}
          percent={isGuarantorScreen ? 66.66 : creditPath?.progress?.percentageCompleted}
          {...(isGuarantorScreen && { onClickBack: () => history.push('/avalista/selecionar-documento') })}
        />

        <S.Title>Envie abaixo a sua CNH aberta</S.Title>

        <S.Subtitle>As informações devem estar legíveis</S.Subtitle>

        <S.UploadWrapper>
          <Upload
            onUpload={onUpload}
            file={preview}
            title="Fazer upload"
            filesUploaded={filesUploaded[filesUploaded.length - 1]}
            icon={<CNHIcon />}
          />
        </S.UploadWrapper>

        <Button
          text="Avançar"
          disabled={!files?.length && !filesUploaded.length}
          footerButton
          onClick={isGuarantorScreen ? handleSubmit : onClickNextScreen}
        />
      </S.Container>
    </Template>
  )
}
