import styled from 'styled-components'
import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const ContainerInputs = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`

export const Text = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  margin-bottom: 20px;
`

export const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  margin-top: 20px;
`
