import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import Button from '../../components/Button'
import { TemplateContext } from '../../components/template/context'
import { useBasicInfo } from './hooks'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { Subtitle } from '../../components/Texts'
import { Mobile } from './Mobile'
import { Desktop } from './Desktop'

export const Selfie = () => {
  const { creditPath, goToNextScreen, isMobile, setIsLoading } = useContext(TemplateContext)

  const [onClickNextScreen, files, setFiles, filesUploaded, type, setType] = useBasicInfo(
    creditPath,
    goToNextScreen,
    setIsLoading,
    isMobile,
  )

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="FOTO COM DOCUMENTO" />
        <S.ContainerInputs>
          <Subtitle>Envie abaixo uma foto sua segurando o seu documento pessoal</Subtitle>
          <S.Text>Se você já tirou a foto, opte por fazer o upload da foto caso contrário tire a foto agora mesmo.</S.Text>
          {isMobile ? (
            <Mobile files={files} setFiles={setFiles} filesUploaded={filesUploaded} />
          ) : (
            <Desktop files={files} setFiles={setFiles} filesUploaded={filesUploaded} type={type} setType={setType} />
          )}
        </S.ContainerInputs>
        <Button
          text="Avançar"
          disabled={!files?.length && !filesUploaded?.length}
          footerButton
          onClick={() => onClickNextScreen()}
        />
      </S.Container>
    </Template>
  )
}
