import { useState } from 'react'
import { toast } from 'react-toastify'
import { PhoneResendRewrite } from '../../../services/api'
import { getSlugPartner } from '../../../utils/getSlugPartner'
import { removeMask } from '../../../utils/removeMask'

export const usePhoneChange = () => {
  const [inputPhone, setInputPhone] = useState('')

  const onSubmitPhone = async () => {
    try {
      await PhoneResendRewrite(removeMask(inputPhone), getSlugPartner())
      location.reload()
    } catch (err) {
      toast.error(`Erro, por favor tente novamente!`, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }

  return [inputPhone, setInputPhone, onSubmitPhone]
}
