import { useEffect, useState, useCallback } from 'react'
import { getGuarantorInfo, postSendEmail } from '../../services/guarantor.api'
import { toast } from 'react-toastify'
import { history } from '../../navigation/history'

export const useGuarantorSendEmail = (checkToken) => {
  const [email, setEmail] = useState('')
  const [showModal, setShowModal] = useState(false)

  const sendEmail = useCallback(async () => {
    try {
      const response = await getGuarantorInfo()
      const data = response?.data

      if (data?.emailVerified) return history.push('/avalista/celular')

      await postSendEmail()
    } catch (error) {
      toast.error('Não foi possivel enviar o email 😔. Espere alguns minutos antes de tentar novamente')
    }
  }, [])

  useEffect(() => {
    checkToken()

    const getData = async () => {
      const response = await getGuarantorInfo()
      const data = response?.data

      if (data?.emailVerified) return history.push('/avalista/celular')

      setEmail(data?.email)
    }

    getData()
    sendEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEmail = () => {
    setShowModal(true)
    sendEmail()
  }

  return [email, showModal, setShowModal, handleEmail]
}
