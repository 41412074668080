import React, { useContext } from 'react'
import { useValidateToken } from './hooks'
import { TemplateContext } from '../../components/template/context'
import { SanarLoading } from '../../components/SanarLoading'

export const ValidateToken = (props) => {
  const { setIsLoading } = useContext(TemplateContext)
  useValidateToken({ ...props, setIsLoading })
  return <SanarLoading />
}
