import React, { useContext } from 'react'
import { ToastContainer } from 'react-toastify'

import { TemplateContext } from '../../components/template/context'
import Header from '../Header'
import { SanarLoading } from '../SanarLoading'
import { View } from './style'

export const Template = ({ haveHeader = false, ...props }) => {
  const { isMobile, isLoading, isLoadingGlobal } = useContext(TemplateContext)

  const Toast = () => (
    <ToastContainer
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      {...(isMobile ? { position: 'bottom-center' } : { position: 'top-right' })}
    />
  )

  if (isLoadingGlobal) {
    return (
      <>
        <Toast />
        <SanarLoading />
      </>
    )
  }

  if (haveHeader && !isMobile)
    return (
      <>
        <Toast />
        <View>
          {isLoading && <SanarLoading />}
          <Header />
          {props.children}
        </View>
      </>
    )

  return (
    <>
      <Toast />
      <View>
        {isLoading && <SanarLoading />}
        {props.children}
      </View>
    </>
  )
}
