import React from 'react'
import Webcam from 'react-webcam'
import { Modal as ModalComponent } from '../Modal'
import Button from '../Button'

import * as S from '../webcam/styles'

export const ModalCamera = ({
  image,
  webcamRef,
  videoConstraints,
  isButtonModalDisabled,
  handleImage,
  isWebcamModal,
  setIsWebcamModal,
  show,
}) => {
  return (
    <>
      {(isWebcamModal || show) && (
        <ModalComponent onDismiss={() => setIsWebcamModal(false)} show={isWebcamModal || show} title="Tirar foto">
          <S.Wrapper>
            <S.WrapperWebcam>
              {!image && (
                <Webcam
                  audio={false}
                  minScreenshotHeight={720}
                  minScreenshotWidth={1280}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              )}
              {image && <S.Preview src={image} />}
            </S.WrapperWebcam>
            <S.WrapperButton>
              <Button text={image ? 'Enviar foto' : 'Tirar foto'} disabled={isButtonModalDisabled} onClick={handleImage} />
            </S.WrapperButton>
          </S.Wrapper>
        </ModalComponent>
      )}
    </>
  )
}
