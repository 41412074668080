import { useEffect } from 'react'
import { ROOT_URL } from '../../constants'
import { generateSignToken } from '../../services/api'

export const useWaitingSignature = (creditPath) => {
  useEffect(() => {
    const redirectToContract = async () => {
      if (!creditPath?.readWaitingSignature?.CreditRequestId) return

      const response = await generateSignToken(creditPath?.readWaitingSignature?.CreditRequestId)

      const { token } = response

      window.location.href = `${ROOT_URL.fe_user}/provi-sign/carregando/${token}`
    }

    redirectToContract()
  }, [creditPath])
}
