import React from 'react'
import Button from '../../components/Button'
import { InputSanar } from '../../components/InputSanar'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { SelectorFieldAddress } from '../../components/SelectorFieldAddress'
import { Template } from '../../components/template'
import { useAddressHooks } from './hooks'
import * as S from './styles'

export const Address = () => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    validateForm,
    getObjectFromAcronym,
    brazilianStatesSelector,
    setFieldTouched,
    isValid,
    dirty,
    creditPath,
    nbhInputRef,
    streetNumberInputRef,
    handleEnterKey,
  } = useAddressHooks()

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="endereço" />

        <S.Wrapper>
          <InputSanar
            mask="99.999-999"
            placeholder="04.548-004"
            label="CEP"
            name="zipcode"
            value={values.zipcode}
            onChange={(e) => setFieldValue('zipcode', e.target.value)}
            hasError={errors.zipcode && touched.zipcode}
            errorMessage={errors.zipcode}
            onBlur={() => {
              setFieldTouched('zipcode')
              validateForm()
            }}
            onKeyDown={handleEnterKey}
            width="400px"
            mobileWidth="100%"
          />
        </S.Wrapper>

        {!errors.zipcode && (
          <>
            <S.Wrapper>
              <SelectorFieldAddress
                label="Estado"
                placeholder="Selecionar estado"
                fieldName="state"
                selectorValue={values.state}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                validateForm={validateForm}
                hasError={errors.state}
                hasTouched={touched.state}
                brazilianStatesSelector={brazilianStatesSelector}
                getObjectFromAcronym={getObjectFromAcronym}
                handleEnterKey={handleEnterKey}
                fieldWidth="400px"
              />
            </S.Wrapper>

            <S.Wrapper>
              <InputSanar
                label="Cidade"
                placeholder="São Paulo"
                name="city"
                value={values.city}
                onChange={(e) => setFieldValue('city', e.target.value)}
                onBlur={() => {
                  setFieldTouched('city')
                  validateForm()
                }}
                hasError={errors.city && touched.city}
                errorMessage={errors.city}
                onKeyDown={handleEnterKey}
                width="400px"
                mobileWidth="100%"
              />
            </S.Wrapper>

            <S.Wrapper>
              <InputSanar
                label="Bairro"
                placeholder="Vila Olimpia"
                value={values.district}
                name="district"
                onChange={(e) => setFieldValue('district', e.target.value)}
                onBlur={() => {
                  setFieldTouched('district')
                  validateForm()
                }}
                hasError={errors.district && touched.district}
                errorMessage={errors.district}
                inputRef={nbhInputRef}
                onKeyDown={handleEnterKey}
                width="400px"
                mobileWidth="100%"
              />
            </S.Wrapper>

            <S.Wrapper>
              <InputSanar
                label="Endereço"
                placeholder="Av. Dr. Cardoso de Melo"
                value={values.street}
                name="street"
                onChange={(e) => setFieldValue('street', e.target.value)}
                onBlur={() => {
                  setFieldTouched('street')
                  validateForm()
                }}
                hasError={errors.street && touched.street}
                errorMessage={errors.street}
                onKeyDown={handleEnterKey}
                width="400px"
                mobileWidth="100%"
              />
            </S.Wrapper>

            <S.RowWrapper>
              <InputSanar
                label="Número"
                mask="999999"
                placeholder="1340"
                value={values.number}
                name="number"
                onChange={(e) => setFieldValue('number', e.target.value)}
                onBlur={() => {
                  setFieldTouched('number')
                  validateForm()
                }}
                hasError={errors.number && touched.number}
                errorMessage={errors.number}
                inputRef={streetNumberInputRef}
                onKeyDown={handleEnterKey}
              />

              <InputSanar
                label="Complemento"
                placeholder="conjunto 11"
                value={values.complement || ''}
                name="complement"
                onChange={(e) => setFieldValue('complement', e.target.value)}
                onBlur={() => {
                  setFieldTouched('complement')
                  validateForm()
                }}
                hasError={errors.complement && touched.complement}
                errorMessage={errors.complement}
                onKeyDown={handleEnterKey}
              />
            </S.RowWrapper>
          </>
        )}

        <S.Wrapper>
          <Button text="Avançar" disabled={!(isValid && dirty)} footerButton onClick={handleSubmit} />
        </S.Wrapper>
      </S.Container>
    </Template>
  )
}
