import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 99vh;

  padding: 4%;

  @media (min-width: ${theme.breakpoints.md}px) {
    width: 70%;
    height: 90vh;
  } ;
`

export const Wrapper = styled.div`
  padding-top: 15px;

  @media (min-width: ${theme.breakpoints.md}px) {
    padding-top: 40px;
  } ;
`
