import React, { useContext } from 'react'
import { Template } from '../../../components/template'
import { ArrowLeft } from '../../../assets/svg/ArrowLeft'
import { TemplateContext } from '../../../components/template/context'
import Button from '../../../components/Button'

import { ProgressBar } from '@provi/provi-components'

import * as S from './styles'
import { InputSanar } from '../../../components/InputSanar'
import { usePhoneChange } from './hooks'
import { isGuarantor } from '../../../utils/isGuarantor'
import { useGuarantor } from './guarantorHooks'

export const PhoneResend = () => {
  const { creditPath } = useContext(TemplateContext)
  const isGuarantorScreen = isGuarantor()
  const [inputPhone, setInputPhone, onSubmitPhone] = usePhoneChange()
  const [changePhone] = useGuarantor(isGuarantorScreen)

  return (
    <Template haveHeader>
      <S.Container>
        <S.ButtonBack
          onClick={() => {
            location.reload()
          }}
          id="btn-back"
        >
          <ArrowLeft />
        </S.ButtonBack>
        <S.PageTitle>CELULAR</S.PageTitle>
        <ProgressBar
          color="#F56B00"
          progress={creditPath?.progress?.percentageCompleted}
          backgroundColor="#FFD9BC"
          width="100%"
        />
        <S.Title>Informe um novo número </S.Title>
        <S.SubTitle>Enviaremos um código de verificação para você a seguir.</S.SubTitle>
        <S.ContainerInput>
          <InputSanar
            label="Celular"
            width="370px"
            mobileWidth="370px"
            mask="(99) 9 9999-9999"
            placeholder="(10) 9 8765-4321"
            value={inputPhone}
            onChange={(e) => setInputPhone(e.target.value)}
          />
        </S.ContainerInput>
        <Button
          text="Avançar"
          disabled={inputPhone?.length < 11}
          footerButton
          onClick={() => {
            isGuarantorScreen ? changePhone(inputPhone) : onSubmitPhone()
          }}
        />
      </S.Container>
    </Template>
  )
}
