import { useEffect, useState } from 'react'

import { getSlugPartner } from '../../utils/getSlugPartner'

export const useProducts = (creditPath, goToNextScreen) => {
  const [selected, setSelected] = useState('')
  const [paymentOptionId, setPaymentOptionId] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  const [isSanarUpFinanciamento, setisSanarUpFinanciamento] = useState(false)

  useEffect(() => {
    const partnerSlug = getSlugPartner()

    if (partnerSlug === 'sanarup-financiamento') setisSanarUpFinanciamento(true)
  }, [])

  useEffect(() => {
    const selectedId = creditPath?.readProductSelection?.choosedCourses[0]?.id

    setSelected(creditPath?.readInstallments?.response?.find(({ id }) => id === selectedId))
    setPaymentOptionId(creditPath?.readInstallments?.choosedId)
    setCollapsed(!!creditPath?.readInstallments?.choosedId)
  }, [creditPath])

  const formatPorcentage = (number) => {
    return number + '%'
  }

  const onClickNextScreen = () => {
    goToNextScreen({
      CourseId: selected?.id,
      PartnerConditionId: paymentOptionId,
    })
  }

  return [
    selected,
    setSelected,
    paymentOptionId,
    setPaymentOptionId,
    collapsed,
    setCollapsed,
    formatPorcentage,
    onClickNextScreen,
    isSanarUpFinanciamento,
  ]
}
