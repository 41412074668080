import React, { useContext } from 'react'

import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import { ProviLogo } from '../../assets/svg/ProviLogo'
import { SanarLogo2 } from '../../assets/svg/SanarLogo2'
import TypeFormIntegration from '../../components/TypeformIntegration'

import * as S from './styles'
import { useAnalysis } from './hooks'

export const Analysis = () => {
  const { setIsLoading } = useContext(TemplateContext)
  const [userName] = useAnalysis(setIsLoading)

  return (
    <Template haveHeader>
      <S.Container>
        <S.MobileHeader>
          <SanarLogo2 />
          <ProviLogo />
        </S.MobileHeader>

        <S.Wrapper>
          <S.Title>Concluído!</S.Title>
          <S.Subtitle>Você finalizou o envio das suas informações.</S.Subtitle>
        </S.Wrapper>

        <S.Wrapper>
          <S.Text>
            As suas informações já estão sendo analisadas para que você ajude {userName} a transformar a carreira.
          </S.Text>

          <S.Text>Assim que a análise for concluída, você e {userName} receberão uma confirmação via e-mail.</S.Text>
        </S.Wrapper>

        <S.Wrapper>
          <S.Disclaimer>
            Caso tenha algum problema com seu contrato ou seu primeiro boleto, entre em contato com a gente via email
            papo@provi.com.br, ou via WhatsApp (11) 95771-2414.
          </S.Disclaimer>
        </S.Wrapper>
      </S.Container>
      <TypeFormIntegration id="w9wg567y" />
    </Template>
  )
}
