import React from 'react'

import { Modal } from '../../../../components/Modal'
import Button from '../../../../components/Button'

import * as S from './styles'

export const EmailGuarantorModal = ({ show, setShow }) => {
  return (
    <Modal show={show} title="Ainda não recebeu o email?" onDismiss={() => setShow(false)}>
      <S.ModalText>
        Enviamos novamente o email, caso ainda não tenha chego aguarde alguns minutos e confira novamente. Lembre-se de
        verificar a caixa de spam também.
      </S.ModalText>
      <S.ModalText>Se ainda assim o email não chegou entre em contato com a gente.</S.ModalText>
      <S.ModalWrapper>
        <Button
          text="Entrar em contato"
          width="220px"
          mobileWidth="220px"
          onClick={() =>
            window
              .open(
                'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                '_blank',
              )
              .focus()
          }
        />
      </S.ModalWrapper>
    </Modal>
  )
}
