import React from 'react'

import { useCardV2 } from './hooks'
import * as S from './styles'

export const CardV2 = ({ isCollapsed, isSelected, setIsCollapsed, setSelected, option, key }) => {
  const [handleFooterMessage, handleFooterClick, handleTitle, pricePerMonth] = useCardV2(
    isSelected,
    isCollapsed,
    setIsCollapsed,
    setSelected,
    option,
  )

  const { details, amountOfAllowances, paymentOptions } = option
  const [payment] = paymentOptions

  return (
    <S.Container isSelected={isSelected} isCollapsed={isCollapsed} key={key}>
      <S.CollapsedContent isSelected={isSelected} isCollapsed={isCollapsed}>
        <label>{option?.name.split('|')[0]}</label>

        <p>
          Receba <strong>{pricePerMonth}</strong>
          <br />
          durante <strong>{amountOfAllowances}</strong> meses
        </p>
      </S.CollapsedContent>

      <S.ExpandedContent isSelected={isSelected} isCollapsed={isCollapsed}>
        {handleTitle(option?.name)}

        <hr />

        <h3>
          <strong>{pricePerMonth}</strong>/mês
        </h3>

        <strong>Juros a partir de {payment.monthlyInterest}% ao mês.</strong>

        {details.map((message) => (
          <p key={message}>{message}</p>
        ))}
      </S.ExpandedContent>

      <S.Footer isCollapsed={isCollapsed} isSelected={isSelected} onClick={handleFooterClick}>
        <S.RadioIcon isCollapsed={isCollapsed} isSelected={isSelected} />
        <p>{handleFooterMessage()}</p>
      </S.Footer>
    </S.Container>
  )
}
