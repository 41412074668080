import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import { TemplateContext } from '../../components/template/context'
import { useIntroGuarantor } from './hooks'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { LinkBottom } from '../../components/LinkBottom'
import { ModalComponent } from './Modal'

export const IntroGuarantor = () => {
  const { creditPath, goToNextScreen, isMobile } = useContext(TemplateContext)

  const [onClickNextScreen, modal, setModal] = useIntroGuarantor(goToNextScreen)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="introdução avalista" canBack={false} />

        <S.Title>
          Para concluir a sua solicitação, será necessário que {!isMobile && <br />} você coloque alguém como seu avalista de
          crédito.
        </S.Title>

        <LinkBottom
          isMobile={isMobile}
          onClickLink={() => setModal(true)}
          onClick={onClickNextScreen}
          textLink="O que é um avalista?"
          paddingTop={30}
        />

        <ModalComponent showModal={modal} setShowModal={setModal} onClickNextScreen={onClickNextScreen} />
      </S.Container>
    </Template>
  )
}
