import styled from 'styled-components'

import { theme } from '@provi/provi-components'
import LogoSanar from '../../assets/images/background_sanar_v2.png'

export const Container = styled.div`
  font-family: 'Montserrat', sans serif;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: url(${LogoSanar});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 70%;
  background-size: 60%;

  ${theme.breakpoints.down('md')} {
    background: ${theme.colors.white100};
  }
`

export const Header = styled.header`
  max-width: 100vw;
  padding: 80px 72px;

  ${theme.breakpoints.down('md')} {
    padding: 40px 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;

  padding-left: 72px;

  display: flex;
  justify-content: center;

  width: 50%;

  ${theme.breakpoints.down('md')} {
    padding: 24px 24px 50px 24px;
    width: 100%;
  }
`

export const Title = styled.h2`
  font-size: 22px;
  line-height: 32px;
  font-style: normal;
  font-weight: bold;
  word-break: break-all;

  margin: 0;

  ${theme.breakpoints.down('sm')} {
    font-size: 24px;
  }
`

export const SubTitle = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.0075em;

  ${theme.breakpoints.down('md')} {
    > br {
      display: none;
    }
  }
`

export const ContainerLogo = styled.span`
  ${theme.breakpoints.down('md')} {
    display: none;
  }
  ${theme.breakpoints.down('sm')} {
    display: inline;
  }
`

export const ContainerLogoProvi = styled.span`
  margin-top: 40px;

  @media screen and (max-width: 990px) {
    display: none;
  }
`

export const InputLabel = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19.1px;
  line-height: 28px;

  letter-spacing: 0.005em;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 5px 0 0 0;
`
