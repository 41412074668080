const radioOptions = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
]

const selectFormationOptions = [
  { label: '1° e 2° ano', value: 'not-allowed' },
  { label: '3° e 4° ano', value: 'preIntern' },
  { label: '5° e 6° ano', value: 'intern' },
  { label: 'Residente', value: 'resident' },
]

const selectFormationOptionsSanarUp = [
  { label: '1° ao 3° ano', value: 'not-allowed' },
  { label: '4° e 5° ano', value: 'not-allowed' },
  { label: '6° ano', value: 'intern' },
]

export { radioOptions, selectFormationOptions, selectFormationOptionsSanarUp }
