import styled from 'styled-components'
import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const ContainerButtons = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`

export const Title = styled.h2`
  margin: 40px 0;
  max-width: 580px;
  font-size: 24px;
  word-wrap: break-all;
  line-height: 24px;
`

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  width: 320px;
  margin: 0 0 16px 0;

  @media (max-width: ${theme.breakpoints.xs}px) {
    width: 100%;
  }
`

export const ContainerCounter = styled.div`
  margin: 40px 0;
  max-width: 310px;
`

export const Link = styled.a`
  color: ${theme.colors.orangePrimary};
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;

  padding: 10px 0;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    font-size: 16px;
  }
`

export const getOTPContainerStyle = (isMobile) => {
  if (isMobile)
    return {
      display: 'flex',
      gap: '8px',
    }

  return {
    display: 'flex',
    gap: '8px',
  }
}

export const getOTPInputStyle = (isMobile) => {
  if (isMobile)
    return {
      width: 60,
      height: 60,
      border: '2px solid #BDBDBD',
      borderRadius: '4px',

      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '18px',
      color: theme.colors.black,
    }

  return {
    width: 80,
    height: 80,
    border: '2px solid #BDBDBD',
    borderRadius: '4px',

    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '18px',
    color: theme.colors.black,
  }
}
