import React, { useContext } from 'react'
import { ProgressBar } from '@provi/provi-components'

import { ArrowLeft } from '../../assets/svg/ArrowLeft'
import { TemplateContext } from '../template/context'

import * as S from './styles'

export const SanarProgressBar = ({ percent, title, onClickBack, canBack = true }) => {
  const { goToPreviousScreen } = useContext(TemplateContext)

  return (
    <S.Container>
      {canBack && (
        <S.ArrowWrapper onClick={onClickBack || goToPreviousScreen}>
          <ArrowLeft />
        </S.ArrowWrapper>
      )}
      <S.Title>{title}</S.Title>
      <ProgressBar color="#F56B00" progress={percent} backgroundColor="#FFD9BC" width="100%" />
    </S.Container>
  )
}
