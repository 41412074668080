import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import { InputSanar } from '../../components/InputSanar'
import Button from '../../components/Button'
import { useBasicInfo } from './hooks'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { history } from '../../navigation/history'
import { TemplateContext } from '../../components/template/context'
import { Title } from '../../components/Texts'

export const GuarantorBasicInfo = () => {
  const { checkToken, setIsLoading } = useContext(TemplateContext)
  const { inputName, setInputName, onClickNextScreen, data, showBirthDateInput, birthDate, setBirthDate, isButtonDisabled } =
    useBasicInfo(checkToken, setIsLoading)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar onClickBack={() => history.push('/avalista/resumo')} title="confirmação dos dados" percent={13.33} />
        <S.ContainerInputs>
          <Title>
            Confirme abaixo os dados da pessoa <br /> avalista de crédito <span>{data?.fullName}</span>
          </Title>
          <InputSanar
            label="CPF"
            width="370px"
            mobileWidth="370px"
            mask="999.999.999-99"
            placeholder="123.456.789-10"
            value={data?.CPF}
            disabled
          />
          <InputSanar
            label="Nome completo"
            width="370px"
            mobileWidth="370px"
            placeholder="Cecília Meirelles"
            value={inputName}
            onChange={(e) => setInputName(e.target.value)}
          />
          {showBirthDateInput && (
            <InputSanar
              label="Data de Nascimento"
              width="370px"
              mobileWidth="370px"
              mask="99/99/9999"
              placeholder="00/00/0000"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          )}
        </S.ContainerInputs>
        <Button text="Confirmo" disabled={!inputName || isButtonDisabled} footerButton onClick={onClickNextScreen} />
      </S.Container>
    </Template>
  )
}
