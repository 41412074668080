import React from 'react'

import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

import File from '../File'
import * as S from './styles'

export default function UploadDesktop({ disabled, onUpload, file = null, filesUploaded, icon, type }) {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: onUpload,
    onDropRejected: () => toast.error('Erro no upload da sua foto'),
    accept: 'image/*',
    disabled,
  })

  const renderDragMessage = () => {
    if ((file || filesUploaded) && type === 'upload') return <File uploadedFile={file || filesUploaded} />

    return (
      <S.DefaultBackgroundContainer>
        {icon}
        <S.UploadMessage>Fazer upload</S.UploadMessage>
      </S.DefaultBackgroundContainer>
    )
  }

  return (
    <S.DropContainer disabled={disabled} disableBorder={(file || filesUploaded) && type === 'upload'} {...getRootProps()}>
      <input {...getInputProps()} />
      {renderDragMessage()}
    </S.DropContainer>
  )
}
