import { useEffect, useState } from 'react'
import { getGuarantorInfo, putGuarantorInfo } from '../../../services/guarantor.api'

export const useGuarantor = (isGuarantorScreen) => {
  const [data, setData] = useState('')

  useEffect(() => {
    async function getData() {
      const { data: responseData } = await getGuarantorInfo()

      setData(responseData)
    }

    isGuarantorScreen && getData()
  }, [isGuarantorScreen])

  const changePhone = async (phone) => {
    await putGuarantorInfo({ ...data, phone: phone.replace(/[^\d]+/g, '') })

    location.reload()
  }

  return [changePhone]
}
