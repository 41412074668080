import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const Wrapper = styled.div`
  padding-top: 30px;
  max-width: 400px;

  @media (min-width: ${theme.breakpoints.md}px) {
    padding-top: 40px;
  }
`

export const Title = styled.h1`
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: ${theme.colors.orangePrimary};
`

export const Subtitle = styled.h1`
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: ${theme.colors.black};
`

export const Description = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
`

export const Link = styled.a`
  color: ${theme.colors.bluePrimary};
  text-decoration: none;

  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  word-break: break-all;
`

export const CopyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.orangePrimary};
  color: ${theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  cursor: pointer;

  :active {
    opacity: 0.5;
  }

  > p {
    margin-left: 5px;
  }

  @media (min-width: ${theme.breakpoints.sm}px) {
    position: static;
    width: 250px;
    border-radius: 4px;
  }
`
