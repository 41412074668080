import styled from 'styled-components'
import { theme } from '../../theme'
import Button from '../../components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const ContainerInputs = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 0px;

  > hr {
    border: 1px solid ${theme.colors.grey4};
    margin: 20px 0;
  }

  > p {
    > span {
      color: ${theme.colors.orangePrimary};
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    }
  }
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ButtonLink = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-top: 30px;

  color: ${theme.colors.orangePrimary};

  cursor: pointer;

  @media (max-width: ${theme.breakpoints.md}px) {
    text-align: center;
    width: 100%;
  }
`

export const ButtonFooter = styled(Button)`
  margin-top: 30px;
`
