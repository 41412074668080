import React from 'react'
import * as S from './styles'
import { ModalText } from '../../../screens/cpf/components/CPFModal/styles'

export const Video = () => {
  return (
    <>
      <S.Title>Tenha certeza de que: </S.Title>
      <ModalText>
        <br />
        Você está em um local bem iluminado <br />
        <br /> Não tem nenhum ruído atrapalhando a gravação <br />
        <br /> O microfone está ligado
      </ModalText>
    </>
  )
}
