import React from 'react'
import Button from '../../../components/Button'
import { Link } from '../../../components/LinkBottom/styles'

import { Modal } from '../../../components/Modal'
import { ModalText, ModalWrapper } from '../../cpf/components/CPFModal/styles'

export const ModalComponent = ({ showModal, setShowModal }) => {
  return (
    <Modal show={showModal} title="Por que você precisa adicionar outro avalista?" onDismiss={() => setShowModal(false)}>
      <ModalText>O CPF que você passou não foi aprovado pela nossa análise.</ModalText>
      <ModalText>
        A gente não olhas apenas se o nome está limpo. Caso você não saiba quem colocar como avalista, entre em contato com a
        gente.Muitas variáveis são levadas em conta, como o comportamento financeiro recente, histórico de pagamentos, quanto já
        tem de crédito em outros lugares, etc.
      </ModalText>
      <br />
      <ModalText>Caso você não saiba quem colocar como avalista, entre em contato com a gente.</ModalText>

      <ModalWrapper>
        <Button text="Entendi" width="220px" mobileWidth="220px" onClick={() => setShowModal(false)} />
        <Link
          onClick={() =>
            window
              .open(
                'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                '_blank',
              )
              .focus()
          }
        >
          Entrar em contato
        </Link>
      </ModalWrapper>
    </Modal>
  )
}
