import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const Title = styled.h1`
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 20px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const TipsForSelfie = styled.ul`
  padding: 5%;

  > li {
    font-size: 16px;
    line-height: 26px;
    margin: 7px 0;
  }

  @media (min-width: ${theme.breakpoints.sm}px) {
    padding: 3%;

    > li {
      font-size: 18px;
      line-height: 28px;
    }
  }
`

export const SVGWrapper = styled.div`
  width: 95%;
  max-width: 450px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    margin-bottom: 25px;
  }
`
