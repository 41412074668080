import styled from 'styled-components'

import { theme } from '../../../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 94vh;
  padding: 5% 5% 40px 5%;

  @media (min-width: ${theme.breakpoints.md}px) {
    flex-direction: row-reverse;
  }
`

export const Title = styled.h2`
  color: ${theme.colors.orangePrimary};
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 28px;
    line-height: 38px;
  }
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin: 40px 0;
  }
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 24px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 40px;
  }
`

export const InvalidDocImg = styled.img`
  width: 85%;
  max-width: 500px;
  height: auto;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${theme.breakpoints.md}px) {
    width: 40%;
    padding-right: 5%;
  }
`
