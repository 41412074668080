import { useState } from 'react'

export const useUpload = (setFiles, setType) => {
  const [preview, setPreview] = useState('')

  const convertArrayBufferToBase64 = (buffer) => {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  async function readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onerror = (event) => {
        const error = event && event.target && event.target.error

        reject(error)
      }

      reader.onload = async (event) => {
        const type = file.type
        const embeddableImage = type.startsWith('image')

        const loadedImageDetails = {
          type,
          blob: new Blob([event.target.result], { type }),
          preview: embeddableImage ? `data:${type};base64,` + convertArrayBufferToBase64(event.target.result) : null,
          embeddableImage,
        }

        setPreview(loadedImageDetails.preview)
        resolve(loadedImageDetails)
      }

      reader.readAsArrayBuffer(file)
    })
  }

  const onUpload = async (accepectedFiles) => {
    const response = []

    for (const file of accepectedFiles) {
      response.push(await readFile(file))
    }

    setType?.('upload')
    setFiles((prevState) => [...prevState, ...response.map((files) => files.blob)])
  }

  return [onUpload, preview]
}
