import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
`

export const RadioSelect = styled.input.attrs({
  type: 'radio',
})`
  opacity: 0;
  width: 0;
  height: 0;
`

export const RadioWrapper = styled.label`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 10px;
  margin: 10px 10px 0 10px;
  font-size: 14px;
  color: ${theme.colors.black};
`

export const RadioControl = styled.span`
  display: grid;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.orangePrimary};
  place-items: center;
  input + &:before {
    content: '';
    width: 15px;
    height: 15px;
    box-shadow: inset 15px 15px ${theme.colors.orangePrimary};
    border-radius: 50%;
    transition: 180ms transform ease-in-out;
    transform: scale(0);
  }
  input:checked + &:before {
    transform: scale(1);
  }
`

export const RadioInput = styled.span`
  display: flex;
  & > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export const RadioLabel = styled.span`
  display: flex;
  align-items: center;
  line-height: 1;
`

export const Text = styled.p`
  color: ${theme.colors.black};
  font-size: 13px;
  font-weight: 600;
  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 16px;
  }
`
