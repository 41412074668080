import { useEffect, useState } from 'react'

import { history } from '../../navigation/history'
import { getCreditRequestInfo } from '../../services/guarantor.api'

export const useAntiFraud = (checkToken) => {
  const [modal, setShowModal] = useState(false)
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [step, setStep] = useState(1)

  const translated = {
    photo: 'foto',
    video: 'video',
  }

  checkToken()

  useEffect(() => {
    const getName = async () => {
      const { data } = await getCreditRequestInfo()

      setName(data?.user?.firstName)
    }

    getName()
  }, [])

  const next = () => {
    if (!type && step === 2) return

    const obj = {
      1: () => setStep(2),
      2: () => history.push(`/avalista/foto-ou-video/${translated[type]}`),
    }

    return obj[step]()
  }

  const clear = () => {
    setStep(1)
    setType('')
  }

  return [modal, setShowModal, type, setType, next, step, clear, name]
}
