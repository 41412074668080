import React from 'react'
import Button from './button'
import StopButton from './stop-button'
import { VideoInput } from './use-video-input'

export const ButtonLogic = (props) => {
  const {
    isCameraOn,
    streamIsReady,
    isReplayingVideo,
    useVideoInput,
    onStartRecording,
    onStopRecording,
    onStopReplaying,
    isRecording,
    options,
    onOpenVideoInput,
    shouldUseVideoInput,
    onTurnOnCamera,
  } = props

  if (isReplayingVideo) {
    return (
      <Button onClick={onStopReplaying} data-qa="start-replaying">
        {(options && options.startReplaying) || 'Gravar outro vídeo'}
      </Button>
    )
  }

  if (isRecording) {
    return <StopButton onClick={onStopRecording} data-qa="stop-recording" />
  }

  if (isCameraOn && streamIsReady) {
    return (
      <Button onClick={onStartRecording} data-qa="start-replaying">
        {(options && options.startReplaying) || 'Gravar'}
      </Button>
    )
  }

  if (useVideoInput) {
    return (
      <Button onClick={onOpenVideoInput} data-qa="open-input">
        {(options && options.uploadVideo) || 'Upload a video'}
      </Button>
    )
  }

  return VideoInput(shouldUseVideoInput, onOpenVideoInput, options, onTurnOnCamera)
}
