import React from 'react'
import * as S from './styles'
import { ModalText } from '../../../screens/cpf/components/CPFModal/styles'

export const Photo = ({ name }) => {
  return (
    <>
      <S.Title>Escreva em um papel em letras de forma:</S.Title>
      <br />
      <ModalText>Sou avalista de {name} na Provi</ModalText>
    </>
  )
}
