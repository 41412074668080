import React, { useContext } from 'react'
import OtpInput from 'react-otp-input'
import { ProgressBar } from '@provi/provi-components'

import { SanarProgressBar } from '../../components/SanarProgressBar'
import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import Button from '../../components/Button'
import { formatPhone } from '../../utils/formatPhone'
import { getPhone, getPorcentage, usePhoneVerification } from './hooks'
import { PhoneResend } from './phone-resend'
import { isGuarantor } from '../../utils/isGuarantor'

import * as S from './styles'
import { useGuarantor } from './guarantorHooks'

export const PhoneVerification = () => {
  const { creditPath, goToNextScreen, isMobile, isLoading, checkToken } = useContext(TemplateContext)
  const isGuarantorScreen = isGuarantor()
  const [otpValue, setOtpValue, countDown, buttonResend, onResendToken, haveScreenNewNumber, setHaveScreenNewNumber] =
    usePhoneVerification(goToNextScreen, creditPath, isLoading, isGuarantorScreen)
  const [phone, sendPhone] = useGuarantor(isGuarantorScreen, checkToken, otpValue, isLoading)

  return haveScreenNewNumber ? (
    <PhoneResend />
  ) : (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={getPorcentage(isGuarantorScreen, creditPath)} title="Verificação" />
        <S.Title>Enviamos um SMS para {formatPhone(getPhone(isGuarantorScreen, phone, creditPath))} com um código.</S.Title>
        <S.SubTitle>Insira o código abaixo:</S.SubTitle>
        <OtpInput
          numInputs={4}
          containerStyle={S.getOTPContainerStyle(isMobile)}
          inputStyle={S.getOTPInputStyle(isMobile)}
          onChange={setOtpValue}
          value={otpValue}
          isInputNum={true}
          shouldAutoFocus={true}
          separator={' '}
        />
        {!buttonResend && (
          <S.ContainerCounter>
            <S.SubTitle>Você poderá reenviar em {countDown} segundos</S.SubTitle>
            <ProgressBar
              color="#F56B00"
              progress={(countDown * 100) / 60}
              backgroundColor="#FFD9BC"
              width="100%"
              height="24px"
              borderRadius="5px"
            />
          </S.ContainerCounter>
        )}

        <S.ContainerButtons>
          {buttonResend && (
            <Button
              text="Reenviar Código"
              disabled={false}
              footerButton
              onClick={() => {
                isGuarantorScreen ? sendPhone() : onResendToken()
              }}
            />
          )}
          <S.Link
            onClick={() => {
              setHaveScreenNewNumber(true)
            }}
          >
            Mudar meu telefone
          </S.Link>
        </S.ContainerButtons>
      </S.Container>
    </Template>
  )
}
