import React from 'react'

import * as S from './styles'

const File = ({ uploadedFile }) => (
  <S.Container>
    <li key={uploadedFile.id}>
      <S.FileInfo>
        <S.Preview src={uploadedFile?.url || uploadedFile} />
        <S.ContainerFeedback>
          <S.TextFeedback>Upload concluído</S.TextFeedback>
        </S.ContainerFeedback>
      </S.FileInfo>
    </li>
  </S.Container>
)

export default File
