import React, { useContext, useEffect } from 'react'

import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import { Checking } from '../../components/Checking'

import * as S from './styles'

export const WaitingGuarantorAnalysis = () => {
  const { goToNextScreen } = useContext(TemplateContext)

  useEffect(() => {
    async function getData() {
      const error = await goToNextScreen({}, false)

      if (error?.message === 'Alguma coisa está demorando mais que o esperado... Aguarde enquanto analisamos o seu avalista.') {
        setTimeout(() => {
          goToNextScreen({}, false)
        }, 5000)
      }
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Template haveHeader>
      <S.Container>
        <Checking subtitle="Estamos realizando a nossa análise!" show={true} />
      </S.Container>
    </Template>
  )
}
