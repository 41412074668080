import React, { useContext } from 'react'

import { TemplateContext } from '../../components/template/context'
import { SanarLogo2 } from '../../assets/svg/SanarLogo2'
import { ProviLogo } from '../../assets/svg/ProviLogo'
import { MadePossibleProvi2 } from '../../assets/svg/MadePossibleProvi2'

import * as S from './styles'

export const IncompleteBasicInfoAnalysis = () => {
  const { isMobile } = useContext(TemplateContext)

  return (
    <S.Container>
      <S.Header>
        <SanarLogo2 />
        <S.ContainerLogo>{isMobile ? <ProviLogo /> : <></>}</S.ContainerLogo>
      </S.Header>
      <S.Content>
        <S.Title>Obrigada por aguardar!</S.Title>
        <S.SubTitle>Fique de olho no seu e-mail para saber os próximos passos. Até já!</S.SubTitle>
      </S.Content>
      <S.ContainerLogoProvi>{!isMobile ? <MadePossibleProvi2 /> : <></>}</S.ContainerLogoProvi>
    </S.Container>
  )
}
