import axios from 'axios'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/browser'
import { v4 as uuidv4 } from 'uuid'
import { history } from '../navigation/history'
import { ROOT_URL } from '../constants'
import { ERRORS } from '../constants/errors'

export const api = axios.create({
  baseURL: ROOT_URL.guarantor,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorsArray = error?.response?.data?.errors

    if (!['array', 'object'].includes(typeof errorsArray)) {
      toast.error(ERRORS.default)
      return error
    }

    for (const err of errorsArray) {
      toast.error(err?.message || ERRORS.default)
    }

    return error
  },
)

const getToken = () => {
  return localStorage.getItem('guarantorToken')
}

const transactionId = uuidv4().replace(/-/g, '')

api.interceptors.request.use(
  async (config) => {
    const token = getToken()
    if (token) {
      Sentry.configureScope(function (scope) {
        scope.setTag('x-request-id', transactionId)
      })
      config.headers.authorization = token
      config.headers['x-request-id'] = transactionId
    }
    return config
  },
  (error) => {
    if (error.response.status === 403) {
      history.push(`/avalista/${localStorage.getItem('publicToken')}`)
    } else {
      return Promise.reject(error)
    }
  },
)

export const getCreditRequestInfo = () => {
  return api.get('/credit-request-info')
}

export const acceptedTermsAndConditions = async () => {
  try {
    const { data } = await axios.get('https://api.ipify.org/?format=json')

    await api.put('/credit-request-info', {
      ip: data.ip,
    })
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'errorRequest',
      message: `ERROR: ${error}`,
      level: Sentry.Severity.Warning,
    })
  }
}

export const getPersonalDocument = (type, side) => {
  let urlParams
  if (side) {
    urlParams = `documentType=${type}&photoSide=${side}`
  } else {
    urlParams = `documentType=${type}`
  }
  return api.get(`/personal-document?${urlParams}`)
}

export const postPersonalDocument = (data) => {
  return api.post('/personal-document', data)
}

export const getGuarantorInfo = async () => {
  return api.get('/guarantor-info')
}

export const putGuarantorInfo = async (data) => {
  return api.put('/guarantor-info', data)
}

export const getAccessToken = async (token) => {
  return api.get(`/public-token/${token?.toUpperCase()}`)
}

export const authToken = async (cpf, urlToken) => {
  return api.post('/public-token', {
    cpf,
    publicToken: urlToken?.toUpperCase(),
  })
}

export const postSendEmail = (resend = false) => {
  const endpoint = resend ? '/email/resend' : '/email/send'
  return api.post(endpoint)
}

export const getSelfie = () => {
  return api.get('/selfie')
}

export const postSelfie = (data) => {
  return api.post('/selfie', {
    downloadURL: data,
  })
}

export const postSendPhone = () => {
  return api.post('/phone/send')
}

export const postVerifyPhone = (data) => {
  return api.post('/phone/verify', {
    data,
  })
}
