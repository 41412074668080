const { REACT_APP_API_ENV } = process.env

const URLS = {
  production: {
    SMART_LOOK: '72ace4476a134028cea70faaeb4f316fd30ee815',
    credithpath_isa_allowance: 'https://api.provi.com.br/creditpath-isa-allowance',
    passwordless: 'https://ms-passwordless.provi.com.br',
    guarantor: 'https://ms-guarantor.provi.com.br',
    login: 'https://login.principia.net/login',
    user: 'https://ms-user.provi.com.br',
    fe_user: 'https://dash.principia.net',
  },
  staging: {
    SMART_LOOK: '72ace4476a134028cea70faaeb4f316fd30ee815',
    credithpath_isa_allowance: 'https://api-staging.provi.com.br/creditpath-isa-allowance',
    // credithpath_isa_allowance: 'http://localhost:5001/creditpath-isa-allowance',
    passwordless: 'https://ms-passwordless-staging.provi.com.br',
    guarantor: 'https://ms-guarantor-staging.provi.com.br',
    login: 'https://login-staging.provi.com.br/login',
    user: 'https://ms-user-staging.provi.com.br',
    fe_user: 'https://dash-staging.principia.net',
  },
}

export const setEnvironment = (environment) => {
  return URLS[environment] || URLS.staging
}

let _ROOT_URL = setEnvironment(REACT_APP_API_ENV)

export const LOCALSTORAGE_TOKENS = {
  token: 'TOKEN',
  crid: 'CRID',
  dataUser: 'USER_DATA',
}

export const ROOT_URL = _ROOT_URL

export const URLS_REDIRECT = {
  production: {
    passwordless: 'https://login.principia.net',
  },
  staging: {
    passwordless: 'https://login-staging.principia.net',
  },
}
export const setRedirectUrl = (environment) => {
  return URLS_REDIRECT[environment] || URLS_REDIRECT.staging
}

let _REDIRECT_URLS = setRedirectUrl(REACT_APP_API_ENV)

export const REDIRECT_URLS = _REDIRECT_URLS

export const userBlockedReasons = {
  under_age: 'menor-de-idade',
  irregular_situation: 'situacao-irregular',
  pending_payment: 'pagamento-em-atraso',
  is_defaulting: 'pagamentos-em-atraso',
  doesnt_study_medicine: 'nao-medico',
}
