import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { history } from '../../navigation/history'
import { getGuarantorInfo, putGuarantorInfo } from '../../services/guarantor.api'

export const useEmail = (checkToken, setIsLoading) => {
  const [email, setEmail] = useState('')
  const [data, setData] = useState('')
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    setDisabled(!/\S+@\S+\.\S+/i.test(email))
  }, [email])

  checkToken()

  useEffect(() => {
    async function getData() {
      const { data: responseData } = await getGuarantorInfo()

      setEmail(responseData?.email)
      setData(responseData)
    }
    getData()
  }, [])

  const onClickNextScreen = async () => {
    try {
      setIsLoading(true)

      await putGuarantorInfo({ ...data, email: email.trim() })

      history.push('/avalista/verificacao-email')
    } catch {
      toast.error('Houve ao adicionar email, tente novamente')
    } finally {
      setIsLoading(false)
    }
  }

  return [email, setEmail, onClickNextScreen, data, disabled]
}
