import React, { useContext } from 'react'

import { SanarProgressBar } from '../../components/SanarProgressBar'
import { Template } from '../../components/template'
import * as S from './styles'
import { TemplateContext } from '../../components/template/context'
import { useProducts } from './hooks'
import { Subtitle } from '../../components/Texts'
import { Hr } from './components/Hr'
import { PaymentOptionCard } from './components/PaymentOptionCard'
import { CardV2 } from './components/CardV2'

export const Products = () => {
  const { creditPath, goToNextScreen, isMobile } = useContext(TemplateContext)

  const [
    selected,
    setSelected,
    paymentOptionId,
    setPaymentOptionId,
    collapsed,
    setCollapsed,
    formatPorcentage,
    onClickNextScreen,
    isSanarUpFinanciamento,
  ] = useProducts(creditPath, goToNextScreen)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="planos" />
        <S.ContainerInputs>
          <Subtitle>Confira {isSanarUpFinanciamento ? 'os planos' : 'as mesadas'} disponíveis para você:</Subtitle>
          <S.Grid>
            {creditPath?.readInstallments?.response?.map((option, index) => (
              <CardV2
                key={`ProductCard_${index}`}
                isCollapsed={collapsed}
                isSelected={selected?.id === option?.id}
                setSelected={setSelected}
                setIsCollapsed={setCollapsed}
                option={option}
              />
            ))}
          </S.Grid>
          {selected &&
            (collapsed ? (
              <S.ButtonLink
                onClick={() => {
                  setSelected('')
                  setCollapsed(false)
                }}
              >
                Limpar seleção
              </S.ButtonLink>
            ) : (
              <S.ButtonLink onClick={() => setCollapsed(true)}>Escolher forma de pagamento</S.ButtonLink>
            ))}
          {selected && collapsed ? (
            <>
              <Hr isMobile={isMobile} />
              <Subtitle>
                Formas de pagamento para o <span>{selected?.name?.split('|')[0]}</span>
              </Subtitle>
              <S.Grid>
                {selected?.paymentOptions?.map(({ price, id, options, inconstantFlow, monthlyInterest }, index) => (
                  <PaymentOptionCard
                    key={index}
                    price={price}
                    id={id}
                    options={options}
                    inconstantFlow={inconstantFlow}
                    selected={paymentOptionId === id}
                    setPaymentOptionId={setPaymentOptionId}
                    monthlyInterest={monthlyInterest}
                    formatPorcentage={formatPorcentage}
                    totalValue={selected.price}
                  />
                ))}
              </S.Grid>
            </>
          ) : (
            <></>
          )}
        </S.ContainerInputs>

        <S.ButtonFooter
          text="Avançar"
          disabled={!selected || !paymentOptionId}
          footerButton
          onClick={() => onClickNextScreen()}
        />
      </S.Container>
    </Template>
  )
}
