import React, { useContext } from 'react'
import { SanarLoading } from '../../components/SanarLoading'
import { TemplateContext } from '../../components/template/context'
import { useWaitingSignature } from './hooks'

export const WaitingSignature = () => {
  const { creditPath } = useContext(TemplateContext)
  useWaitingSignature(creditPath)

  return <SanarLoading />
}
