import styled, { css } from 'styled-components'
import { theme } from '../../theme'

const disabledStyle = css`
  color: ${theme.colors.greyLight};
  cursor: not-allowed;
  &:hover {
    color: ${theme.colors.greyLight};
    font-weight: 500;
  }
`

export const Label = styled.label`
  display: inline-flex;
  align-items: baseline;
  position: relative;
  padding: 4px 4px 4px 34px;
  height: fit-content;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  border-radius: 4px;
  border: 2px solid;
  border-color: transparent;
  transition: border-color 0.3s ease;
  cursor: pointer;
  user-select: none;
  color: ${({ textColor }) => textColor || '#000000'};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  font-weight: 600;
  ${theme.breakpoints.down('sm')} {
    font-size: 13.2px;
  }

  :hover {
    color: #000;
    font-weight: 600;
    span:nth-last-child(1) {
      background: ${theme.colors.orangePrimary};
    }
  }

  input:checked ~ span {
    border: 1px solid ${({ selectedColor }) => selectedColor};
    color: ${({ selectedColor }) => selectedColor};
    background-color: transparent;
    font-weight: 600;
    border: none;
  }

  input:checked ~ span:nth-last-child(1) {
    border: 1px solid ${({ selectedColor }) => selectedColor};
    background: ${({ selectedColor }) => selectedColor};
    div {
      background: ${({ selectedColor }) => selectedColor};
      width: 14px;
      height: 14px;
      left: 4px;
      top: 4px;
      border-radius: 50%;
      position: absolute;
    }
  }

  span {
    transition: color 0.3s ease;
  }

  :active {
    span:nth-last-child(1) {
      background: ${theme.colors.orangePrimaryDark};
    }
    :focus,
    :focus-within {
      outline: ${theme.colors.orangePrimaryDark} auto 2px;
    }
  }

  input:disabled ~ span:nth-last-child(1) {
    background-color: ${theme.colors.greyLight};
    border: 1px solid ${theme.colors.greyPrimary};
  }

  ${({ disabled }) => disabled && disabledStyle}

  &:checked {
    color: ${({ selectedColor }) => selectedColor};
  }
  &:checked ~ span:after {
    color: ${({ selectedColor }) => selectedColor};
  }
  :focus,
  :focus-within {
    outline: ${theme.colors.orangePrimaryDark} auto 2px;
  }

  & > span > div > a {
    color: ${theme.colors.orangePrimaryDark};
    text-decoration: underline;
  }
`

export const Box = styled.input.attrs({
  type: 'checkbox',
})`
  cursor: pointer;
  position: absolute;
  opacity: 0.0001;
  height: 100%;
  width: fit-content;
  &:checked ~ span:after {
    display: block;
  }
`

export const Mark = styled.span`
  position: absolute;
  top: 3px;
  left: 2px;
  height: 22px;
  width: 22px;
  border: 1px solid ${theme.colors.orangePrimary};
  border-radius: 2px;
  transition: 180ms ease-in-out;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 6.8px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`
export const Container = styled.div``
