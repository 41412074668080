import { useEffect, useState } from 'react'
import { removeMask } from '../../utils/removeMask'

export const useBasicInfo = (creditPath, goToNextScreen) => {
  const [inputCpf, setInputCpf] = useState('')
  const [inputName, setInputName] = useState('')
  const [inputSocialName, setInputSocialName] = useState('')
  const [inputPhone, setInputPhone] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [showNextButton, setShowNextButton] = useState(false)
  const [showBirthDateInput, setShowBirthDateInput] = useState(false)
  const [showSocialName, setShowSocialName] = useState(false)

  useEffect(() => {
    if (creditPath) {
      creditPath?.readBasicInfo?.cpf && setInputCpf(creditPath?.readBasicInfo?.cpf)
      creditPath?.readBasicInfo?.fullName && setInputName(creditPath?.readBasicInfo?.fullName)
      creditPath?.readBasicInfo?.fullSocialName && setInputSocialName(creditPath?.readBasicInfo?.fullSocialName)
      creditPath?.readBasicInfo?.phone && setInputPhone(creditPath?.readBasicInfo?.phone)

      if (!creditPath?.readBasicInfo?.birthDate) setShowBirthDateInput(true)
      if (creditPath?.readBasicInfo?.fullSocialName) setShowSocialName(true)
    }
  }, [creditPath])

  const isSocialNameValid = showSocialName ? inputSocialName.length > 2 : true

  useEffect(() => {
    if (showBirthDateInput) {
      if (isSocialNameValid && inputName?.length > 2 && removeMask(inputPhone)?.length > 10 && birthDate?.length > 9)
        return setShowNextButton(true)
    }

    if (isSocialNameValid && inputName?.length > 2 && removeMask(inputPhone)?.length > 10) return setShowNextButton(true)

    return setShowNextButton(false)
  }, [inputName, birthDate, inputPhone, showBirthDateInput, showSocialName, inputSocialName])

  const onClickNextScreen = () => {
    goToNextScreen({
      phone: removeMask(inputPhone),
      fullName: inputName,
      ...(showSocialName && inputSocialName?.length > 0 && { fullSocialName: inputSocialName }),
      birthDate,
    })
  }

  return [
    inputCpf,
    inputName,
    setInputName,
    inputPhone,
    setInputPhone,
    onClickNextScreen,
    showBirthDateInput,
    birthDate,
    setBirthDate,
    showNextButton,
    inputSocialName,
    setInputSocialName,
    showSocialName,
    setShowSocialName,
  ]
}
