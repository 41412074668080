import React, { forwardRef } from 'react'

import * as S from './styles'

export const Title = forwardRef((props, ref) => {
  return (
    <S.Title ref={ref} {...props}>
      {props.children}
    </S.Title>
  )
})
