import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { setupFirebase } from './services/firebase'
import { initSanarGtm } from './services/initSanarGTM'
import { initProviGtm } from './services/initProviGTM'

const isSanar = window.location.pathname.includes('/sanar')
const { REACT_APP_API_ENV } = process.env

// sentry active only production.
Sentry.init({
  dsn: 'https://0ea4e88b20864eeea5a081cc6d7f3b3c@o245612.ingest.sentry.io/5941603',
  environment: REACT_APP_API_ENV,
})

isSanar && REACT_APP_API_ENV === 'production' ? initSanarGtm() : initProviGtm()

// initial setup firebase
setupFirebase(REACT_APP_API_ENV)

moment.updateLocale('pt-br', require('moment/locale/pt-br'))
