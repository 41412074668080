import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { history } from '../../navigation/history'
import { getGuarantorInfo, putGuarantorInfo } from '../../services/guarantor.api'

export const usePhone = (checkToken, setIsLoading) => {
  const [phone, setPhone] = useState('')
  const [data, setData] = useState('')

  checkToken()

  useEffect(() => {
    async function getData() {
      const { data: responseData } = await getGuarantorInfo()

      setPhone(responseData?.phone)
      setData(responseData)
    }
    getData()
  }, [])

  const onClickNextScreen = async () => {
    try {
      setIsLoading(true)

      await putGuarantorInfo({ ...data, phone })

      history.push('/avalista/confirmacao-celular')
    } catch {
      toast.error('Houve ao adicionar número de celular, tente novamente')
    } finally {
      setIsLoading(false)
    }
  }

  return [phone, setPhone, onClickNextScreen]
}
