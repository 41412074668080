import React from 'react'

import { Modal } from '../../../components/Modal'
import { ModalContent } from './Content'

export const ModalComponent = ({ showModal, setShowModal, modalType }) => {
  const titles = { security: 'Meus dados estão seguros?', video: 'Por que precisamos do vídeo selfie ou da foto selfie?' }

  return (
    <Modal show={showModal} title={titles[modalType]} onDismiss={() => setShowModal('')}>
      <ModalContent modalType={modalType} />
    </Modal>
  )
}
