import styled from 'styled-components'

export const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.0075em;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`
