import React, { useContext } from 'react'

import { Template } from '../../components/template'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { SelectInput } from '../../components/SelectInput'
import { InputSanar } from '../../components/InputSanar'
import { RadioSelect } from '../../components/RadioSelect'
import Button from '../../components/Button'

import { radioOptions } from './constants'
import { useBankAccountScreen } from './hooks'
import { TemplateContext } from '../../components/template/context'
import { WarningModal } from './components/WarningModal'
import { Checking } from '../../components/Checking'
import { InvalidAccount } from './components/InvalidAccount'

import * as S from './styles'

export const BankAccount = () => {
  const { creditPath, goToNextScreen } = useContext(TemplateContext)
  const [
    fields,
    handleFields,
    showNextButton,
    bankOptions,
    showWarningModal,
    setShowWarningModal,
    isChecking,
    handleNextButton,
    isBlocked,
    isSanarUpFinanciamento,
  ] = useBankAccountScreen(creditPath, goToNextScreen)

  return (
    <Template haveHeader>
      {!isChecking && !isBlocked && (
        <S.Container>
          <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="Dados Bancários" />

          <S.Subtitle>
            Informe os dados da sua conta para receber {isSanarUpFinanciamento ? 'o financiamento' : 'a mesada'}
          </S.Subtitle>

          <S.Wrapper>
            <S.TextContainer>
              <strong>A sua conta bancária precisa:</strong>
              <p>- Estar em seu nome</p>
              <p>- Ser uma conta corrente ou uma conta poupança. Não aceitamos conta salário nem conta de pagamento</p>
              <p>
                - Ser uma conta sem limite máximo de saldo. Contas com limite podem bloquear a transferência{' '}
                {isSanarUpFinanciamento ? 'dos valores' : 'da mesada'}
              </p>
            </S.TextContainer>
          </S.Wrapper>

          <S.Wrapper>
            <SelectInput
              width="370px"
              label="Banco"
              options={bankOptions}
              value={fields.bank}
              onValueChange={(selection) => handleFields('bank', { value: selection.id, label: selection.label })}
            />

            <S.InputContainer>
              <S.InputWrapperLong>
                <InputSanar
                  label="Agência"
                  mask="9999"
                  placeholder="0000"
                  value={fields.agency}
                  onChange={(e) => handleFields('agency', e.target.value)}
                />
              </S.InputWrapperLong>

              <S.InputWrapperShort>
                <InputSanar
                  label="Dígito"
                  mask="9"
                  placeholder="0"
                  value={fields.agencyDigit}
                  onChange={(e) => handleFields('agencyDigit', e.target.value)}
                />
              </S.InputWrapperShort>
            </S.InputContainer>

            <S.InputContainer>
              <S.InputWrapperLong>
                <InputSanar
                  label="Conta"
                  mask="999999999"
                  placeholder="000000"
                  value={fields.account}
                  onChange={(e) => handleFields('account', e.target.value)}
                />
              </S.InputWrapperLong>

              <S.InputWrapperShort>
                <InputSanar
                  label="Dígito"
                  mask="9"
                  placeholder="0"
                  value={fields.accountDigit}
                  onChange={(e) => handleFields('accountDigit', e.target.value)}
                />
              </S.InputWrapperShort>
            </S.InputContainer>
          </S.Wrapper>

          <S.Wrapper>
            <RadioSelect
              options={radioOptions}
              group="bankAccount"
              text="Tipo de conta:  "
              selectedValue={fields.accountType}
              onSelectionChange={(value) => handleFields('accountType', value)}
            />
          </S.Wrapper>

          <S.Wrapper>
            <Button text="Avançar" footerButton disabled={!showNextButton} onClick={() => setShowWarningModal(true)} />
          </S.Wrapper>
        </S.Container>
      )}

      <Checking show={isChecking} subtitle="Estamos analisando os seus dados bancários" />

      {isBlocked && <InvalidAccount />}

      <WarningModal show={showWarningModal} setShow={setShowWarningModal} handleNextButton={handleNextButton} />
    </Template>
  )
}
