import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


  html, body{
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
  }


  .typeform-popover-wrapper iframe {
    @media (max-width: 320px) {
      width: 90% !important;
    }
  }
`
