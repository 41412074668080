import React, { useContext } from 'react'
import { TemplateContext } from '../../../components/template/context'
import { PreviewContainer } from './previewContainer'
import * as S from './styles'
import { VideoContainer } from './videoContainer'

export const Card = (props) => {
  const { title, icon, hiddenInMobile, preview, isVideo } = props

  const { isMobile } = useContext(TemplateContext)

  if (hiddenInMobile && isMobile) return <></>

  if (preview) {
    if (isVideo) return <VideoContainer {...props} />

    return <PreviewContainer {...props} />
  }

  return (
    <S.CardContainer {...props}>
      <S.CardTitle>{title}</S.CardTitle>
      {icon}
    </S.CardContainer>
  )
}
