import styled from 'styled-components'

import { theme } from '../../../theme'

export const Paragraph = styled.p`
  color: ${theme.colors.black};
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 10px 0;

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 20px;
    line-height: 30px;
  }
`
