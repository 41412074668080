import { history } from '../navigation/history'

export const getSlugPartner = () => {
  const pathname = history.location.pathname

  const split = pathname.split('/')
  const isTokenScreen = split.includes('token')

  if (isTokenScreen) return split[2]

  return split[1]
}
