import {
  Cpf,
  ValidateToken,
  BasicInfo,
  Products,
  PhoneVerification,
  CourseObjective,
  LinkExpired,
  Home,
  TeachingInfo,
  NotAMedic,
  Address,
  SchoolRecord,
  Selfie,
  RGBack,
  RGFront,
  ProofOfEnrollment,
  PreDocument,
  IntroDocument,
  WaitingGuarantorAnalysis,
  IntroGuarantor,
  WaitingDocumentAnalysis,
  CreditAnalysis,
  GuarantorNegativeFeedback,
  BankAccount,
  IntroSelfie,
  CNH,
  ThirdGuarantorNegativeFeedback,
  GuarantorPositiveFeedback,
  GuarantorCpf,
  WaitingSignature,
  EligibilityResponse,
  IncompleteBasicInfoAnalysis,
  BasicInfoAnalysis,
  InvalidPath,
} from '../screens'

export const routes = [
  {
    path: '/token/:partner/:token',
    component: ValidateToken,
  },
  {
    path: '/token/:partner/:token/campanha/:campaign',
    component: ValidateToken,
  },
  {
    path: '/:partner/token-invalido',
    component: LinkExpired,
  },
  {
    path: '/:partner/elegibilidade/:reason',
    component: EligibilityResponse,
  },
  {
    path: '/:partner/cpf',
    component: Cpf,
  },
  {
    path: '/:partner/cadastro',
    component: BasicInfo,
    uniqueName: 'basicInfo',
  },
  {
    path: '/:partner/analisando-cpf',
    component: BasicInfoAnalysis,
    uniqueName: 'basicInfoAnalysis',
  },
  {
    path: '/:partner/cpf-em-analise',
    component: IncompleteBasicInfoAnalysis,
    uniqueName: 'incompleteBasicInfoAnalysis',
  },
  {
    path: '/:partner/negado',
    component: InvalidPath,
    uniqueName: 'invalidPath',
  },
  {
    path: '/:partner/avalista-negado',
    component: GuarantorNegativeFeedback,
    uniqueName: 'secondGuarantorNegativeFeedback',
  },
  {
    path: '/:partner/avalista-negado',
    component: GuarantorNegativeFeedback,
    uniqueName: 'firstGuarantorNegativeFeedback',
  },
  {
    path: '/:partner/produtos',
    component: Products,
    uniqueName: 'products',
  },
  { path: '/:partner/objetivo', component: CourseObjective, uniqueName: 'courseObjective' },
  {
    path: '/:partner/endereco',
    component: Address,
    uniqueName: 'address',
  },
  {
    path: '/:partner/analisando-avalista',
    component: WaitingGuarantorAnalysis,
    uniqueName: 'waitingFirstGuarantorAnalysis',
  },
  {
    path: '/:partner/analisando-avalista',
    component: WaitingGuarantorAnalysis,
    uniqueName: 'waitingSecondGuarantorAnalysis',
  },
  {
    path: '/:partner/analisando-avalista',
    component: WaitingGuarantorAnalysis,
    uniqueName: 'waitingThirdGuarantorAnalysis',
  },
  {
    path: '/:partner/comprovante-matricula',
    component: ProofOfEnrollment,
    uniqueName: 'proofOfEnrollment',
  },
  {
    path: '/:partner/aguardando-assinatura',
    component: WaitingSignature,
    uniqueName: 'waitingSignature',
  },
  { path: '/:partner/historico-escolar', component: SchoolRecord, uniqueName: 'schoolRecord' },
  {
    path: '/:partner/informacoes-ensino',
    component: TeachingInfo,
    uniqueName: 'teachingInformation',
  },
  { path: '/:partner/produtos', component: Products, uniqueName: 'products' },
  {
    path: '/:partner/telefone-verificacao',
    component: PhoneVerification,
    uniqueName: 'phoneVerification',
  },
  {
    path: '/:partner/objetivo',
    component: CourseObjective,
    uniqueName: 'courseObjective',
  },
  {
    path: '/:partner/introdução-avalista',
    component: IntroGuarantor,
    uniqueName: 'introGuarantor',
  },
  {
    path: '/:partner/pre-documentos',
    component: PreDocument,
    uniqueName: 'preDocument',
  },
  { path: '/:partner/documento-pessoal-rg-parte-frente', component: RGFront, uniqueName: 'RGFront' },
  { path: '/:partner/documento-pessoal-rg-parte-tras', component: RGBack, uniqueName: 'RGBack' },
  { path: '/:partner/selfie', component: Selfie, uniqueName: 'selfie' },
  { path: '/:partner/em-analise', component: CreditAnalysis, uniqueName: 'creditAnalysis' },
  { path: '/:partner/CNH', component: CNH, uniqueName: 'CNH' },
  {
    path: '/:partner/intro-documentos',
    component: IntroDocument,
    uniqueName: 'introDocument',
  },
  {
    path: '/:partner/analisando',
    component: WaitingDocumentAnalysis,
    uniqueName: 'waitingDocumentAnalysis',
  },
  {
    path: '/:partner/entre-em-contato/',
    component: NotAMedic,
  },
  {
    path: '/:partner/dados-bancarios',
    component: BankAccount,
    uniqueName: 'bankAccount',
  },
  {
    path: '/:partner/intro-selfie',
    component: IntroSelfie,
    uniqueName: 'introSelfie',
  },
  {
    path: '/:partner/avalista-aprovado',
    component: GuarantorPositiveFeedback,
    uniqueName: 'guarantorPositiveFeedback',
  },
  {
    path: '/:partner/cpf-avalista',
    component: GuarantorCpf,
    uniqueName: 'firstGuarantorCPF',
  },
  {
    path: '/:partner/cpf-avalista-2',
    component: GuarantorCpf,
    uniqueName: 'secondGuarantorCPF',
  },
  {
    path: '/:partner/cpf-avalista-3',
    component: GuarantorCpf,
    uniqueName: 'thirdGuarantorCPF',
  },
  {
    path: '/:partner/analisando-cpf-avalista',
    component: WaitingGuarantorAnalysis,
    uniqueName: 'waitingFirstGuarantorAnalysis',
  },
  {
    path: '/:partner/analisando-cpf-avalista-2',
    component: WaitingGuarantorAnalysis,
    uniqueName: 'waitingSecondGuarantorAnalysis',
  },
  {
    path: '/:partner/analisando-cpf-avalista-3',
    component: WaitingGuarantorAnalysis,
    uniqueName: 'waitingThirdGuarantorAnalysis',
  },
  {
    path: '/:partner/avalistas-nao-aprovados',
    component: ThirdGuarantorNegativeFeedback,
    uniqueName: 'thirdGuarantorNegativeFeedback',
  },
  {
    path: '/:partner/avalista-aprovado',
    component: GuarantorPositiveFeedback,
    uniqueName: 'guarantorPositiveFeedback',
  },
  {
    path: '/',
    component: Home,
    uniqueName: 'home',
  },
]
