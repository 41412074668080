import { useState } from 'react'
import { removeMask } from '../../utils/removeMask'

export const useGuarantorCPF = (goToNextScreen) => {
  const [value, setValue] = useState('')
  const [showModal, setShowModal] = useState(false)

  const onClickNextScreen = () => {
    goToNextScreen({ data: removeMask(value) })
  }

  return [value, setValue, onClickNextScreen, showModal, setShowModal]
}
