import styled, { keyframes } from 'styled-components'
import { theme } from '../../theme'

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Overlay = styled.div`
  align-items: center;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 201;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  -webkit-animation-name: ${opacity};
  -webkit-animation-duration: 0.25s;
  animation-name: ${opacity};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
`

const scale = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

export const Container = styled.div`
  border: 2px solid #f4bd92;
  border-radius: 16px;
  position: relative;
  max-width: ${({ width }) => width};
  max-height: 600px;
  width: 90%;
  z-index: 15;
  max-height: 90vh;
  box-sizing: border-box;
  background: white;
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: 16px;
  animation-name: ${scale};
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  font-weight: 500;
`

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 25px;
`

export const ButtonClose = styled.a`
  cursor: pointer;
`

export const Title = styled.p`
  color: ${(props) => props.theme?.colors?.orangePrimary};
`

export const Body = styled.div`
  position: relative;
  margin-bottom: 20px;
`

export const Content = styled.div`
  ${theme.breakpoints.down('md')} {
    overflow-y: auto;
  }
`
