import styled, { css } from 'styled-components'

import { theme } from '../../../theme'

const limitWidth = css`
  max-width: 85%;
`

export const LargeText = styled.p`
  color: ${theme.colors.orangePrimary};
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  margin: 10px 0;

  ${(props) => props.limitWidth && limitWidth}

  @media (min-width: ${theme.breakpoints.sm}px) {
    font-size: 30px;
    line-height: 40px;
  }
`
