import React, { useContext, useEffect } from 'react'

import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import { Checking } from '../../components/Checking'

import * as S from './styles'

export const WaitingDocumentAnalysis = () => {
  const { goToNextScreen } = useContext(TemplateContext)

  useEffect(() => {
    goToNextScreen({}, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Template haveHeader>
      <S.Container>
        <Checking subtitle="Estamos analisando os seus documentos" show={true} />
      </S.Container>
    </Template>
  )
}
