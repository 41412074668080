import styled from 'styled-components'
import { theme } from '../../../theme'

export const Title = styled.h1`
  color: ${theme.black};
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  margin: 15px 0;

  > span {
    color: ${theme.colors.orangePrimary};
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    font-size: 22px;
  }
`
