import React from 'react'

import { Modal } from '../../../../components/Modal'
import { Link } from '../../../../components/Texts'
import Button from '../../../../components/Button'

import * as S from './styles'

export const CPFModal = ({ showModal, setShowModal }) => {
  return (
    <Modal show={showModal} title="Por que precisamos do seu CPF?" onDismiss={() => setShowModal(false)}>
      <S.ModalText>
        Preciamos do seu CPF para fazer uma análise mais completa e te oferecer as melhores opções de financiamento.
      </S.ModalText>
      <S.ModalText>Não se preocupe! O seu histórico de crédito não é decisivo para nossa análise.</S.ModalText>
      <S.ModalText>
        Se você está recebendo a mensagem que o seu CPF já está cadastrado em outra conta e você não lembra como logou aqui na
        Provi, entre em contato com a gente.
      </S.ModalText>

      <S.ModalWrapper>
        <Button text="Entendi" width="220px" mobileWidth="220px" onClick={() => setShowModal(false)} />
        <S.LinkWrapper>
          <Link
            onClick={() =>
              window
                .open(
                  'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                  '_blank',
                )
                .focus()
            }
          >
            Entrar em contato
          </Link>
        </S.LinkWrapper>
      </S.ModalWrapper>
    </Modal>
  )
}
