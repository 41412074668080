import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { theme as localTheme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 30px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const ContainerInputs = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`

export const RowWrapper = styled.div`
  display: flex;

  ${theme.breakpoints?.down('md')} {
    flex-direction: column;
    row-gap: 20px;
  }
`

export const Link = styled.p`
  width: 342px;
  height: 24px;

  margin-top: 40px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: ${localTheme.colors.orangePrimary};

  cursor: pointer;

  ${theme.breakpoints?.down('md')} {
    font-size: 13.2px;
    margin-top: 20px;
  }
`

export const SubTitle = styled.p`
  width: 311px;
  height: 24px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15.84px;
  line-height: 24px;

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  ${theme.breakpoints?.up('md')} {
    display: none;
  }
`
