import React from 'react'
import { GuarantorUploaded } from './guarantorUploaded'
import * as S from './styles'
import { VideoContainer } from './videoContainer'

export const RenderGuarantor = (props) => {
  const { getInputProps, renderDragMessage, icon, isVideo, disableBorder } = props

  if (isVideo) return disableBorder ? <GuarantorUploaded {...props} /> : <VideoContainer {...props} />

  return (
    <S.GuarantorContainer {...props}>
      <input {...getInputProps()} />
      {renderDragMessage() || (
        <S.ContainerDefault>
          <p>Fazer upload</p> {icon}
        </S.ContainerDefault>
      )}
    </S.GuarantorContainer>
  )
}
