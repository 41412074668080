import React from 'react'
import { FeedbackIcon } from '../../../assets/svg/FeedbackIcon'
import { TextFeedback } from '../../../components/File/styles'
import * as S from './styles'

export const VideoContainer = ({ icon, onClickFooter }) => {
  return (
    <S.CardContainer disableCursor>
      {icon}
      <S.FlexWrapper>
        <FeedbackIcon />
        <TextFeedback>Upload concluído</TextFeedback>
      </S.FlexWrapper>
      <S.TextFooter onClick={onClickFooter}>Gravar outro vídeo</S.TextFooter>
    </S.CardContainer>
  )
}
