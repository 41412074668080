import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { history } from '../../navigation/history'
import { uploadImages } from '../../services/api'
import { getPersonalDocument, postPersonalDocument } from '../../services/guarantor.api'

export const GuarantorHooks = (isGuarantor, files, setFilesUploaded, setIsLoading, checkToken) => {
  useEffect(() => {
    const getData = async () => {
      const response = await getPersonalDocument('CNH')
      const { data } = response.data

      setFilesUploaded(data?.map((fileUploaded) => ({ url: fileUploaded.data, ...fileUploaded }))?.reverse())
    }

    if (isGuarantor) {
      checkToken()
      getData()
    }
  }, [isGuarantor, setFilesUploaded, checkToken])

  const handleSubmit = async () => {
    try {
      setIsLoading(true)

      if (files?.length) {
        const urls = await uploadImages(files)

        await postPersonalDocument({
          downloadURL: urls.map(({ url, ...upload }) => ({ data: url, ...upload })),
          documentType: 'CNH',
          photoSide: 'FRONT',
        })

        return history.push('/avalista/foto-ou-video')
      }

      history.push('/avalista/foto-ou-video')
    } catch {
      toast.error('Ocorreu um erro ao enviar o arquivo. Tente novamente ou entre em contato com a Provi.')
    } finally {
      setIsLoading(false)
    }
  }

  return [handleSubmit]
}
