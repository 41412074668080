import styled, { css } from 'styled-components'
import { theme } from '../../theme'

export const View = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  ${theme.breakpoints.down('sm')} {
    width: 100%;
    .select-menu {
      * {
        font-size: 13.2px;
        line-height: 20px;
        letter-spacing: 0.005em;
      }
    }
  }
  ${({ isVariant }) =>
    isVariant &&
    css`
      > label.select-label {
        color: ${theme.colors.white};
      }
      > span.select-explainer {
        color: ${theme.colors.white};
      }
    `}
`

export const Label = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  ${theme.breakpoints.down('sm')} {
    font-size: 13.2px;
    line-height: 20px;
  }
`

export const Explainer = styled.span`
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  margin-top: 7px;
  ${theme.breakpoints.down('sm')} {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.0075em;
  }
`

const borderColor = (isFocused, variant) => {
  if (variant) {
    return isFocused ? `2px solid ${theme.colors.black}` : `2px solid ${theme.colors.white}`
  }
  return isFocused ? `2px solid ${theme.colors.greyPrimary}` : `2px solid ${theme.colors.greyLight}`
}

export const customStyles = (variant) => ({
  option: () => ({
    padding: 12,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.colors.black,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: theme.colors.bluePrimaryLight,
      color: theme.colors.bluePrimaryDark,
    },
  }),
  control: (provided, state) => ({
    ...provided,
    height: 48,
    marginTop: 8,
    fontFamily: 'Montserrat, sans serif',
    zIndex: 200,
    cursor: 'pointer',
    border: borderColor(state.isFocused, variant),
    boxShadow: 'none',
    '&:hover': {
      // leaving this empty so it can override the default hover color
    },
    borderRadius: 4,
  }),
  input: (provided) => ({
    ...provided,
    color: theme.colors.blue800,
    fontSize: 16,
    fontFamily: 'Montserrat, sans serif',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: theme.colors.black,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.0075em',
    color: theme.colors.black,
  }),
  placeholder: () => ({
    fontFamily: 'Montserrat, sans serif',
    color: theme.colors.greyPrimary,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.0075em',
    fontWeight: 500,
  }),
  indicatorSeparator: () => ({}),
  menuList: (menuList) => ({
    ...menuList,
    padding: 0,
    zIndex: 201,
  }),
  menu: (provided) => ({
    ...provided,
    borderCollapse: 'separate',
    border: `2px solid ${theme.colors.greyLight}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    overflow: 'auto',
    fontFamily: 'Montserrat, sans serif',
    boxShadow: '',
    marginTop: -2,
    zIndex: 201,
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 20,
  }),
})
