import React from 'react'

export const ProviLogo = () => (
  <svg width="82" height="24" viewBox="0 0 82 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.8246 13.1649C39.8246 16.7527 37.1848 19.5221 33.9827 19.5221C32.344 19.5221 31.1458 18.9682 30.34 18.0527V24.0007H26.6721V7.14646H30.34V8.27896C31.1458 7.36345 32.344 6.80957 33.9827 6.80957C37.1848 6.80957 39.8246 9.57705 39.8246 13.1649ZM36.1568 13.1649C36.1568 11.3586 34.9335 10.228 33.2484 10.228C31.5613 10.228 30.34 11.3605 30.34 13.1649C30.34 14.9712 31.5632 16.1018 33.2484 16.1018C34.9354 16.1018 36.1568 14.9712 36.1568 13.1649Z"
      fill="#2647D7"
    />
    <path
      d="M49.2359 6.9043V10.9965C47.7208 10.7567 45.57 11.3582 45.57 13.7412V19.1829H41.9021V7.14602H45.57V9.2892C46.057 7.67516 47.6957 6.9043 49.2359 6.9043Z"
      fill="#2647D7"
    />
    <path
      d="M49.9683 13.1649C49.9683 9.57705 52.8515 6.80957 56.446 6.80957C60.0404 6.80957 62.9237 9.57896 62.9237 13.1649C62.9237 16.7527 60.0385 19.5221 56.446 19.5221C52.8535 19.5221 49.9683 16.7527 49.9683 13.1649ZM59.2577 13.1649C59.2577 11.4557 58.0345 10.3232 56.446 10.3232C54.8575 10.3232 53.6342 11.4557 53.6342 13.1649C53.6342 14.8741 54.8575 16.0066 56.446 16.0066C58.0364 16.0066 59.2577 14.8741 59.2577 13.1649Z"
      fill="#2647D7"
    />
    <path
      d="M76.3698 7.14648L71.8478 19.1852H67.6929L63.1709 7.14648H67.2291L69.7723 14.7789L72.3154 7.14648H76.3698Z"
      fill="#2647D7"
    />
    <path
      d="M77.3477 3.91993C77.3477 2.73985 78.3506 1.75391 79.5468 1.75391C80.743 1.75391 81.746 2.74175 81.746 3.91993C81.746 5.10001 80.743 6.08785 79.5468 6.08785C78.3506 6.08785 77.3477 5.09811 77.3477 3.91993ZM77.7148 7.14612H81.3827V19.1849H77.7148V7.14612Z"
      fill="#2647D7"
    />
    <path
      d="M21.3868 4.13615C16.7006 -1.1628 8.52807 -1.37026 3.56737 3.51375C1.16915 5.87772 -0.0173924 8.9802 0.00193249 12.077L0 12.0751V23.9996H12.107L12.1051 23.9977C15.4831 24.0186 18.8688 22.6672 21.3211 19.9378C25.3368 15.4668 25.3678 8.63759 21.3868 4.13615ZM15.4502 15.2175C13.6453 16.9952 10.7195 16.9952 8.91456 15.2175C7.10962 13.4398 7.10962 10.5581 8.91456 8.78034C10.7195 7.00261 13.6453 7.00261 15.4502 8.78034C17.2552 10.5581 17.2552 13.4398 15.4502 15.2175Z"
      fill="#2647D7"
    />
  </svg>
)
