import React, { useContext } from 'react'

import { Template } from '../../components/template'
import { TemplateContext } from '../../components/template/context'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { CopyPastIcon } from '../../assets/svg/copy-paste-icon'
import TypeFormIntegration from '../../components/TypeformIntegration'

import * as S from './styles'

export const GuarantorPositiveFeedback = () => {
  const { creditPath } = useContext(TemplateContext)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar title="avalista aprovado" percent={creditPath?.progress?.percentageCompleted} canBack={false} />
        <S.Wrapper>
          <S.Title>Que ótimo!</S.Title>

          <S.Subtitle>
            Seu avalista foi aprovado.
            <br />A sua vaga já está garantida.
          </S.Subtitle>
        </S.Wrapper>

        <S.Wrapper>
          <S.Description>Agora só falta você enviar o link abaixo para ele preencher alguns dados.</S.Description>
        </S.Wrapper>

        <S.Wrapper>
          <S.Link href={creditPath?.readGuarantorPositiveFeedback?.link} target="_blank">
            {creditPath?.readGuarantorPositiveFeedback?.link}
          </S.Link>
        </S.Wrapper>

        <S.Wrapper>
          <S.CopyButton onClick={() => navigator.clipboard.writeText(creditPath?.readGuarantorPositiveFeedback?.link)}>
            <CopyPastIcon />
            <p>Copiar link</p>
          </S.CopyButton>
        </S.Wrapper>
        <TypeFormIntegration id="pOLHbQb9" />
      </S.Container>
    </Template>
  )
}
