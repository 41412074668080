import React from 'react'
import * as S from './styles'
import Button from '../Button'

export const ArrayContent = ({ children, isMobile, onClickLink, onClick, disabled, textLink, text }) => {
  return (
    <>
      <S.ContainerInputs>
        {children}
        {isMobile &&
          textLink.map((linkText, index) => (
            <S.Link key={linkText} onClick={onClickLink[index]}>
              {linkText}
            </S.Link>
          ))}
      </S.ContainerInputs>
      <Button text={text} disabled={disabled} footerButton onClick={onClick} />
      {!isMobile &&
        textLink.map((linkText, index) => (
          <S.Link key={linkText} onClick={onClickLink[index]}>
            {linkText}
          </S.Link>
        ))}
    </>
  )
}
