import React, { useContext } from 'react'

import { TemplateContext } from '../../components/template/context'
import Button from '../../components/Button'
import { Template } from '../../components/template'
import { Paragraph } from '../../components/Texts'
import { RadioSelect } from '../../components/RadioSelect'
import { SelectInput } from '../../components/SelectInput'
import { CheckBox } from '../../components/Checkbox'
import { InputSanar } from '../../components/InputSanar'
import { SanarProgressBar } from '../../components/SanarProgressBar'

import { InvalidInfoModal, InvalidDateModal } from './components'
import { useTeachingInfo } from './hooks'
import { radioOptions, selectFormationOptions, selectFormationOptionsSanarUp } from './constants'

import * as S from './styles'

export const TeachingInfo = () => {
  const { creditPath, goToNextScreen } = useContext(TemplateContext)
  const [
    fields,
    handleFields,
    showNextButton,
    handleNextButton,
    showInvalidInfoModal,
    setShowInvalidInfoModal,
    showInvalidDateModal,
    setShowInvalidDateModal,
    handleCheckBox,
    rangeMessage,
    isSanarUpFinanciamento,
  ] = useTeachingInfo(creditPath, goToNextScreen)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar title="Informações de Ensino" percent={creditPath?.progress?.percentageCompleted} />

        <S.ParagraphWrapper>
          <Paragraph>
            {isSanarUpFinanciamento
              ? 'O Financiamento da Sanar UP está disponível para pessoas matriculadas no curso de medicina que estejam no 6º ano da graduação.'
              : 'A Mesada de Sanar UP está disponível apenas para pessoas matriculadas no curso de medicina que estejam do 3º ao 6º ano da graduação ou na residência médica.'}
          </Paragraph>
        </S.ParagraphWrapper>

        <S.Wrapper>
          <RadioSelect
            options={radioOptions}
            group="medicine"
            text="Você cursa medicina?"
            selectedValue={fields.medicineRadioValue}
            onSelectionChange={(value) => handleFields('medicineRadioValue', value === 'true')}
          />
        </S.Wrapper>

        <S.Wrapper>
          <SelectInput
            width="100%"
            label="Etapa de formação"
            options={isSanarUpFinanciamento ? selectFormationOptionsSanarUp : selectFormationOptions}
            value={fields.formationStep}
            onValueChange={(selection) => handleFields('formationStep', selection)}
          />
        </S.Wrapper>

        <S.Wrapper>
          <InputSanar
            width="100%"
            label="Data prevista para formatura (mês e ano)"
            mask="99/9999"
            placeholder="06/2022"
            value={fields.previewDate}
            onChange={(e) => handleFields('previewDate', e.target.value)}
          />
          <S.DateRangeMessage show={rangeMessage?.show}>
            Exclusivo para quem vai se formar entre {rangeMessage?.minDate} e {rangeMessage?.maxDate}
          </S.DateRangeMessage>
        </S.Wrapper>

        <S.Wrapper>
          <SelectInput
            width="100%"
            label="Selecione sua faculdade"
            explainer="Apenas instituições brasileiras são permitidas"
            options={creditPath?.readTeachingInformation?.universities}
            value={fields.university}
            isDisabled={fields.checkboxValue}
            onValueChange={(selection) => handleFields('university', { value: selection?.id, label: selection?.label })}
          />
          <S.CheckboxWrapper>
            <CheckBox text="Não encontrei minha faculdade" checked={fields.checkboxValue} onChange={handleCheckBox} />
          </S.CheckboxWrapper>
        </S.Wrapper>

        <S.Wrapper>
          <RadioSelect
            options={radioOptions}
            group="finance"
            text="Você possui um financiamento estudantil?"
            selectedValue={fields.financeRadioValue}
            onSelectionChange={(value) => handleFields('financeRadioValue', value === 'true')}
          />
        </S.Wrapper>

        <S.HideInputWrapper show={fields.financeRadioValue}>
          <InputSanar
            label="Que tipo de financiamento?"
            width="100%"
            placeholder="FIES"
            value={fields.financeName}
            onChange={(e) => handleFields('financeName', e.target.value)}
          />
        </S.HideInputWrapper>

        <S.Wrapper>
          <Button text="Avançar" footerButton disabled={!showNextButton} onClick={handleNextButton} />
        </S.Wrapper>
      </S.Container>

      <InvalidInfoModal show={showInvalidInfoModal} setShow={setShowInvalidInfoModal} />
      <InvalidDateModal show={showInvalidDateModal} setShow={setShowInvalidDateModal} />
    </Template>
  )
}
