import React, { useContext, useEffect, useState } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import { TemplateContext } from '../../components/template/context'
import { Checking } from '../../components/Checking'
import useCountDown from '../../hooks/useCountdown'
import { writeIncompleteAnalysis } from '../../components/template/hooks'
import { getCreditPath, postCreditPath } from '../../services/api'
import { getSlugPartner } from '../../utils/getSlugPartner'
import { getPathname } from '../../utils/getPathName'
import { navigateWithParams } from '../../utils/navigateWithParams'
import { useInterval } from '../../hooks/useInterval'

export const BasicInfoAnalysis = () => {
  const { creditPath, setCreditPath } = useContext(TemplateContext)
  const { timeLeft: timeLeftDeadLine, actions: actionsDeadLine } = useCountDown(40 * 1000, 1000)
  const [timeOut, setTimeLeft] = useState(false)
  const { start: startDeadLine } = actionsDeadLine
  const { screenWaitingTime = 0, timeBetweenRequests = 10000 } = creditPath?.readBasicInfoAnalysis || {}

  useInterval(() => {
    ;(async () => {
      if (window.hasRedirected) {
        return
      }
      const {
        data: { nextScreen },
      } = await postCreditPath({ currentScreen: 'basicInfoAnalysis', screen: 'basicInfoAnalysis' }, getSlugPartner())
      const pathname = getPathname(nextScreen)
      if (nextScreen !== 'basicInfoAnalysis') {
        window.hasRedirected = true
        const response = await getCreditPath(getSlugPartner())
        setCreditPath(response)
        navigateWithParams(`/${getSlugPartner()}/${pathname}`)
      }
    })()
  }, timeBetweenRequests)

  useEffect(() => {
    if (screenWaitingTime) {
      startDeadLine(screenWaitingTime)
    }
  }, [startDeadLine, screenWaitingTime])

  useEffect(() => {
    ;(async () => {
      const hasTimedOut = timeLeftDeadLine === 0

      if (hasTimedOut && !timeOut) {
        setTimeLeft(true)

        await writeIncompleteAnalysis()
      }
    })()
  }, [timeLeftDeadLine, timeOut])

  return (
    <Template haveHeader>
      <S.Container>
        <Checking subtitle="Estamos realizando a nossa análise!" show={true} />
      </S.Container>
    </Template>
  )
}
