import React, { useContext } from 'react'

import * as S from './styles'
import { TemplateContext } from '../../../components/template/context'
import { MobileCard } from '../MobileCard'

export const Card = (props) => {
  const { title, image, paragraph, onClickButton, buttonLabel } = props
  const { isMobile } = useContext(TemplateContext)

  if (isMobile) return <MobileCard {...props} />

  return (
    <S.Card>
      <S.CardTitle>{title}</S.CardTitle>
      {image}
      <S.CardParagraph>{paragraph}</S.CardParagraph>
      <S.CardButton onClick={onClickButton}>{buttonLabel}</S.CardButton>
    </S.Card>
  )
}
