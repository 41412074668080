import styled from 'styled-components'

import { theme } from '../../theme'
import LogoSanar from '../../assets/images/background_sanar_v2.png'

export const Container = styled.div`
  font-family: 'Montserrat', sans serif;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: url(${LogoSanar});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 70%;
  background-size: 60%;

  @media (max-width: ${theme.breakpoints.md}px) {
    background: ${theme.colors.white};
  }
`

export const Header = styled.header`
  max-width: 100vw;
  padding: 80px 72px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 40px 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;

  row-gap: 24px;

  padding-left: 72px;

  display: flex;
  justify-content: center;
  height: 60%;
  width: 40%;
  max-width: 800px;

  @media (max-width: ${theme.breakpoints.md}px) {
    padding: 24px;
    width: 100%;
  }
`

export const Title = styled.h2`
  font-size: 26px;
  line-height: 36px;

  font-weight: 600;
  color: ${theme.colors.orangePrimary};

  margin: 0;

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 36px;
    line-height: 46px;
  }
`

export const SubTitle = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  margin: 0;

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const ContainerLogo = styled.span`
  @media (max-width: ${theme.breakpoints.md}px) {
    display: none;
  }
  @media (max-width: ${theme.breakpoints.sm}px) {
    display: inline;
  }
`

export const ContainerLogoProvi = styled.span`
  position: absolute;
  bottom: 50px;
  left: 72px;
`
