import React from 'react'
import Select from 'react-select'
import { customStyles, View, Label, Explainer } from './styles'

/**
 * @typedef {object} ReactSelectValue
 * @property {number | string} value
 * @property {string} label
 */

/**
 * @typedef {object} SelectorProps
 * @property {Array<ReactSelectValue>} [options]
 * @property {ReactSelectValue} [value]
 * @property {((selectedValue: ReactSelectValue) => Void)} [onValueChange]
 * @property {string} [name]
 * @property {string} [placeholder]
 * @property {string} [explainer]
 * @property {boolean} [variant]
 */

/** @type {import('react').FC<SelectorProps>} */
export const SelectInput = ({
  options = [],
  value,
  onValueChange,
  name,
  width = '378px',
  placeholder = 'Selecionar',
  label,
  explainer,
  variant = false,
  ...props
}) => (
  <View width={width} isVariant={variant}>
    {label && <Label className="select-label">{label}</Label>}
    {explainer && <Explainer className="select-explainer">{explainer}</Explainer>}

    <Select
      inputProps={{ 'aria-label': { name } }}
      noOptionsMessage={() => 'Sem opçōes'}
      styles={customStyles(variant)}
      options={options}
      value={value}
      onChange={onValueChange}
      placeholder={placeholder}
      className="select-menu"
      {...props}
    />
  </View>
)
