import { useCallback, useContext, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { TemplateContext } from '../../components/template/context'
import { errorMessages, requiredErrorMessages } from '../../constants/errors'
import { getAddressFromCep } from '../../services/api'
import { removeMask } from '../../utils/removeMask'
import { validateCEP } from '../../utils/validateCEP'
import { useGuarantorAddress } from './guarantorHooks'
import { brazilianStates } from './states'

export const useAddressHooks = () => {
  const { creditPath, goToNextScreen, checkToken, setIsLoading } = useContext(TemplateContext)
  const isGuarantor = window?.location?.pathname?.includes('/avalista/')
  const { guarantorNextScreen } = useGuarantorAddress(checkToken, setIsLoading, isGuarantor)
  const streetNumberInputRef = useRef(null)
  const nbhInputRef = useRef(null)

  const proceed = useCallback(
    (valuesAddressInfos) => {
      const removeMaskCEP = removeMask(valuesAddressInfos.zipcode)

      if (isGuarantor) {
        return guarantorNextScreen({
          ...valuesAddressInfos,
          zipcode: removeMaskCEP,
        })
      }

      goToNextScreen({
        ...valuesAddressInfos,
        zipcode: removeMaskCEP,
      })
    },
    [isGuarantor, guarantorNextScreen, goToNextScreen],
  )

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    handleSubmit,
    isValid,
    dirty,
    validateForm,
    validateField,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      zipcode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      number: '',
      complement: '',
    },

    validationSchema: Yup.object({
      zipcode: Yup.string().required(requiredErrorMessages.cep),
      street: Yup.string().required(requiredErrorMessages.street).min(4, errorMessages.street),
      number: Yup.string().required(requiredErrorMessages.streetNumber),
      state: Yup.string().required(requiredErrorMessages.state),
      city: Yup.string().required(requiredErrorMessages.city).min(3, errorMessages.city),
      district: Yup.string().required(requiredErrorMessages.district).min(3, errorMessages.district),
      complement: Yup.string().notRequired().nullable(),
    }),

    onSubmit: proceed,

    validate: (fields) => {
      const fieldsArray = Object.keys(fields)
      return fieldsArray.reduce((prev, cur) => {
        if (cur === 'zipcode' && fields[cur] && !validateCEP(fields[cur]) && removeMask(fields[cur]).length !== 8) {
          return {
            ...prev,
            [cur]: errorMessages.cep,
          }
        }

        return {
          ...prev,
        }
      }, {})
    },
  })

  const populateInputs = useCallback(
    ({ zipcode, city, state, district, street, number, complement }) => {
      setFieldValue('zipcode', zipcode || '')
      setFieldValue('state', state || '')
      setFieldValue('city', city || '')
      setFieldValue('district', district || '')
      setFieldValue('street', street || '')
      setFieldValue('number', number || '')
      setFieldValue('complement', complement || '')

      zipcode && setFieldTouched('zipcode')
      state && setFieldTouched('state')
      city && setFieldTouched('city')
      district && setFieldTouched('district')
      street && setFieldTouched('street')
      number && setFieldTouched('number')
      complement && setFieldTouched('complement' || '')

      validateForm()
    },
    [setFieldValue, setFieldTouched, validateForm],
  )

  useEffect(() => {
    async function getAddress() {
      try {
        const data = await getAddressFromCep(values.zipcode)

        setFieldValue('state', data?.uf || '')
        setFieldValue('district', data?.bairro || '')
        setFieldValue('city', data?.localidade || '')
        setFieldValue('street', data?.logradouro || '')

        data?.uf && setFieldTouched('state')
        data?.bairro && setFieldTouched('district')
        data?.localidade && setFieldTouched('city')
        data?.logradouro && setFieldTouched('street')

        if (!!data?.uf && !!data?.localidade && !data?.logradouro) {
          nbhInputRef.current?.focus()
          return
        }

        if (data?.logradouro) {
          streetNumberInputRef.current?.focus()
          return
        }

        if (!data) {
          document.getElementById('input-state')?.focus()
          return
        }
      } catch {
        document.getElementById('react-select-2-input')?.focus()
      } finally {
        setIsLoading(false)
      }
    }

    if (values?.zipcode?.length === 10) {
      setIsLoading(true)
      getAddress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, values.zipcode, validateField])

  useEffect(() => {
    if (values.zipcode?.length === 8) {
      setFieldTouched('zipcode')
      validateField('zipcode')
      validateForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.zipcode])

  useEffect(() => {
    if (creditPath?.readAddress) {
      const { readAddress } = creditPath
      populateInputs(readAddress)
    }
  }, [creditPath?.readAddress, populateInputs])

  const brazilianStatesSelector = brazilianStates.find((option) => option.acronym === values.state)

  const getObjectFromAcronym = useCallback(
    (acronym) => {
      const filteredObject = brazilianStates.find((option) => option.acronym === acronym)
      return setFieldValue('state', filteredObject?.acronym)
    },
    [setFieldValue],
  )

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      !isSubmitting && handleSubmit(event)
    }
  }

  return {
    values,
    setFieldValue,
    errors,
    touched,
    validateForm,
    setFieldTouched,
    isValid,
    dirty,
    getObjectFromAcronym,
    brazilianStatesSelector,
    handleSubmit,
    creditPath,
    nbhInputRef,
    streetNumberInputRef,
    handleEnterKey,
  }
}
