import { useState, useEffect } from 'react'

import { getCreditRequestInfo } from '../../services/guarantor.api'

export const useAnalysis = (setIsLoading) => {
  const [userName, setUserName] = useState('')

  useEffect(() => {
    setIsLoading(true)
    async function getData() {
      const response = await getCreditRequestInfo()

      if (response) {
        setUserName(response.data.user.firstName)
        setIsLoading(false)
      }
    }

    getData()
  }, [setIsLoading])

  return [userName]
}
