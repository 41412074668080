import React, { useContext } from 'react'

import { SanarProgressBar } from '../../components/SanarProgressBar'
import { Template } from '../../components/template'
import * as S from './styles'
import { InputSanar } from '../../components/InputSanar'
import Button from '../../components/Button'
import { CheckBox } from '../../components/Checkbox'
import { TemplateContext } from '../../components/template/context'
import { useBasicInfo } from './hooks'

export const BasicInfo = () => {
  const { creditPath, goToNextScreen } = useContext(TemplateContext)

  const [
    inputCpf,
    inputName,
    setInputName,
    inputPhone,
    setInputPhone,
    onClickNextScreen,
    showBirthDateInput,
    birthDate,
    setBirthDate,
    showNextButton,
    inputSocialName,
    setInputSocialName,
    showSocialName,
    setShowSocialName,
  ] = useBasicInfo(creditPath, goToNextScreen)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="Cadastro" canBack={false} />

        <S.Wrapper>
          <InputSanar
            label="CPF"
            width="370px"
            mobileWidth="370px"
            mask="999.999.999-99"
            placeholder="123.456.789-10"
            value={inputCpf}
            disabled
          />
        </S.Wrapper>

        <S.Wrapper>
          <InputSanar
            label="Nome como aparece no documento"
            width="370px"
            mobileWidth="370px"
            placeholder="Nome"
            value={inputName}
            onChange={(e) => setInputName(e.target.value)}
          />
        </S.Wrapper>

        <S.CheckboxWrapper>
          <CheckBox
            text="Quero informar um nome social"
            checked={showSocialName}
            onChange={() => setShowSocialName((prevState) => !prevState)}
          />
        </S.CheckboxWrapper>

        {showSocialName && (
          <S.Wrapper>
            <InputSanar
              label="Nome social"
              width="370px"
              mobileWidth="370px"
              placeholder="Nome"
              value={inputSocialName}
              onChange={(e) => setInputSocialName(e.target.value)}
            />
          </S.Wrapper>
        )}

        {showBirthDateInput && (
          <S.Wrapper>
            <InputSanar
              label="Data de Nascimento"
              width="370px"
              mobileWidth="370px"
              mask="99/99/9999"
              placeholder="00/00/0000"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </S.Wrapper>
        )}

        <S.Wrapper>
          <InputSanar
            label="Celular"
            width="370px"
            mobileWidth="370px"
            mask="(99) 9 9999-9999"
            placeholder="(10) 9 8765-4321"
            value={inputPhone}
            onChange={(e) => setInputPhone(e.target.value)}
          />
        </S.Wrapper>

        <S.Wrapper>
          <Button text="Avançar" disabled={!showNextButton} footerButton onClick={onClickNextScreen} />
        </S.Wrapper>
      </S.Container>
    </Template>
  )
}
