import React, { useCallback, useState, useEffect } from 'react'

import { formatCurrancy } from '../../../../utils/intl'
import { getSlugPartner } from '../../../../utils/getSlugPartner'

export const useCardV2 = (isSelected, isCollapsed, setIsCollapsed, setSelected, option) => {
  const [pricePerMonth, setPricePerMonth] = useState('')
  const [isSanarUpFinanciamento, setisSanarUpFinanciamento] = useState(false)

  useEffect(() => {
    const partnerSlug = getSlugPartner()

    if (partnerSlug === 'sanarup-financiamento') setisSanarUpFinanciamento(true)
  }, [])

  useEffect(() => {
    const { details } = option

    const getAllowanceValue = (array) => {
      return array
        .find((sentence) => sentence.includes('R$'))
        .split(' ')
        .find((sentence) => sentence.includes('R$'))
        .replace(/[^\d]+/g, '')
    }

    const formattedPrice = formatCurrancy(getAllowanceValue(details))

    setPricePerMonth(formattedPrice)
  }, [option])

  const handleFooterMessage = useCallback(() => {
    if (isCollapsed) {
      return 'Ver detalhes'
    }

    if (isSelected) {
      return isSanarUpFinanciamento ? 'Plano selecionado' : 'Mesada selecionada'
    }

    return isSanarUpFinanciamento ? 'Selecionar plano' : 'Selecionar mesada'
  }, [isCollapsed, isSelected, isSanarUpFinanciamento])

  const handleFooterClick = () => {
    if (isCollapsed) setIsCollapsed(false)
    else {
      setSelected(option)
      setIsCollapsed(true)
    }
  }

  const handleTitle = useCallback((str) => {
    const [title] = str.split('|')

    return (
      <>
        <h1>{title}</h1>
      </>
    )
  }, [])

  return [handleFooterMessage, handleFooterClick, handleTitle, pricePerMonth]
}
