import { v4 as uuidv4 } from 'uuid'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import { LOCALSTORAGE_TOKENS } from '../constants'
import { ERRORS } from '../constants/errors'
import { logger, setLogger } from '../utils/logger'

export const interceptApi = (api) => {
  const transactionId = uuidv4().replace(/-/g, '')

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(LOCALSTORAGE_TOKENS.token)
      if (token) {
        Sentry.configureScope(function (scope) {
          scope.setTag('x-request-id', transactionId)
        })
        config.headers['x-request-id'] = transactionId
        config.headers.Authorization = token
        setLogger()
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status !== 401) {
        logger(error)

        const errorsArray = error?.response?.data?.errors || [error?.response?.data?.error] || []

        if (!['array', 'object'].includes(typeof errorsArray)) {
          toast.error(ERRORS.default)
          return
        }

        errorsArray.forEach((err) => {
          toast.error(err?.message || ERRORS.default)
        })
      }

      return Promise.reject(error)
    },
  )
}
