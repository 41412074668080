import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { authToken, getAccessToken } from '../../services/guarantor.api'
import { toast } from 'react-toastify'
import { history } from '../../navigation/history'

export const useGuarantorIntroduction = () => {
  const [userName, setUserName] = useState('')
  const [cpf, setCpf] = useState('')
  const { token, screen } = useParams()

  useEffect(() => {
    const getInfos = async () => {
      try {
        if (!token) {
          setTimeout(() => {
            if (!token) history.push('/avalista/token-invalido')
          }, 3000)
        }

        const obj = {
          phone: '/avalista/celular',
          default: '/avalista/resumo',
        }

        if (token && screen) {
          localStorage.setItem('guarantorToken', token)

          return history.push(obj[screen])
        }

        const response = await getAccessToken(token)
        const { name } = response?.data

        if (name) setUserName(name)
      } catch (error) {
        toast.error('Erro ao buscar avalista, revise o token e tente novamente')
      }
    }
    getInfos()
  }, [token, screen])

  const onClick = async () => {
    try {
      const response = await authToken(cpf, token)

      localStorage.setItem('guarantorToken', response?.data?.token)
      localStorage.setItem('publicToken', token)

      if (response) history.push('/avalista/resumo')
    } catch (error) {
      toast.error('Erro ao buscar avalista, revise o token e tente novamente')
    }
  }

  return [userName, onClick, cpf, setCpf]
}
