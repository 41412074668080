import styled from 'styled-components'

import { theme } from '../../../../theme'

export const ModalText = styled.p`
  color: ${theme.colors.black};
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
`

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
`
