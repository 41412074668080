import React from 'react'

import { Modal } from '../../../components/Modal'
import { ModalText } from '../../cpf/components/CPFModal/styles'

export const ModalComponent = ({ showModal, setShowModal }) => {
  return (
    <Modal show={showModal} title="O que é o histórico acadêmico?" onDismiss={() => setShowModal(false)}>
      <ModalText>
        O histórico acadêmico é um documento oficial que registra toda a sua trajetória acadêmica referente ao seu rendimento
        escolar.
      </ModalText>
      <ModalText>
        Normalmente, a solicitação desse documento é feita no departamento de coordenação do curso ou pelo portal digital da sua
        universidade.
      </ModalText>
    </Modal>
  )
}
