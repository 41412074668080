import React, { useEffect, useState, useContext } from 'react'
import { Sidetab, Popover } from '@typeform/embed-react'

import { TemplateContext } from '../template/context'

const TypeFormIntegration = ({ id }) => {
  const { isMobile } = useContext(TemplateContext)
  const [show, setShow] = useState(true)

  useEffect(() => {
    const hasAlreadySubmitted = localStorage.getItem(`submittedTypeform#${id}`) === 'true'
    if (hasAlreadySubmitted) setShow(false)
  }, [id])

  return (
    show &&
    (isMobile ? (
      <Popover
        id={id}
        open={'load'}
        onSubmit={() => localStorage.setItem(`submittedTypeform#${id}`, 'true')}
        buttonColor="#0445AF"
      />
    ) : (
      <Sidetab
        id={id}
        open={'load'}
        buttonText="Feedback"
        buttonColor="#0445AF"
        onSubmit={() => localStorage.setItem(`submittedTypeform#${id}`, 'true')}
      />
    ))
  )
}

export default TypeFormIntegration
