import styled from 'styled-components'
import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const ButtonBack = styled.a`
  cursor: pointer;
`

export const PageTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  margin: 25px 0 5px 0;
`

export const ContainerInputs = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`

export const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: ${theme.colors.black};
`
