import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import Button from '../../components/Button'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { history } from '../../navigation/history'
import { TemplateContext } from '../../components/template/context'
import { Container, ContainerInputs, Subtitle } from '../email/styles'
import { Title } from '../../components/Texts'
import { Card } from './Card'
import UploadComponent from '../../components/Upload'
import { UploadCloud } from '../../assets/svg/UploadCloud'
import { UploadVideo } from '../../assets/svg/UploadVideo'
import { ModalVideo } from '../../components/ModalVideo'
import { ModalCamera } from '../../components/ModalCamera'
import { useUpload } from './hooks'
import { useUpload as useCameraUpload } from '../../components/webcam/hooks'
import { useUpload as UseOnUpload } from '../../utils/useUpload'
import { ImageUpload } from '../../assets/svg/ImageUpload'

export const Upload = () => {
  const { checkToken, setIsLoading } = useContext(TemplateContext)
  const [
    showModal,
    setShowModal,
    type,
    texts,
    filesUploaded,
    setFilesNotUpload,
    setType,
    files,
    setFiles,
    closeModal,
    typeUpload,
    video,
    setVideo,
    isButtonModalDisabled,
    setIsButtonModalDisabled,
    openModal,
    onClickNextScreen,
  ] = useUpload(checkToken, setIsLoading)
  const [handleImage, image, webcamRef, videoConstraints, , , , preview] = useCameraUpload(
    setFilesNotUpload,
    setType,
    closeModal,
  )
  const [onUpload, previewUpload] = UseOnUpload(setFiles, setType)

  return (
    <Template haveHeader>
      <Container>
        <SanarProgressBar onClickBack={() => history.push('/avalista/foto-ou-video')} title="Foto ou vídeo" percent={93.33} />
        <ContainerInputs>
          <Title>{texts?.title}</Title>
          <Subtitle>{texts?.subtitle}</Subtitle>
          <S.RowWrapper>
            <UploadComponent
              onUpload={onUpload}
              file={typeUpload !== 'camera' && (previewUpload || files[0])}
              filesUploaded={typeUpload !== 'camera' && filesUploaded[filesUploaded.length - 1]}
              title="Fazer upload"
              icon={<UploadCloud />}
              isGuarantor
              isVideo={type === 'video'}
              disabled={typeUpload === 'camera'}
              onClickFooter={() => {
                setType('')
                setFiles([])
              }}
            />
            <Card
              onClick={
                typeUpload !== 'upload'
                  ? openModal
                  : () => {
                      // is intentional
                    }
              }
              hiddenInMobile
              icon={type === 'video' ? <UploadVideo /> : <ImageUpload />}
              title={texts?.cardTitle}
              isVideo={type === 'video'}
              onClickFooter={() => {
                setVideo(false)
                setShowModal(true)
                setType('')
              }}
              disabled={typeUpload === 'upload'}
              preview={video || preview}
            />
            <ModalVideo
              show={showModal && type === 'video'}
              onDismiss={() => setShowModal(false)}
              handleVideo={() => {
                setShowModal(false)
              }}
              setVideo={setVideo}
              setType={setType}
              isButtonModalDisabled={isButtonModalDisabled}
              setIsButtonModalDisabled={setIsButtonModalDisabled}
            />
            <ModalCamera
              image={image}
              webcamRef={webcamRef}
              videoConstraints={videoConstraints}
              isButtonModalDisabled={isButtonModalDisabled}
              handleImage={handleImage}
              setIsWebcamModal={setShowModal}
              show={showModal && type === 'foto'}
            />
          </S.RowWrapper>
        </ContainerInputs>
        <Button
          text="Avançar"
          disabled={!image && !filesUploaded.length && !files.length && !video}
          footerButton
          onClick={onClickNextScreen}
        />
      </Container>
    </Template>
  )
}
