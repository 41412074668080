import React from 'react'

export const RGBackIcon = () => (
  <svg width="139" height="188" viewBox="0 0 139 188" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.999817 186.624L137.559 186.624L137.559 0.999969L0.999756 1.00001L0.999817 186.624Z"
      fill="#E2EFD8"
      stroke="#338C47"
      strokeMiterlimit="10"
    />
    <path
      d="M9.51177 177.028L129.918 177.028L129.918 10.5527L9.51172 10.5527L9.51177 177.028Z"
      fill="#E2EFD8"
      stroke="#338C47"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M107.601 101.894C107.557 101.981 107.557 102.068 107.514 102.068C107.557 102.025 107.601 101.981 107.601 101.894Z"
      fill="#EBEBEB"
    />
    <path
      d="M107.601 101.851C107.557 102.025 107.514 102.112 107.514 102.112C107.557 102.068 107.557 101.981 107.601 101.851C107.644 101.721 107.687 101.591 107.731 101.417C107.687 101.591 107.644 101.764 107.601 101.851Z"
      fill="#263238"
    />
    <path
      d="M14.8525 167.764C14.809 167.46 14.7656 167.026 14.7656 166.592C14.7656 165.94 14.8959 165.549 15.1564 165.246C15.3735 165.028 15.6775 164.898 16.0248 164.898C16.6327 164.898 17.0669 165.289 17.2406 165.767C17.3709 165.419 17.7182 165.202 18.1959 165.072C18.8472 164.898 19.2814 164.811 19.4985 164.724V165.332C19.3682 165.419 18.9775 165.506 18.3696 165.636C17.7182 165.767 17.5011 166.027 17.4577 166.592V167.156H19.4551V167.764H14.8525ZM16.9801 167.156V166.548C16.9801 165.897 16.6327 165.506 16.1117 165.506C15.5038 165.506 15.2433 165.94 15.2433 166.592C15.2433 166.896 15.2867 167.113 15.2867 167.199H16.9801V167.156Z"
      fill="#3A3A3A"
    />
    <path
      d="M17.8925 163.682C18.7175 163.682 19.0649 163.161 19.0649 162.51C19.0649 162.076 18.978 161.815 18.8912 161.598L19.3254 161.511C19.4122 161.728 19.5425 162.076 19.5425 162.64C19.5425 163.682 18.8478 164.29 17.8491 164.29C16.8504 164.29 16.0254 163.682 16.0254 162.727C16.0254 161.642 16.9807 161.338 17.5885 161.338C17.7188 161.338 17.8056 161.338 17.8925 161.338V163.682ZM17.4583 161.859C17.0675 161.859 16.4596 162.032 16.4596 162.727C16.4596 163.335 17.0241 163.596 17.4583 163.682V161.859Z"
      fill="#3A3A3A"
    />
    <path
      d="M16.1127 157.647C16.3732 157.647 16.6337 157.69 17.0245 157.69H18.9785C19.76 157.69 20.2377 157.864 20.4982 158.168C20.8021 158.515 20.889 158.949 20.889 159.384C20.889 159.774 20.8021 160.252 20.6285 160.513L20.1508 160.339C20.2811 160.122 20.4114 159.774 20.4114 159.34C20.4114 158.732 20.064 158.255 19.239 158.255H18.8482C19.1521 158.428 19.4127 158.819 19.4127 159.34C19.4127 160.165 18.7179 160.773 17.7627 160.773C16.6337 160.773 15.9824 160.035 15.9824 159.253C15.9824 158.689 16.2864 158.342 16.5469 158.211L16.0258 158.168V157.647H16.1127ZM17.4153 158.255C17.3285 158.255 17.2416 158.255 17.1548 158.298C16.8074 158.428 16.5035 158.689 16.5035 159.167C16.5035 159.731 16.9811 160.165 17.7627 160.165C18.414 160.165 18.9785 159.818 18.9785 159.167C18.9785 158.776 18.7614 158.472 18.3706 158.298C18.2837 158.255 18.1535 158.255 18.0232 158.255H17.4153Z"
      fill="#3A3A3A"
    />
    <path
      d="M15.1564 155.997C15.3735 155.997 15.5472 156.127 15.5472 156.388C15.5472 156.605 15.3735 156.735 15.1564 156.735C14.9393 156.735 14.7656 156.561 14.7656 156.344C14.7656 156.171 14.9393 155.997 15.1564 155.997ZM19.4551 156.692H16.1117V156.084H19.4551V156.692Z"
      fill="#3A3A3A"
    />
    <path
      d="M18.8477 155.128C18.978 154.955 19.1083 154.607 19.1083 154.303C19.1083 153.869 18.8912 153.652 18.5872 153.652C18.2833 153.652 18.153 153.826 17.9793 154.26C17.7622 154.868 17.4149 155.128 17.0241 155.128C16.503 155.128 16.0254 154.694 16.0254 153.956C16.0254 153.609 16.1122 153.305 16.2425 153.131L16.6767 153.305C16.5899 153.435 16.503 153.695 16.503 153.999C16.503 154.347 16.7201 154.564 16.9807 154.564C17.2412 154.564 17.3714 154.347 17.5451 153.913C17.7622 153.348 18.0662 153.044 18.5438 153.044C19.1517 153.044 19.5425 153.522 19.5425 154.303C19.5425 154.651 19.4556 154.998 19.3254 155.259L18.8477 155.128Z"
      fill="#3A3A3A"
    />
    <path
      d="M15.1562 151.568H16.1115V150.699H16.5891V151.568H18.4128C18.847 151.568 19.0641 151.438 19.0641 151.09C19.0641 150.917 19.0641 150.83 19.0207 150.743L19.4983 150.699C19.5418 150.83 19.5852 151.003 19.5852 151.221C19.5852 151.524 19.4983 151.742 19.3247 151.872C19.151 152.046 18.847 152.089 18.4562 152.089H16.6326V152.61H16.1549V152.089H15.3299L15.1562 151.568Z"
      fill="#3A3A3A"
    />
    <path
      d="M17.1543 150.005C16.7635 150.005 16.4162 150.005 16.1122 150.048V149.527H16.7635V149.484C16.3293 149.31 16.0254 148.963 16.0254 148.572C16.0254 148.485 16.0254 148.442 16.0254 148.398H16.5899C16.5899 148.442 16.5899 148.528 16.5899 148.615C16.5899 149.05 16.9372 149.353 17.3714 149.44C17.4583 149.44 17.5451 149.484 17.6754 149.484H19.4556V150.092H17.1543V150.005Z"
      fill="#3A3A3A"
    />
    <path
      d="M17.7622 144.751C19.0214 144.751 19.5425 145.619 19.5425 146.444C19.5425 147.356 18.8912 148.051 17.8056 148.051C16.6767 148.051 16.0254 147.313 16.0254 146.401C16.0254 145.402 16.7201 144.751 17.7622 144.751ZM17.8056 147.443C18.5438 147.443 19.1083 147.009 19.1083 146.401C19.1083 145.836 18.5438 145.359 17.8056 145.359C17.2412 145.359 16.503 145.663 16.503 146.357C16.503 147.139 17.1543 147.443 17.8056 147.443Z"
      fill="#3A3A3A"
    />
    <path opacity="0.25" d="M22.458 168.381L28.3633 168.381L28.3633 95.8679L22.458 95.8679L22.458 168.381Z" fill="#338C47" />
    <path
      d="M14.5947 90.6919C14.5512 90.3445 14.5078 89.8669 14.5078 89.3892C14.5078 88.5208 14.7249 87.9129 15.0723 87.4787C15.4631 87.0445 15.9841 86.8274 16.7657 86.8274C17.5473 86.8274 18.1552 87.0445 18.5894 87.5221C19.0236 87.9563 19.2841 88.6945 19.2841 89.6063C19.2841 90.0405 19.2841 90.3879 19.2407 90.6919H14.5947ZM18.7631 90.084C18.8065 89.9103 18.8065 89.6932 18.8065 89.4761C18.8065 88.1734 18.0683 87.4787 16.8091 87.4787C15.7236 87.4787 14.9854 88.0866 14.9854 89.3892C14.9854 89.6932 15.0289 89.9537 15.0289 90.084H18.7631Z"
      fill="#3A3A3A"
    />
    <path
      d="M19.2408 84.2222L18.8066 84.2656C19.0671 84.4393 19.3276 84.8301 19.3276 85.3077C19.3276 86.0024 18.85 86.3498 18.3724 86.3498C17.5474 86.3498 17.1132 85.6116 17.1132 84.309H17.0263C16.7658 84.309 16.2448 84.3958 16.2448 85.0906C16.2448 85.3945 16.3316 85.7419 16.5053 85.959L16.1145 86.0893C15.9408 85.8287 15.8105 85.3945 15.8105 85.0037C15.8105 83.9616 16.5053 83.7445 17.2 83.7445H18.4592C18.7632 83.7445 19.0237 83.7445 19.2842 83.7011V84.2222H19.2408ZM17.504 84.309C17.504 84.9603 17.5908 85.7419 18.2421 85.7419C18.6329 85.7419 18.8066 85.4814 18.8066 85.1774C18.8066 84.7432 18.5461 84.4827 18.2421 84.3524C18.1987 84.3524 18.1119 84.309 18.0684 84.309H17.504Z"
      fill="#3A3A3A"
    />
    <path
      d="M14.8984 82.0511H15.8537V81.1827H16.3313V82.0511H18.155C18.5892 82.0511 18.8063 81.9208 18.8063 81.5735C18.8063 81.3998 18.8063 81.3129 18.7629 81.2261L19.2405 81.1827C19.284 81.3129 19.3274 81.4866 19.3274 81.7037C19.3274 82.0077 19.2405 82.2248 19.0668 82.355C18.8932 82.5287 18.5892 82.5721 18.1984 82.5721H16.3747V83.0932H15.8971V82.5721H15.0721L14.8984 82.0511Z"
      fill="#3A3A3A"
    />
    <path
      d="M19.2408 78.6208L18.8066 78.6643C19.0671 78.8379 19.3276 79.2287 19.3276 79.7064C19.3276 80.4011 18.85 80.7485 18.3724 80.7485C17.5474 80.7485 17.1132 80.0103 17.1132 78.7077H17.0263C16.7658 78.7077 16.2448 78.7945 16.2448 79.4893C16.2448 79.7932 16.3316 80.1406 16.5053 80.3577L16.1145 80.4879C15.9408 80.2274 15.8105 79.7932 15.8105 79.4024C15.8105 78.3603 16.5053 78.1432 17.2 78.1432H18.4592C18.7632 78.1432 19.0237 78.1432 19.2842 78.0998V78.6208H19.2408ZM17.504 78.7077C17.504 79.359 17.5908 80.1406 18.2421 80.1406C18.6329 80.1406 18.8066 79.88 18.8066 79.5761C18.8066 79.1419 18.5461 78.8814 18.2421 78.7511C18.1987 78.7511 18.1119 78.7077 18.0684 78.7077H17.504Z"
      fill="#3A3A3A"
    />
    <path
      d="M14.291 72.7591H18.3726C18.6765 72.7591 19.0239 72.7591 19.241 72.7156V73.2801L18.6765 73.3235C19.0673 73.4972 19.3278 73.9314 19.3278 74.4525C19.3278 75.2775 18.6331 75.8854 17.6344 75.8854C16.5055 75.8854 15.8107 75.1906 15.8107 74.3656C15.8107 73.8446 16.0713 73.4972 16.3318 73.367H14.3344V72.7591H14.291ZM17.2436 73.367C17.1568 73.367 17.07 73.367 16.9831 73.4104C16.5923 73.4972 16.2884 73.8446 16.2884 74.2788C16.2884 74.9301 16.8528 75.2775 17.591 75.2775C18.2857 75.2775 18.8502 74.9301 18.8502 74.2788C18.8502 73.888 18.5897 73.4972 18.1121 73.367C18.0252 73.367 17.9384 73.3235 17.8515 73.3235H17.2436V73.367Z"
      fill="#3A3A3A"
    />
    <path
      d="M17.6776 71.413C18.5026 71.413 18.85 70.8919 18.85 70.2406C18.85 69.8064 18.7632 69.5459 18.6763 69.3288L19.1105 69.2419C19.1974 69.459 19.3276 69.8064 19.3276 70.3709C19.3276 71.413 18.6329 72.0209 17.6342 72.0209C16.6355 72.0209 15.8105 71.413 15.8105 70.4577C15.8105 69.3722 16.7658 69.0682 17.3737 69.0682C17.504 69.0682 17.5908 69.0682 17.6776 69.0682V71.413ZM17.2434 69.5893C16.8527 69.5893 16.2448 69.763 16.2448 70.4577C16.2448 71.0656 16.8092 71.3261 17.2434 71.413V69.5893Z"
      fill="#3A3A3A"
    />
    <path
      d="M17.6776 66.463C18.5026 66.463 18.85 65.9419 18.85 65.2906C18.85 64.8564 18.7632 64.5959 18.6763 64.3788L19.1105 64.2919C19.1974 64.509 19.3276 64.8564 19.3276 65.4209C19.3276 66.463 18.6329 67.0708 17.6342 67.0708C16.6355 67.0708 15.8105 66.463 15.8105 65.5077C15.8105 64.4222 16.7658 64.1182 17.3737 64.1182C17.504 64.1182 17.5908 64.1182 17.6776 64.1182V66.463ZM17.2434 64.6393C16.8527 64.6393 16.2448 64.813 16.2448 65.5077C16.2448 66.1156 16.8092 66.3761 17.2434 66.463V64.6393Z"
      fill="#3A3A3A"
    />
    <path
      d="M15.8535 63.0327L16.5917 62.5551C16.7654 62.4248 16.939 62.338 17.1561 62.2077C16.939 62.0775 16.7654 61.9906 16.5917 61.8604L15.8969 61.3827V60.7314L17.5035 61.8604L19.2403 60.688V61.3827L18.5022 61.8604C18.3285 61.9906 18.1114 62.1209 17.9377 62.2077C18.1548 62.338 18.3285 62.4248 18.5022 62.5551L19.2403 63.0327V63.7275L17.5035 62.5117L15.8535 63.6406V63.0327Z"
      fill="#3A3A3A"
    />
    <path
      d="M16.9829 60.1235C16.5487 60.1235 16.2013 60.1235 15.8974 60.167V59.6025L16.4619 59.5591C16.0711 59.2985 15.8105 58.9078 15.8105 58.3433C15.8105 57.5183 16.5053 56.9104 17.504 56.9104C18.7197 56.9104 19.3276 57.6486 19.3276 58.4301C19.3276 58.8643 19.154 59.2551 18.8066 59.4722H20.6303V60.0801H16.9829V60.1235ZM17.8513 59.5157C17.9382 59.5157 18.025 59.5157 18.1119 59.4722C18.5461 59.342 18.8066 58.9946 18.8066 58.5604C18.8066 57.9091 18.2855 57.5183 17.504 57.5183C16.8092 57.5183 16.2448 57.8657 16.2448 58.517C16.2448 58.9512 16.5487 59.342 16.9829 59.4288C17.0698 59.4288 17.1566 59.4722 17.2434 59.4722H17.8513V59.5157Z"
      fill="#3A3A3A"
    />
    <path opacity="0.25" d="M22.2432 91.308L28.1484 91.308L28.1484 18.7951L22.2432 18.7951L22.2432 91.308Z" fill="#338C47" />
    <path opacity="0.25" d="M46.6221 168.902L52.5273 168.902L52.5273 40.7237L46.6221 40.7237L46.6221 168.902Z" fill="#338C47" />
    <path
      d="M42.4277 168.372H37.7383V167.721L40.1264 166.201C40.6909 165.853 41.1685 165.593 41.6462 165.376C41.0383 165.419 40.4304 165.463 39.7356 165.463H37.7817V164.898H42.4712V165.506L40.083 166.982C39.562 167.33 39.0409 167.634 38.5199 167.851C39.1278 167.807 39.6922 167.807 40.4738 167.807H42.4712V168.372H42.4277Z"
      fill="#3A3A3A"
    />
    <path
      d="M40.6919 160.773C41.9511 160.773 42.4722 161.642 42.4722 162.467C42.4722 163.378 41.8209 164.073 40.7353 164.073C39.6064 164.073 38.9551 163.335 38.9551 162.423C38.9551 161.425 39.6932 160.773 40.6919 160.773ZM40.7353 163.422C41.4735 163.422 42.038 162.988 42.038 162.38C42.038 161.815 41.4735 161.338 40.7353 161.338C40.1709 161.338 39.4327 161.642 39.4327 162.336C39.4327 163.118 40.084 163.422 40.7353 163.422Z"
      fill="#3A3A3A"
    />
    <path
      d="M39.9538 159.992C39.6064 159.992 39.3024 159.992 39.0419 160.035V159.514L39.563 159.471C39.259 159.297 38.9551 158.949 38.9551 158.428C38.9551 157.951 39.2156 157.603 39.6498 157.473C39.4761 157.386 39.3024 157.256 39.2156 157.082C39.0419 156.865 38.9985 156.648 38.9985 156.344C38.9985 155.91 39.3024 155.259 40.4748 155.259H42.4722V155.867H40.5617C39.9103 155.867 39.5195 156.084 39.5195 156.605C39.5195 156.952 39.7801 157.213 40.084 157.343C40.1709 157.386 40.2577 157.386 40.388 157.386H42.4722V157.994H40.4748C39.9538 157.994 39.563 158.211 39.563 158.689C39.563 159.08 39.8669 159.34 40.1709 159.427C40.2577 159.47 40.3445 159.471 40.4748 159.471H42.5156V160.078H39.9538V159.992Z"
      fill="#3A3A3A"
    />
    <path
      d="M40.8632 153.869C41.6882 153.869 42.0356 153.348 42.0356 152.697C42.0356 152.263 41.9487 152.002 41.8619 151.785L42.2961 151.698C42.3829 151.915 42.5132 152.263 42.5132 152.827C42.5132 153.869 41.8185 154.477 40.8198 154.477C39.8211 154.477 38.9961 153.869 38.9961 152.914C38.9961 151.828 39.9514 151.525 40.5592 151.525C40.6895 151.525 40.7764 151.525 40.8632 151.525V153.869ZM40.3856 152.089C39.9948 152.089 39.3869 152.263 39.3869 152.957C39.3869 153.565 39.9514 153.826 40.3856 153.913V152.089Z"
      fill="#3A3A3A"
    />
    <g opacity="0.25">
      <path opacity="0.25" d="M94.003 168.381L99.9082 168.381L99.9082 95.8678L94.0029 95.8678L94.003 168.381Z" fill="#338C47" />
    </g>
    <path
      d="M89.7559 167.851H85.0664V167.2L87.4546 165.68C88.019 165.332 88.4967 165.072 88.9743 164.855C88.3664 164.898 87.7585 164.942 87.0638 164.942H85.1098V164.377H89.7993V164.985L87.4111 166.461C86.8901 166.809 86.369 167.113 85.848 167.33C86.4559 167.286 87.0203 167.286 87.8019 167.286H89.7993V167.851H89.7559Z"
      fill="#3A3A3A"
    />
    <path
      d="M89.7564 161.424L89.3222 161.468C89.5827 161.642 89.8433 162.032 89.8433 162.51C89.8433 163.205 89.3656 163.552 88.888 163.552C88.063 163.552 87.6288 162.814 87.6288 161.511H87.542C87.2814 161.511 86.7604 161.598 86.7604 162.293C86.7604 162.597 86.8472 162.944 87.0209 163.161L86.6301 163.292C86.4564 163.031 86.3262 162.597 86.3262 162.206C86.3262 161.164 87.0209 160.947 87.7156 160.947H88.9749C89.2788 160.947 89.5393 160.947 89.7998 160.903V161.424H89.7564ZM88.063 161.511C88.063 162.163 88.1498 162.944 88.8012 162.944C89.192 162.944 89.3656 162.684 89.3656 162.38C89.3656 161.946 89.1051 161.685 88.8012 161.555C88.7577 161.555 88.6709 161.511 88.6275 161.511H88.063Z"
      fill="#3A3A3A"
    />
    <path
      d="M85.457 159.21H86.4123V158.342H86.8899V159.21H88.7136C89.1478 159.21 89.3649 159.08 89.3649 158.732C89.3649 158.559 89.3649 158.472 89.3215 158.385L89.7991 158.342C89.8425 158.472 89.886 158.646 89.886 158.863C89.886 159.167 89.7991 159.384 89.6254 159.514C89.4518 159.688 89.1478 159.731 88.757 159.731H86.9333V160.252H86.4557V159.731H85.6307L85.457 159.21Z"
      fill="#3A3A3A"
    />
    <path
      d="M88.8441 154.868C89.1915 154.868 89.4954 154.868 89.756 154.824V155.346L89.1915 155.389C89.452 155.563 89.7994 155.91 89.7994 156.518C89.7994 157.039 89.4954 157.69 88.3231 157.69H86.3691V157.082H88.2362C88.8875 157.082 89.3218 156.909 89.3218 156.344C89.3218 155.953 89.0178 155.649 88.7573 155.519C88.6704 155.476 88.5402 155.476 88.4533 155.476H86.3691V154.868H88.8441Z"
      fill="#3A3A3A"
    />
    <path
      d="M87.4551 153.826C87.0643 153.826 86.717 153.826 86.413 153.869V153.348H87.0643V153.305C86.6301 153.131 86.3262 152.784 86.3262 152.393C86.3262 152.306 86.3262 152.263 86.3262 152.219H86.8906C86.8906 152.263 86.8906 152.35 86.8906 152.436C86.8906 152.871 87.238 153.175 87.6722 153.261C87.7591 153.261 87.8459 153.305 87.9762 153.305H89.7564V153.913H87.4551V153.826Z"
      fill="#3A3A3A"
    />
    <path
      d="M89.7564 149.744L89.3222 149.788C89.5827 149.961 89.8433 150.352 89.8433 150.83C89.8433 151.525 89.3656 151.872 88.888 151.872C88.063 151.872 87.6288 151.134 87.6288 149.831H87.542C87.2814 149.831 86.7604 149.918 86.7604 150.613C86.7604 150.917 86.8472 151.264 87.0209 151.481L86.6301 151.611C86.4564 151.351 86.3262 150.917 86.3262 150.526C86.3262 149.484 87.0209 149.267 87.7156 149.267H88.9749C89.2788 149.267 89.5393 149.267 89.7998 149.223V149.744H89.7564ZM88.063 149.831C88.063 150.482 88.1498 151.264 88.8012 151.264C89.192 151.264 89.3656 151.003 89.3656 150.7C89.3656 150.265 89.1051 150.005 88.8012 149.875C88.7577 149.875 88.6709 149.831 88.6275 149.831H88.063Z"
      fill="#3A3A3A"
    />
    <path d="M84.8496 148.268V147.66H89.7996V148.268H84.8496Z" fill="#3A3A3A" />
    <path
      d="M85.4572 145.923C85.6743 145.923 85.848 146.053 85.848 146.314C85.848 146.531 85.6743 146.661 85.4572 146.661C85.2401 146.661 85.0664 146.488 85.0664 146.271C85.0664 146.053 85.2401 145.923 85.4572 145.923ZM89.7559 146.618H86.4125V146.01H89.7559V146.618Z"
      fill="#3A3A3A"
    />
    <path
      d="M84.8496 142.059H88.9312C89.2351 142.059 89.5825 142.059 89.7996 142.015V142.58L89.2351 142.623C89.6259 142.797 89.8864 143.231 89.8864 143.752C89.8864 144.577 89.1917 145.185 88.193 145.185C87.0641 145.185 86.3693 144.49 86.3693 143.665C86.3693 143.144 86.6299 142.797 86.8904 142.667H84.893V142.059H84.8496ZM87.7588 142.71C87.672 142.71 87.5851 142.71 87.4983 142.754C87.1075 142.84 86.8036 143.188 86.8036 143.622C86.8036 144.273 87.368 144.621 88.1062 144.621C88.8009 144.621 89.3654 144.273 89.3654 143.622C89.3654 143.231 89.1049 142.84 88.6272 142.71C88.5404 142.71 88.4535 142.667 88.3667 142.667H87.7588V142.71Z"
      fill="#3A3A3A"
    />
    <path
      d="M89.7564 139.193L89.3222 139.236C89.5827 139.41 89.8433 139.801 89.8433 140.279C89.8433 140.973 89.3656 141.321 88.888 141.321C88.063 141.321 87.6288 140.582 87.6288 139.28H87.542C87.2814 139.28 86.7604 139.367 86.7604 140.061C86.7604 140.365 86.8472 140.713 87.0209 140.93L86.6301 141.06C86.4564 140.8 86.3262 140.365 86.3262 139.975C86.3262 138.932 87.0209 138.715 87.7156 138.715H88.9749C89.2788 138.715 89.5393 138.715 89.7998 138.672V139.193H89.7564ZM88.063 139.28C88.063 139.931 88.1498 140.713 88.8012 140.713C89.192 140.713 89.3656 140.452 89.3656 140.148C89.3656 139.714 89.1051 139.454 88.8012 139.323C88.7577 139.323 88.6709 139.28 88.6275 139.28H88.063Z"
      fill="#3A3A3A"
    />
    <path
      d="M84.8496 134.807H88.9312C89.2351 134.807 89.5825 134.807 89.7996 134.764V135.329L89.2351 135.372C89.6259 135.546 89.8864 135.98 89.8864 136.501C89.8864 137.326 89.1917 137.934 88.193 137.934C87.0641 137.934 86.3693 137.239 86.3693 136.414C86.3693 135.893 86.6299 135.546 86.8904 135.415H84.893V134.807H84.8496ZM87.7588 135.415C87.672 135.415 87.5851 135.415 87.4983 135.459C87.1075 135.546 86.8036 135.893 86.8036 136.327C86.8036 136.979 87.368 137.326 88.1062 137.326C88.8009 137.326 89.3654 136.979 89.3654 136.327C89.3654 135.936 89.1049 135.546 88.6272 135.415C88.5404 135.415 88.4535 135.372 88.3667 135.372H87.7588V135.415Z"
      fill="#3A3A3A"
    />
    <path
      d="M88.1933 133.461C89.0183 133.461 89.3656 132.94 89.3656 132.289C89.3656 131.855 89.2788 131.594 89.192 131.377L89.6262 131.29C89.713 131.507 89.8433 131.855 89.8433 132.419C89.8433 133.461 89.1485 134.069 88.1498 134.069C87.1512 134.069 86.3262 133.461 86.3262 132.506C86.3262 131.421 87.2814 131.117 87.8893 131.117C88.0196 131.117 88.1064 131.117 88.1933 131.117V133.461ZM87.7591 131.681C87.3683 131.681 86.7604 131.855 86.7604 132.55C86.7604 133.157 87.3249 133.418 87.7591 133.505V131.681Z"
      fill="#3A3A3A"
    />
    <g opacity="0.25">
      <path opacity="0.25" d="M94.001 91.2522L99.9062 91.2522L99.9062 16.6985L94.001 16.6985L94.001 91.2522Z" fill="#338C47" />
    </g>
    <path
      d="M89.7559 90.6919H85.0664V90.0406L87.4546 88.5209C88.019 88.1735 88.4967 87.913 88.9743 87.6959C88.3664 87.7393 87.7585 87.7827 87.0638 87.7827H85.1098V87.1748H89.7993V87.7827L87.4111 89.259C86.8901 89.6064 86.369 89.9103 85.848 90.1274C86.4559 90.084 87.0203 90.084 87.8019 90.084H89.7993V90.6919H89.7559Z"
      fill="#3A3A3A"
    />
    <path
      d="M89.7564 84.309L89.3222 84.3524C89.5827 84.5261 89.8433 84.9169 89.8433 85.3945C89.8433 86.0893 89.3656 86.4366 88.888 86.4366C88.063 86.4366 87.6288 85.6985 87.6288 84.3959H87.542C87.2814 84.3959 86.7604 84.4827 86.7604 85.1774C86.7604 85.4814 86.8472 85.8287 87.0209 86.0459L86.6301 86.1761C86.4564 85.9156 86.3262 85.4814 86.3262 85.0906C86.3262 84.0485 87.0209 83.8314 87.7156 83.8314H88.9749C89.2788 83.8314 89.5393 83.8314 89.7998 83.788V84.309H89.7564ZM88.063 84.3959C88.063 85.0472 88.1498 85.8287 88.8012 85.8287C89.192 85.8287 89.3656 85.5682 89.3656 85.2643C89.3656 84.8301 89.1051 84.5695 88.8012 84.4393C88.7577 84.4393 88.6709 84.3959 88.6275 84.3959H88.063Z"
      fill="#3A3A3A"
    />
    <path
      d="M89.1485 82.8761C89.2788 82.7025 89.4091 82.3551 89.4091 82.0511C89.4091 81.6169 89.1919 81.3998 88.888 81.3998C88.5841 81.3998 88.4538 81.5735 88.2801 82.0077C88.063 82.6156 87.7156 82.8761 87.3249 82.8761C86.8038 82.8761 86.3262 82.4419 86.3262 81.7038C86.3262 81.3564 86.413 81.0525 86.5433 80.8788L86.9775 81.0525C86.8906 81.1827 86.8038 81.4432 86.8038 81.7472C86.8038 82.0946 87.0209 82.3117 87.2814 82.3117C87.542 82.3117 87.6722 82.0946 87.8459 81.6603C88.063 81.0959 88.367 80.7919 88.8446 80.7919C89.4525 80.7919 89.8433 81.2696 89.8433 82.0511C89.8433 82.3985 89.7564 82.7459 89.6262 83.0064L89.1485 82.8761Z"
      fill="#3A3A3A"
    />
    <path
      d="M89.6266 77.6656C89.7135 77.8393 89.8003 78.1867 89.8003 78.6209C89.8003 79.6196 89.1056 80.3143 88.0635 80.3143C87.0214 80.3143 86.2832 79.6196 86.2832 78.4906C86.2832 78.1432 86.37 77.7959 86.4569 77.6222L86.9345 77.7525C86.8477 77.8827 86.7608 78.1432 86.7608 78.4472C86.7608 79.2288 87.3253 79.6196 88.02 79.6196C88.8016 79.6196 89.2793 79.0985 89.2793 78.4472C89.2793 78.0998 89.1924 77.8827 89.1056 77.709L89.6266 77.6656Z"
      fill="#3A3A3A"
    />
    <path
      d="M85.4572 76.2761C85.6743 76.2761 85.848 76.4064 85.848 76.6669C85.848 76.884 85.6743 77.0143 85.4572 77.0143C85.2401 77.0143 85.0664 76.8406 85.0664 76.6235C85.0664 76.4064 85.2401 76.2761 85.4572 76.2761ZM89.7559 76.9274H86.4125V76.3195H89.7559V76.9274Z"
      fill="#3A3A3A"
    />
    <path
      d="M87.3249 75.3209C86.9775 75.3209 86.6735 75.3209 86.413 75.3643V74.8433L86.9341 74.7998C86.6301 74.6262 86.3262 74.2788 86.3262 73.7577C86.3262 73.2801 86.5867 72.9327 87.0209 72.8025C86.8472 72.7156 86.6735 72.5854 86.5867 72.4117C86.413 72.1946 86.3696 71.9775 86.3696 71.6735C86.3696 71.2393 86.6735 70.588 87.8459 70.588H89.8433V71.1959H87.9327C87.2814 71.1959 86.8906 71.413 86.8906 71.9341C86.8906 72.2814 87.1512 72.542 87.4551 72.6722C87.542 72.7156 87.6288 72.7156 87.7591 72.7156H89.8433V73.3235H87.8459C87.3249 73.3235 86.9341 73.5406 86.9341 74.0183C86.9341 74.4091 87.238 74.6696 87.542 74.7564C87.6288 74.7998 87.7156 74.7998 87.8459 74.7998H89.8867V75.4077H87.3249V75.3209Z"
      fill="#3A3A3A"
    />
    <path
      d="M88.1933 69.1986C89.0183 69.1986 89.3656 68.6775 89.3656 68.0262C89.3656 67.592 89.2788 67.3315 89.192 67.1143L89.6262 67.0275C89.713 67.2446 89.8433 67.592 89.8433 68.1564C89.8433 69.1986 89.1485 69.8064 88.1498 69.8064C87.1512 69.8064 86.3262 69.1985 86.3262 68.2433C86.3262 67.1578 87.2814 66.8538 87.8893 66.8538C88.0196 66.8538 88.1064 66.8538 88.1933 66.8538V69.1986ZM87.7591 67.3749C87.3683 67.3749 86.7604 67.5486 86.7604 68.2433C86.7604 68.8512 87.3249 69.1117 87.7591 69.1986V67.3749Z"
      fill="#3A3A3A"
    />
    <path
      d="M87.3249 66.0288C86.9775 66.0288 86.6735 66.0288 86.413 66.0722V65.5511L86.9775 65.5077C86.6735 65.334 86.3262 64.9433 86.3262 64.3788C86.3262 63.9012 86.5867 63.2064 87.7591 63.2064H89.7564V63.8143H87.8025C87.2814 63.8143 86.8038 64.0314 86.8038 64.5959C86.8038 64.9867 87.1077 65.2906 87.4117 65.4209C87.4985 65.4643 87.5854 65.4643 87.7156 65.4643H89.7564V66.0722H87.3249V66.0288Z"
      fill="#3A3A3A"
    />
    <path
      d="M85.457 61.4696H86.4123V60.6011H86.8899V61.4696H88.7136C89.1478 61.4696 89.3649 61.3393 89.3649 60.9919C89.3649 60.8182 89.3649 60.7314 89.3215 60.6446L89.7991 60.6011C89.8425 60.7314 89.886 60.9051 89.886 61.1222C89.886 61.4261 89.7991 61.6432 89.6254 61.7735C89.4518 61.9472 89.1478 61.9906 88.757 61.9906H86.9333V62.5117H86.4557V61.9906H85.6307L85.457 61.4696Z"
      fill="#3A3A3A"
    />
    <path
      d="M88.063 56.9104C89.3222 56.9104 89.8433 57.7788 89.8433 58.6038C89.8433 59.5157 89.192 60.2104 88.1064 60.2104C86.9775 60.2104 86.3262 59.4722 86.3262 58.5604C86.3262 57.5617 87.0209 56.9104 88.063 56.9104ZM88.1064 59.6025C88.8446 59.6025 89.4091 59.1683 89.4091 58.5604C89.4091 57.9959 88.8446 57.5183 88.1064 57.5183C87.542 57.5183 86.8038 57.8222 86.8038 58.517C86.8038 59.2551 87.4551 59.6025 88.1064 59.6025Z"
      fill="#3A3A3A"
    />
    <path opacity="0.25" d="M117.648 168.902L123.553 168.902L123.553 40.7236L117.647 40.7236L117.648 168.902Z" fill="#338C47" />
    <path
      d="M113.289 165.072C113.419 165.289 113.506 165.723 113.506 166.288C113.506 167.59 112.681 168.589 111.118 168.589C109.641 168.589 108.643 167.59 108.643 166.157C108.643 165.593 108.773 165.202 108.86 165.028L109.337 165.159C109.207 165.376 109.164 165.723 109.164 166.114C109.164 167.199 109.858 167.938 111.118 167.938C112.247 167.938 112.985 167.286 112.985 166.157C112.985 165.81 112.898 165.419 112.811 165.159L113.289 165.072Z"
      fill="#3A3A3A"
    />
    <path
      d="M108.815 164.334C108.772 164.03 108.729 163.639 108.729 163.161C108.729 162.553 108.859 162.119 109.119 161.859C109.336 161.598 109.684 161.468 110.118 161.468C110.552 161.468 110.856 161.598 111.117 161.815C111.464 162.119 111.638 162.64 111.638 163.248C111.638 163.422 111.638 163.596 111.594 163.726H113.461V164.334H108.815ZM111.073 163.726C111.117 163.596 111.117 163.422 111.117 163.205C111.117 162.467 110.769 162.032 110.118 162.032C109.51 162.032 109.206 162.467 109.206 163.118C109.206 163.378 109.206 163.596 109.25 163.682H111.073V163.726Z"
      fill="#3A3A3A"
    />
    <path
      d="M108.773 160.643V158.125H109.294V160.035H110.858V158.255H111.379V160.035H113.506V160.643H108.773Z"
      fill="#3A3A3A"
    />
    <path opacity="0.25" d="M68.7556 168.902L72.5332 168.902L72.5332 40.7236L68.7555 40.7236L68.7556 168.902Z" fill="#338C47" />
    <path opacity="0.25" d="M73.9978 168.902L77.7754 168.902L77.7753 40.7236L73.9977 40.7236L73.9978 168.902Z" fill="#338C47" />
    <path d="M59.8398 168.372V165.854H60.3609V167.764H61.924V165.984H62.4451V167.764H64.5727V168.372H59.8398Z" fill="#3A3A3A" />
    <path
      d="M60.2306 164.507C60.4477 164.507 60.6214 164.638 60.6214 164.898C60.6214 165.115 60.4477 165.246 60.2306 165.246C60.0135 165.246 59.8398 165.072 59.8398 164.855C59.8398 164.638 60.0135 164.507 60.2306 164.507ZM64.5293 165.159H61.1859V164.551H64.5293V165.159Z"
      fill="#3A3A3A"
    />
    <path d="M59.5781 163.552V162.944H64.5281V163.552H59.5781Z" fill="#3A3A3A" />
    <path
      d="M60.2306 161.251C60.4477 161.251 60.6214 161.381 60.6214 161.642C60.6214 161.859 60.4477 161.989 60.2306 161.989C60.0135 161.989 59.8398 161.815 59.8398 161.598C59.8398 161.381 60.0135 161.251 60.2306 161.251ZM64.5293 161.902H61.1859V161.294H64.5293V161.902Z"
      fill="#3A3A3A"
    />
    <path
      d="M64.5279 158.429L64.0937 158.472C64.3542 158.646 64.6148 159.036 64.6148 159.514C64.6148 160.209 64.1371 160.556 63.6595 160.556C62.8345 160.556 62.4003 159.818 62.4003 158.515H62.3134C62.0529 158.515 61.5319 158.602 61.5319 159.297C61.5319 159.601 61.6187 159.948 61.7924 160.165L61.4016 160.296C61.2279 160.035 61.0977 159.601 61.0977 159.21C61.0977 158.168 61.7924 157.951 62.4871 157.951H63.7463C64.0503 157.951 64.3108 157.951 64.5713 157.907V158.429H64.5279ZM62.7911 158.515C62.7911 159.167 62.8779 159.948 63.5292 159.948C63.92 159.948 64.0937 159.688 64.0937 159.384C64.0937 158.95 63.8332 158.689 63.5292 158.559C63.4858 158.559 63.399 158.515 63.3555 158.515H62.7911Z"
      fill="#3A3A3A"
    />
    <path
      d="M64.3981 154.521C64.4849 154.651 64.5718 154.998 64.5718 155.389L64.8323 155.563C64.8757 155.302 65.0494 155.085 65.3099 155.085C65.7007 155.085 65.8744 155.432 65.8744 155.823C65.8744 155.997 65.831 156.171 65.7441 156.301L65.4836 156.214C65.5705 156.084 65.5705 155.953 65.5705 155.823C65.5705 155.65 65.527 155.519 65.3534 155.519C65.1797 155.519 65.0928 155.736 65.0494 156.04L64.5284 155.736C64.3981 156.605 63.7468 157.126 62.8349 157.126C61.7928 157.126 61.0547 156.431 61.0547 155.302C61.0547 154.955 61.1415 154.607 61.2284 154.434L61.706 154.564C61.6192 154.694 61.5323 154.955 61.5323 155.259C61.5323 156.04 62.0968 156.431 62.7915 156.431C63.5731 156.431 64.0507 155.91 64.0507 155.259C64.0507 154.911 63.9639 154.694 63.877 154.521H64.3981Z"
      fill="#3A3A3A"
    />
    <path
      d="M60.578 153.522C60.1438 153.522 59.8398 153.348 59.8398 153.044C59.8398 152.871 59.8833 152.784 60.0135 152.61C60.0569 152.48 60.1438 152.393 60.1438 152.306C60.1438 152.176 60.1004 152.132 59.8398 152.132V151.829C60.3175 151.829 60.5346 151.959 60.5346 152.306C60.5346 152.436 60.4912 152.567 60.4043 152.74C60.3175 152.871 60.2741 152.957 60.2741 153.044C60.2741 153.175 60.4043 153.218 60.578 153.218V153.522ZM64.5293 151.959L64.0951 152.002C64.3556 152.176 64.6161 152.567 64.6161 153.044C64.6161 153.739 64.1385 154.086 63.6609 154.086C62.8359 154.086 62.4017 153.348 62.4017 152.046H62.3148C62.0543 152.046 61.5333 152.132 61.5333 152.827C61.5333 153.131 61.6201 153.479 61.7938 153.696L61.403 153.826C61.2293 153.565 61.099 153.131 61.099 152.74C61.099 151.698 61.7938 151.481 62.4885 151.481H63.7477C64.0517 151.481 64.3122 151.481 64.5727 151.438V151.959H64.5293ZM62.7925 152.046C62.7925 152.697 62.8793 153.479 63.5306 153.479C63.9214 153.479 64.0951 153.218 64.0951 152.914C64.0951 152.48 63.8346 152.219 63.5306 152.089C63.4872 152.089 63.4004 152.046 63.3569 152.046H62.7925Z"
      fill="#3A3A3A"
    />
    <path
      d="M62.8345 147.443C64.0937 147.443 64.6148 148.311 64.6148 149.136C64.6148 150.048 63.9634 150.743 62.8779 150.743C61.749 150.743 61.0977 150.005 61.0977 149.093C61.0977 148.094 61.7924 147.443 62.8345 147.443ZM62.8345 150.092C63.5726 150.092 64.1371 149.657 64.1371 149.05C64.1371 148.485 63.5726 148.007 62.8345 148.007C62.27 148.007 61.5319 148.311 61.5319 149.006C61.5319 149.788 62.2266 150.092 62.8345 150.092Z"
      fill="#3A3A3A"
    />
  </svg>
)
