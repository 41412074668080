export const brazilianStates = [
  {
    id: 1,
    acronym: 'AC',
    label: 'Acre',
  },
  {
    id: 2,
    acronym: 'AL',
    label: 'Alagoas',
  },
  {
    id: 3,
    acronym: 'AM',
    label: 'Amazonas',
  },
  {
    id: 4,
    acronym: 'AP',
    label: 'Amapá',
  },
  {
    id: 5,
    acronym: 'BA',
    label: 'Bahia',
  },
  {
    id: 6,
    acronym: 'CE',
    label: 'Ceará',
  },
  {
    id: 7,
    acronym: 'DF',
    label: 'Distrito Federal',
  },
  {
    id: 8,
    acronym: 'ES',
    label: 'Espírito Santo',
  },
  {
    id: 9,
    acronym: 'GO',
    label: 'Goiás',
  },
  {
    id: 10,
    acronym: 'MA',
    label: 'Maranhão',
  },
  {
    id: 11,
    acronym: 'MG',
    label: 'Minas Gerais',
  },
  {
    id: 12,
    acronym: 'MS',
    label: 'Mato Grosso do Sul',
  },
  {
    id: 13,
    acronym: 'MT',
    label: 'Mato Grosso',
  },
  {
    id: 14,
    acronym: 'PA',
    label: 'Pará',
  },
  {
    id: 15,
    acronym: 'PB',
    label: 'Paraíba',
  },
  {
    id: 16,
    acronym: 'PE',
    label: 'Pernambuco',
  },
  {
    id: 17,
    acronym: 'PI',
    label: 'Piauí',
  },
  {
    id: 18,
    acronym: 'PR',
    label: 'Paraná',
  },
  {
    id: 19,
    acronym: 'RJ',
    label: 'Rio de Janeiro',
  },
  {
    id: 20,
    acronym: 'RN',
    label: 'Rio Grande do Norte',
  },
  {
    id: 21,
    acronym: 'RO',
    label: 'Rondônia',
  },
  {
    id: 22,
    acronym: 'RR',
    label: 'Roraima',
  },
  {
    id: 23,
    acronym: 'RS',
    label: 'Rio Grande do Sul',
  },
  {
    id: 24,
    acronym: 'SC',
    label: 'Santa Catarina',
  },
  {
    id: 25,
    acronym: 'SE',
    label: 'Sergipe',
  },
  {
    id: 26,
    acronym: 'SP',
    label: 'São Paulo',
  },
  {
    id: 27,
    acronym: 'TO',
    label: 'Tocantins',
  },
]
