import styled from 'styled-components'
import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const ContainerInputs = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`

export const Wrapper = styled.div`
  padding-top: 30px;
`

export const CheckboxWrapper = styled.div`
  width: 70%;
  padding-top: 16px;

  @media (max-width: ${theme.breakpoints.md}px) {
    width: 100%;
  }
`
