import React, { forwardRef } from 'react'

import * as S from './styles'

export const Subtitle = forwardRef((props, ref) => {
  return (
    <S.Subtitle ref={ref} {...props}>
      {props.children}
    </S.Subtitle>
  )
})
