import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  margin-top: 30px;

  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 22px;
  }
`

export const Wrapper = styled.div`
  padding-top: 30px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 20px 0;

  > strong {
    font-size: 14px;
    margin-bottom: 15px;
  }

  > p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  @media (min-width: ${theme.breakpoints.sm}px) {
    > p {
      font-size: 16px;
    }

    > strong {
      font-size: 16px;
    }
  }
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  @media (min-width: ${theme.breakpoints.md}px) {
    max-width: 290px;
  }
`

export const InputWrapperLong = styled.div`
  width: 60%;
`

export const InputWrapperShort = styled.div`
  width: 30%;
`
