import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import { TemplateContext } from '../../components/template/context'
import { useProofOfEnrollment } from './hooks'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import Upload from '../../components/Upload'
import { ProofOfEnrollmentIcon } from '../../assets/svg/ProofOfEnrollmentIcon'
import { useUpload } from '../../utils/useUpload'
import { ModalComponent } from './Modal'
import { LinkBottom } from '../../components/LinkBottom'

export const ProofOfEnrollment = () => {
  const { creditPath, goToNextScreen, setIsLoading, isMobile } = useContext(TemplateContext)

  const [onClickNextScreen, files, setFiles, filesUploaded, modal, setModal] = useProofOfEnrollment(
    creditPath,
    goToNextScreen,
    setIsLoading,
  )
  const [onUpload, preview] = useUpload(setFiles)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar percent={creditPath?.progress?.percentageCompleted} title="COMPROVANTE DE MATRÍCULA" />
        <S.Title>Envie abaixo o seu comprovante de matrícula</S.Title>

        <LinkBottom
          isMobile={isMobile}
          onClickLink={() => setModal(true)}
          onClick={onClickNextScreen}
          disabled={!files?.length && !filesUploaded.length}
          textLink="O que são esses documentos?"
        >
          <Upload
            onUpload={onUpload}
            file={preview || files?.[0]}
            filesUploaded={filesUploaded[filesUploaded.length - 1]}
            title="Fazer upload"
            type="image/*, .pdf"
            maxFiles={5}
            icon={<ProofOfEnrollmentIcon />}
          />
          {files.length > 0 && (
            <S.FileCount>
              {files.length} arquivos enviados
              <br />
              (limite de 5 arquivos)
            </S.FileCount>
          )}
        </LinkBottom>
      </S.Container>
      <ModalComponent showModal={modal} setShowModal={setModal} />
    </Template>
  )
}
