import React from 'react'
import * as S from '../cardStyles'
import { Description } from '../Description'
import { theme } from '../../../../theme'
import { Footer } from '../Footer'

export const PaymentOptionCard = ({
  inconstantFlow,
  selected,
  options,
  price,
  id,
  setPaymentOptionId,
  monthlyInterest,
  formatPorcentage,
  totalValue,
  key,
}) => {
  return (
    <S.Card key={key} border={selected ? theme.colors.orangePrimary : theme.colors.orangeSecondary}>
      {inconstantFlow ? (
        <>
          <S.PriceLabel>Parcelas a partir de:</S.PriceLabel>
          <S.PayPrice isPayOption>
            <span>{parseFloat(options[0].price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
            /mês
          </S.PayPrice>
        </>
      ) : (
        <S.PayPrice isPayOption>
          <span>{parseFloat(price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>/mês
        </S.PayPrice>
      )}
      <S.Fee>
        {inconstantFlow
          ? `Valor total de ${parseFloat(totalValue).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`
          : `Juros de ${formatPorcentage(monthlyInterest)} ao mês.`}
      </S.Fee>
      <Description inconstantFlow={inconstantFlow} options={options} />
      <Footer
        selected={selected}
        labelFooter={selected ? 'Pagamento selecionado' : 'Selecionar pagamento'}
        onChange={() => setPaymentOptionId(id)}
        labelInput="Selecionar pagamento"
      />
    </S.Card>
  )
}
