import React from 'react'

export const SanarLogo = () => (
  <svg width="130" height="28" viewBox="0 0 130 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6008 10.9472C18.935 10.3619 18.1413 10.0171 17.3175 9.8982C16.3691 9.76155 15.9794 8.60747 16.6569 7.92915C18.3794 6.20455 18.5837 3.39678 16.9761 1.56401C15.2143 -0.444379 12.1563 -0.518347 10.299 1.34124L1.42062 10.23C-0.278155 11.9312 -0.512318 14.6919 1.03826 16.5294C1.78137 17.4097 2.76685 17.9331 3.79941 18.1004C4.74126 18.2532 5.12577 19.3934 4.45093 20.0691L4.4492 20.0708C2.72624 21.7954 2.52231 24.6032 4.12993 26.436C5.89177 28.4444 8.9497 28.5183 10.8071 26.6587L19.822 17.6329C21.6806 15.7724 21.6067 12.7112 19.6008 10.9472Z"
      fill="#F56B00"
    />
    <path
      d="M27.3234 19.5898L28.6696 17.4248C30.0141 18.2164 31.3586 18.4807 32.7562 18.4807C34.0735 18.4807 34.9704 17.9261 34.9704 17.0553C34.9704 16.1842 33.9689 15.7084 32.5454 15.3394L31.3586 15.0228C28.9595 14.389 27.3234 13.4656 27.3234 11.2214C27.3234 8.39674 29.5388 6.52203 32.9139 6.52203C35.049 6.52203 36.8683 6.971 38.266 7.92136L36.7638 10.271C35.6301 9.63737 34.4161 9.34664 33.1507 9.34664C31.8593 9.34664 30.9624 9.82208 30.9624 10.6405C30.9624 11.4852 31.8593 11.8811 33.0989 12.1986L34.2856 12.5412C36.7106 13.1748 38.635 14.2839 38.635 16.7915C38.635 19.4838 36.263 21.2798 32.7031 21.2798C30.5403 21.2794 28.616 20.7248 27.3234 19.5898Z"
      fill="white"
    />
    <path
      d="M52.5591 12.0648V21.0137H49.1054L49.0263 18.9806C48.0763 20.4591 46.4418 21.2779 44.7016 21.2779C41.7487 21.2779 39.7712 19.5087 39.7712 16.8689C39.7712 14.1234 42.1962 12.3282 45.8353 12.3282C46.8108 12.3282 47.8913 12.4338 48.9736 12.6448V12.3814C48.9736 10.4807 47.7082 9.34487 45.5454 9.34487C44.2009 9.34487 43.1204 9.74149 42.2235 10.4807L40.6681 8.369C42.1963 7.12836 43.9901 6.52063 46.4678 6.52063C50.1069 6.52106 52.5591 8.71153 52.5591 12.0648ZM48.9732 15.0741C48.0503 14.8895 47.1007 14.7839 46.2825 14.7839C44.5949 14.7839 43.5144 15.5764 43.5144 16.7901C43.5144 17.9515 44.2791 18.7176 45.7299 18.7176C47.3906 18.7172 48.946 17.397 48.9732 15.0741Z"
      fill="white"
    />
    <path
      d="M68.1131 12.0648V21.0137H64.5273V12.6453C64.5273 10.7978 63.2101 9.5832 61.2055 9.5832C59.2025 9.5832 57.8835 10.7978 57.8835 12.6453V21.0137H54.2976V6.78536H57.7513L57.8044 8.58051C58.885 7.23346 60.5466 6.52106 62.4449 6.52106C65.8465 6.52106 68.1131 8.7384 68.1131 12.0648Z"
      fill="white"
    />
    <path
      d="M82.5085 12.0648V21.0137H79.0544L78.9758 18.9806C78.0255 20.4591 76.3914 21.2779 74.6507 21.2779C71.6978 21.2779 69.7205 19.5087 69.7205 16.8689C69.7205 14.1234 72.1456 12.3282 75.7843 12.3282C76.7599 12.3282 77.8402 12.4338 78.9231 12.6448V12.3814C78.9231 10.4807 77.6576 9.34487 75.4944 9.34487C74.1502 9.34487 73.0699 9.74149 72.1729 10.4807L70.6175 8.369C72.1456 7.12836 73.9389 6.52063 76.4167 6.52063C80.0561 6.52106 82.5085 8.71153 82.5085 12.0648ZM78.9225 15.0741C77.9995 14.8895 77.0498 14.7839 76.2315 14.7839C74.5441 14.7839 73.4638 15.5764 73.4638 16.7901C73.4638 17.9515 74.2282 18.7176 75.679 18.7176C77.3397 18.7172 78.8952 17.397 78.9225 15.0741Z"
      fill="white"
    />
    <path
      d="M92.9742 6.52106L92.7376 9.82108C89.7847 9.82108 87.8334 11.2464 87.8334 13.3846V21.0141H84.2473V6.78536H87.7008L87.7541 8.68601C88.8877 7.2867 90.6804 6.52106 92.9742 6.52106Z"
      fill="white"
    />
    <path
      d="M113.144 6.78537V21.0141H109.69L109.637 19.2713C108.609 20.5915 107.054 21.2784 105.26 21.2784C102.044 21.2784 99.855 19.0602 99.855 15.7078V6.78537H103.441V15.1537C103.441 16.9748 104.681 18.1895 106.526 18.1895C108.345 18.1895 109.558 16.9748 109.558 15.1537V6.78537H113.144Z"
      fill="white"
    />
    <path
      d="M130 13.8335C130 18.295 127.02 21.2784 123.197 21.2784C121.035 21.2784 119.48 20.4072 118.478 18.9555V26.8225H114.891V6.78536H118.346L118.424 8.92311C119.426 7.41909 121.009 6.52106 123.197 6.52106C127.02 6.52106 130 9.42485 130 13.8335ZM126.282 13.86C126.282 11.2991 124.7 9.5832 122.379 9.5832C120.034 9.5832 118.478 11.2991 118.478 13.8868C118.478 16.4735 120.034 18.1895 122.379 18.1895C124.699 18.1895 126.282 16.4735 126.282 13.86Z"
      fill="white"
    />
  </svg>
)
