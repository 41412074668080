import React from 'react'

import * as S from './styles'

export const RadioSelect = ({ options, group, text, onSelectionChange, selectedValue }) => {
  const handleOnChange = (e) => {
    onSelectionChange(e.target.value)
  }

  return (
    <S.Container onChange={handleOnChange}>
      <S.Text>{text}</S.Text>
      {options.map(({ label, value }, index) => (
        <S.RadioWrapper key={`RadioButton_${label}_${value}_${index}`}>
          <S.RadioInput>
            <input type="radio" name={group} value={value} checked={value === selectedValue} onChange={handleOnChange} />
            <S.RadioControl />
          </S.RadioInput>
          <S.RadioLabel>{label}</S.RadioLabel>
        </S.RadioWrapper>
      ))}
    </S.Container>
  )
}
