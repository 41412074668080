import styled, { css } from 'styled-components'
import { theme } from '../../../theme'

export const Card = styled.div`
  box-sizing: border-box;
  border-radius: 8px;

  width: 311px;

  position: relative;

  padding: 15px 15px 59px 15px;

  margin: 20px 20px 0 0;

  transition: height 0.3s;

  ${({ height = 'auto', border = theme.colors.orangeSecondary }) =>
    css`
      height: ${height};
      border: 2px solid ${border};
    `}

  > hr {
    border: 1px solid ${theme.colors.grey2};
    margin-top: 15px;
  }

  ${theme.breakpoints?.down('sm')} {
    width: 800px;
    margin: 0 0 20px 0;
  }
`

export const CardTitle = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.015em;
  text-transform: uppercase;

  color: ${theme.colors.orangePrimary};
`

export const Subtitle = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.0075em;

  color: ${theme.colors.black};
`

export const Price = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 40px;

  color: ${theme.colors.grey3};

  margin-top: 15px;

  > span {
    font-weight: bold;
    font-size: 30px;
  }
`

export const PayPrice = styled(Price)`
  margin-top: 0px;
  margin-bottom: 15px;

  > span {
    color: ${theme.colors.orangePrimary};
    font-weight: bold;
    font-size: 30px;
  }
`

export const Fee = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12.8px;
  line-height: 20px;

  letter-spacing: 0.005em;

  color: ${theme.colors.greyPrimary};

  margin: 0 0 15px 0;
`

export const Description = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.005em;

  margin-top: 15px;

  color: ${theme.colors.grey3};
`

export const Footer = styled.div`
  width: 100%;
  height: 44px;
  position: absolute;
  bottom: 0;
  left: 0;

  border-radius: 0px 0px 4px 4px;
  display: flex;

  ${({ background = theme.colors.orangeSecondary }) =>
    css`
      background: ${background};
    `}
`

export const RadioSelect = styled.input.attrs({
  type: 'radio',
})`
  opacity: 0;
  width: 0;
  height: 0;
`

export const RadioWrapper = styled.label`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 10px;
  margin: 10px 10px 0 10px;
  font-size: 14px;

  cursor: pointer;
  color: ${theme.colors.black};
`

export const RadioControl = styled.span`
  display: grid;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.orangePrimary};
  background: ${theme.colors.white};

  place-items: center;
  input + &:before {
    content: '';
    width: 15px;
    height: 15px;
    box-shadow: inset 15px 15px ${theme.colors.orangePrimary};
    border-radius: 50%;
    transition: 180ms transform ease-in-out;
    transform: scale(0);
  }
  input:checked + &:before {
    transform: scale(1);
  }
`

export const RadioInput = styled.span`
  display: flex;
  & > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export const LabelFooter = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  cursor: pointer;

  ${({ width = 'auto', align = 'center', color = theme.colors.black }) =>
    css`
      width: ${width};
      justify-content: ${align};
      color: ${color};
    `}
`

export const Content = styled.div`
  transition: display 0.3s;

  ${({ display = 'block' }) =>
    css`
      display: ${display};
    `}
`

export const PriceLabel = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;

  letter-spacing: 0.0025em;

  color: ${theme.colors.grey3};
`

export const CollapsedTitle = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.005em;

  color: ${theme.colors.greyPrimary};

  margin-top: 15px;

  > span {
    font-weight: bold;
  }
`
