import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { ThemeProvider } from 'styled-components'

import './utils/prototypeFunctions'
import ErrorBoundary from './components/error-boundary'
import { TemplateProvider } from './components/template/provider'
import { Navigation } from './navigation'
import { GlobalStyles } from './styles'
import './config'
import { theme } from './theme'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <TemplateProvider>
            <GlobalStyles />
            <Navigation />
            <ToastContainer />
          </TemplateProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </AppContainer>,
    document.getElementById('provi-root'),
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./navigation', () => {
    render()
  })
}
