import { useState, useEffect } from 'react'

export const useIntroDocument = (creditPath, goToNextScreen) => {
  const [activeBtn, setActiveBtn] = useState('')

  useEffect(() => {
    if (creditPath?.readIntroDocument) setActiveBtn(creditPath?.readIntroDocument?.documentType)
  }, [creditPath])

  const handleBtn = (docType) => {
    setActiveBtn(docType)
    goToNextScreen({ data: docType })
  }

  return [activeBtn, handleBtn]
}
