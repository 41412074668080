import styled from 'styled-components'

import { theme } from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 5% 5% 60px 5%;

  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 70%;
    padding: 30px 5%;
  }
`

export const Wrapper = styled.div`
  padding-top: 30px;
  max-width: 600px;
`

export const CheckboxWrapper = styled.div`
  padding-top: 10px;
`

export const HideInputWrapper = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  max-width: 600px;
  padding-top: 30px;
`

export const ParagraphWrapper = styled.div`
  padding-top: 30px;
  max-width: 900px;
`

export const DateRangeMessage = styled.p`
  display: ${({ show }) => (show ? 'block' : 'none')};
  color: ${theme.colors.red500};
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
`
