import { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { PhoneResendRewrite, getCreditPath } from '../../services/api'
import { getSlugPartner } from '../../utils/getSlugPartner'

export const usePhoneVerification = (goToNextScreen, creditPath, isLoading, isGuarantorScreen) => {
  const [otpValue, setOtpValue] = useState('')
  const [countDown, setCountDown] = useState(60)
  const [buttonResend, setButtonResend] = useState(false)
  const [haveScreenNewNumber, setHaveScreenNewNumber] = useState(false)

  const interval = useRef(null)

  const verifyPhonePolling = async () => {
    const data = await getCreditPath(getSlugPartner())

    if (data?.readPhone?.verifiedAsUser) return goToNextScreen({})

    setTimeout(verifyPhonePolling, 15000)
  }

  useEffect(() => {
    verifyPhonePolling()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (otpValue.length === 4 && !isLoading && !isGuarantorScreen) {
      goToNextScreen({ data: otpValue })
      setOtpValue('')
    }
  }, [otpValue, goToNextScreen, isLoading, isGuarantorScreen])

  useEffect(() => {
    if (countDown > 0) {
      interval.current = setInterval(() => setCountDown((count) => count - 1), 1000)
    } else if (countDown === 0) {
      setButtonResend(true)
      clearInterval(interval.current)
      setCountDown(60)
    }

    return () => clearInterval(interval.current)
  }, [countDown])

  const onResendToken = async () => {
    try {
      await PhoneResendRewrite(creditPath?.readPhone?.data, getSlugPartner())
      setButtonResend(false)
      setCountDown(60)
    } catch (err) {
      toast.error('Erro, por favor tente novamente!', {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }
  }

  return [otpValue, setOtpValue, countDown, buttonResend, onResendToken, haveScreenNewNumber, setHaveScreenNewNumber]
}

export const getPhone = (isGuarantorScreen, phone, creditPath) => {
  if (isGuarantorScreen) return phone?.replace(/[^\d]+/g, '')

  return creditPath?.readPhone?.data
}

export const getPorcentage = (isGuarantorScreen, creditPath) => {
  if (isGuarantorScreen) return 46.66

  return creditPath?.progress?.percentageCompleted
}
