import React from 'react'

import * as S from './styles'

export const Checking = ({ subtitle, show }) => {
  return (
    <S.Container show={show}>
      <S.AnalyzingImg />
      <S.Title>Aguarde alguns instantes...</S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
    </S.Container>
  )
}
