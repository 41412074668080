import { useState } from 'react'

export const useIntroGuarantor = (goToNextScreen) => {
  const [modal, setModal] = useState(false)

  const onClickNextScreen = async () => {
    goToNextScreen()
  }

  return [onClickNextScreen, modal, setModal]
}
