import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { history } from '../../navigation/history'
import { uploadImages } from '../../services/api'
import { getSelfie, postSelfie } from '../../services/guarantor.api'

export const useUpload = (checkToken, setIsLoading) => {
  const { type } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [filesUploaded, setFilesNotUpload] = useState([])
  const [typeUpload, setType] = useState('')
  const [texts, setTexts] = useState({})
  const [files, setFiles] = useState([])
  const [alreadyUploaded, setAlreadyUploaded] = useState(false)
  const [video, setVideo] = useState('')
  const [isButtonModalDisabled, setIsButtonModalDisabled] = useState(false)

  checkToken()

  const getUploadImageArray = () => {
    if (files.length) return files
    if (filesUploaded.length) return filesUploaded
    if (video) return [video]

    return []
  }

  const onClickNextScreen = async () => {
    try {
      setIsLoading(true)

      const images = getUploadImageArray()
      const lastImage = images?.[images.length - 1]
      // new images are a Blob and always last in the array
      // old images (from endpoint) are an object with a url and a type
      const userJustAddedANewImage = !lastImage?.url

      // if the user is in the review flow and didn't upload a new image, we just send a POST request to trigger the ending of the flow
      if (alreadyUploaded && !userJustAddedANewImage) {
        await postSelfie(images)

        return history.push('/avalista/concluido')
      }

      const obj = {
        video: 'video',
        foto: 'photo',
      }

      // upload new image to firebase
      const urlsImgs = await uploadImages([lastImage])

      const urls = urlsImgs.map(({ url, ...upload }) => ({ data: url, ...upload, type: obj[type] }))

      await postSelfie(urls)

      setIsLoading(false)

      history.push('/avalista/concluido')
    } catch {
      toast.error('Ocorreu um erro ao enviar o arquivo. Tente novamente ou entre em contato com a Provi.')
    }
  }

  useEffect(() => {
    const getTexts = () => {
      const obj = {
        video: {
          title: 'Escolha a forma que você deseja enviar o seu vídeo',
          subtitle: 'Lembre-se que o vídeo precisa ter qualidade de imagem e de som',
          cardTitle: 'Gravar vídeo agora',
        },
        foto: {
          title: 'Escolha a forma que você deseja enviar a sua foto',
          subtitle: 'Lembre-se que a foto precisa ter qualidade e boa iluminação',
          cardTitle: 'Tirar foto agora',
        },
      }

      return setTexts(obj[type.toLowerCase()])
    }

    const getSelfies = async () => {
      setIsLoading(true)

      const { data } = await getSelfie()

      setFiles(data?.data?.map((file) => ({ url: file?.data, ...file })))

      if (data?.data?.length) {
        setType('upload')
        setAlreadyUploaded(true)
      }

      setIsLoading(false)
    }

    getSelfies()
    getTexts()
  }, [type, setIsLoading])

  const closeModal = () => {
    return setShowModal(false)
  }

  const openModal = () => {
    return setShowModal(true)
  }

  return [
    showModal,
    setShowModal,
    type.toLowerCase(),
    texts,
    filesUploaded,
    setFilesNotUpload,
    setType,
    files,
    setFiles,
    closeModal,
    typeUpload,
    video,
    setVideo,
    isButtonModalDisabled,
    setIsButtonModalDisabled,
    openModal,
    onClickNextScreen,
  ]
}
