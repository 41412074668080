import styled, { css } from 'styled-components'

import { theme } from '../../../../theme'

const footerTextCollapsedStyles = css`
  width: 100%;
  text-align: center;
`

export const Container = styled.div`
  display: flex;
  height: ${({ isCollapsed }) => (isCollapsed ? '170px' : '500px')};
  flex-direction: column;
  justify-content: space-between;
  max-width: 310px;
  width: 100%;
  margin: 0 15px 15px 0;

  border: 2px solid ${({ isSelected }) => (isSelected ? theme.colors.orangePrimary : theme.colors.orangeSecondary)};
  border-radius: 12px;

  transition: height 0.3s ease-out;
  overflow: hidden;

  @media (max-width: ${theme.breakpoints.md}px) {
    margin: 15px auto;
  }
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
  padding: 0 10px;
  border-radius: 0px 0px 10px 10px;
  background-color: ${({ isSelected }) => (isSelected ? theme.colors.orangePrimary : theme.colors.orangeSecondary)};
  cursor: pointer;

  & > p {
    color: ${({ isSelected }) => (isSelected ? theme.colors.white : theme.colors.black)};
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;

    ${({ isCollapsed }) => isCollapsed && footerTextCollapsedStyles}
  }
`

export const RadioIcon = styled.div`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.orangePrimary};
  background-color: ${theme.colors.white};
  margin-right: 10px;

  &:before {
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${theme.colors.orangePrimary};
  }
`

export const CollapsedContent = styled.div`
  display: ${({ isCollapsed }) => (isCollapsed ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  padding: 15px;

  & > label {
    color: ${({ isSelected }) => (isSelected ? theme.colors.orangePrimary : theme.colors.orangeSecondary)};
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
  }

  & > p {
    color: ${theme.colors.greyPrimary};
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin-top: 10px;
  }
`

export const ExpandedContent = styled.div`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'flex')};
  flex-direction: column;
  padding: 15px;
  width: 100%;

  & > h1,
  h2,
  h3 {
    font-size: 16px;
    line-height: 26px;
  }

  & > h2,
  h3,
  p {
    font-weight: 500;
  }

  & > h1 {
    color: ${({ isSelected }) => (isSelected ? theme.colors.orangePrimary : theme.colors.orangeSecondary)};
    font-weight: 700;
  }

  & > hr {
    margin: 15px 0;
    background-color: ${theme.colors.grey2};
    height: 2px;
    border-width: 0;
  }

  & > h3 {
    color: ${theme.colors.greyPrimary};

    & > strong {
      font-size: 34px;
      line-height: 44px;
    }
  }

  & > strong,
  p {
    color: ${theme.colors.greyPrimary};
    font-size: 14px;
    line-height: 24px;
    margin-top: 15px;
  }
`
