import { InputFieldV3 } from '@provi/provi-components'
import React from 'react'

import * as S from './styles'

export const InputSanar = (props) => {
  return (
    <S.StyledInput>
      <InputFieldV3 {...props} />
    </S.StyledInput>
  )
}
