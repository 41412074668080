import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { ROOT_URL } from '../constants'
import { setLogger } from '../utils/logger'

const api = axios.create({
  baseURL: ROOT_URL.passwordless,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    setLogger()
    return Promise.reject(error)
  },
)

export const validateToken = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      api.defaults.headers.Authorization = token
      const response = await api.get('/auth')
      resolve(response)
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'errorRequestAuth',
        message: 'ERROR INFO ' + err,
        level: Sentry.Severity.Warning,
      })
      reject(err)
    }
  })
}
