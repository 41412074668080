import { useEffect } from 'react'
import { LOCALSTORAGE_TOKENS } from '../../constants'
import { validateToken } from '../../services/auth'
import { navigateWithParams } from '../../utils/navigateWithParams'
import { history } from '../../navigation/history'

export const useValidateToken = (props) => {
  const { match, setIsLoading } = props
  const pathName = window?.location?.pathname

  useEffect(() => {
    const getData = async () => {
      const { token, partner } = match.params
      const params = new URLSearchParams(history.location.search)
      const campaign = params.get('campaignSlug')
      try {
        const { data } = await validateToken(token)
        localStorage.setItem(LOCALSTORAGE_TOKENS.dataUser, JSON.stringify(data))
        localStorage.setItem(LOCALSTORAGE_TOKENS.token, token)

        if (campaign) {
          navigateWithParams(`/${data?.partnerSlug}/cpf?campanha=${campaign}`)
          return false
        }
        if (!['sanar', 'sanarup-financiamento'].includes(data?.partnerSlug)) {
          navigateWithParams(`/${partner}/token-invalido`)
        }
      } catch {
        navigateWithParams(`/${partner}/token-invalido`)
      }
    }
    getData()
  }, [match.setIsLoading, match.params, setIsLoading, pathName])
}
