import React from 'react'

import { Modal } from '../../../../components/Modal'
import Button from '../../../../components/Button'

import * as S from './styles'

export const WarningModal = ({ show, setShow, handleNextButton }) => {
  return (
    <Modal show={show} title="Você está avançando de etapa!" onDismiss={() => setShow(false)} width="400px">
      <S.ModalText>Lembre-se: ao avançar você não poderá mais alterar as informações que você inseriu até agora.</S.ModalText>

      <S.ModalWrapper>
        <Button text="Avançar" width="300px" mobileWidth="100%" onClick={() => handleNextButton()} />
      </S.ModalWrapper>
    </Modal>
  )
}
