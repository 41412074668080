import React from 'react'
import { UploadIcon } from '../../../assets/svg/UploadIcon'
import UploadDesktop from '../../../components/UploadDesktop'
import { WebcamWrapper } from '../../../components/webcam/index'
import { useUpload } from '../../../utils/useUpload'

export const Desktop = ({ files, setFiles, filesUploaded, type, setType }) => {
  const [onUpload, preview] = useUpload(setFiles)

  return (
    <>
      <WebcamWrapper setFilesNotUpload={setFiles} type={type} setType={setType} />
      <br />
      <UploadDesktop
        onUpload={onUpload}
        file={preview}
        filesUploaded={filesUploaded[filesUploaded.length - 1]}
        type={type}
        icon={<UploadIcon />}
      />
    </>
  )
}
