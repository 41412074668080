import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import { TemplateContext } from '../../components/template/context'
import { Subtitle } from '../../components/Texts'
import { LinkBottom } from '../../components/LinkBottom'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { usePreDocument } from './hooks'
import { ModalComponent } from './Modal'
import { history } from '../../navigation/history'

export const PreDocument = () => {
  const { isMobile, checkToken } = useContext(TemplateContext)
  const [modal, setModal] = usePreDocument()
  checkToken()

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar onClickBack={() => history.push('/avalista/confirmacao-email')} percent={53.33} title="DOCUMENTOS" />
        <LinkBottom
          isMobile={isMobile}
          onClickLink={[() => setModal('video'), () => setModal('security')]}
          onClick={() => history.push('/avalista/selecionar-documento')}
          textLink={['O que é a foto ou vídeo de confirmação?', 'Meus dados estão seguros?']}
          text="Enviar documentos"
        >
          <S.Wrapper>
            <Subtitle>Para concluir a sua parte, você vai precisar enviar:</Subtitle>
          </S.Wrapper>
          <S.List>
            <S.Item>RG/CNH</S.Item>
            <S.Item>Foto ou vídeo de confirmação</S.Item>
          </S.List>
        </LinkBottom>
      </S.Container>
      <ModalComponent showModal={['video', 'security'].includes(modal)} setShowModal={setModal} modalType={modal} />
    </Template>
  )
}
