import React from 'react'
import { ModalText } from '../../../../screens/cpf/components/CPFModal/styles'

export const ModalContent = ({ modalType }) => {
  const obj = {
    security: (
      <ModalText>
        A Provi pede essas informações para que não haja fraude em seus processos de financiamento. Asseguramos seus dados e do
        solicitante de crédito.
      </ModalText>
    ),
    video: (
      <>
        <ModalText>Essa parte do processo é muito importante para a sua segurança digital.</ModalText>
        <ModalText>
          Através do vídeo selfie ou da foto selfie, nós conseguimos confirmar que é você mesmo quem está preenchendo essa
          solicitação, e assim evitamos qualquer tipo de fraude.
        </ModalText>
      </>
    ),
  }

  return obj[modalType] || <></>
}
