import React, { useContext } from 'react'

import { TemplateContext } from '../../components/template/context'
import Button from '../../components/Button'
import { Template } from '../../components/template'

import { SanarProgressBar } from '../../components/SanarProgressBar'

import * as S from './styles'

export const ThirdGuarantorNegativeFeedback = () => {
  const { creditPath } = useContext(TemplateContext)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar title="Avalista" percent={creditPath?.progress?.percentageCompleted} canBack={false} />

        <S.Wrapper>
          <S.Title>Não conseguimos aprovar suas indicações de avalistas.</S.Title>
        </S.Wrapper>

        <S.Wrapper>
          <S.Description>
            Caso deseje, você poderá tentar uma última vez. Para isso, entre em contato com a gente.{' '}
          </S.Description>
        </S.Wrapper>

        <S.Wrapper>
          <Button
            text="Entrar em contato"
            footerButton
            onClick={() =>
              window
                .open(
                  'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                  '_blank',
                )
                .focus()
            }
          />
        </S.Wrapper>
      </S.Container>
    </Template>
  )
}
