import React from 'react'
import * as S from './styles'
import { ModalText } from '../../../screens/cpf/components/CPFModal/styles'
import { GuarantorPhoto } from '../../../assets/svg/GuarantorPhoto'

export const Photo = () => {
  return (
    <>
      <S.Title>Segure o papel e tire uma selfie:</S.Title>
      <br />
      <ModalText>Tire uma foto de você mesmo, segurando o papel escrito em letras de forma.</ModalText>
      <S.ImageWrapper>
        <GuarantorPhoto />
      </S.ImageWrapper>
    </>
  )
}
