import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { history } from '../../navigation/history'
import { getGuarantorInfo, postSendPhone, postVerifyPhone } from '../../services/guarantor.api'

export const useGuarantor = (isGuarantorScreen, checkToken, otpValue, isLoading) => {
  const [phone, setPhone] = useState('')
  const [sended, setSended] = useState(false)

  const sendPhone = async () => {
    try {
      await postSendPhone()

      setSended(true)
    } catch (error) {
      toast.error('Não foi possivel enviar o SMS 😔. Espere alguns minutos antes de tentar novamente')
    }
  }

  useEffect(() => {
    const getData = async () => {
      const response = await getGuarantorInfo()
      const { phone: phoneGuarantor, phoneVerified } = response.data

      if (phoneVerified) {
        history.push('/avalista/endereco')
      } else if (!sended) {
        isGuarantorScreen && sendPhone()
      }

      setPhone(phoneGuarantor)
    }

    if (isGuarantorScreen) {
      getData()
      checkToken()
    }
  }, [isGuarantorScreen, checkToken, sended])

  useEffect(() => {
    const onComplete = async () => {
      if (otpValue.length === 4 && !isLoading && isGuarantorScreen) {
        const response = await postVerifyPhone(otpValue)
        const { verified } = response.data

        if (verified) {
          history.push('/avalista/endereco')
        }
      }
    }

    if (isGuarantorScreen) onComplete()
  }, [otpValue, isLoading, isGuarantorScreen])

  return [phone, sendPhone]
}
