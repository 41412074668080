import React, { useContext } from 'react'

import * as S from './styles'
import Button from '../../components/Button'
import { SanarLogo2 } from '../../assets/svg/SanarLogo2'
import { ProviLogo } from '../../assets/svg/ProviLogo'
import { MadePossibleProvi2 } from '../../assets/svg/MadePossibleProvi2'
import { TemplateContext } from '../../components/template/context'
import { useGuarantorIntroduction } from './hooks'
import { LargeText, Subtitle } from '../../components/Texts'

import { InputSanar } from '../../components/InputSanar'

export const GuarantorIntroduction = () => {
  const { isMobile } = useContext(TemplateContext)
  const [userName, onClick, cpf, setCpf] = useGuarantorIntroduction()

  return (
    <S.Container>
      <S.Header>
        <SanarLogo2 />
        <S.ContainerLogo>{isMobile ? <ProviLogo /> : <></>}</S.ContainerLogo>
      </S.Header>
      <S.Content>
        <LargeText limitWidth>
          Você está sendo convidado {userName && `por ${userName}`}
          <br /> para ser avalista de crédito na Provi.
        </LargeText>
        <S.SubTitle>
          A Provi é uma fintech que está revolucionando o <br />
          mercado de empréstimos e financiamentos <br /> educacionais no Brasil.
        </S.SubTitle>
        <br />
        <S.SubTitle>
          Sendo assim, o crédito {userName && `de ${userName}`} não é um empréstimo <br /> qualquer. Nossos financiamentos têm o
          intuito de <br /> impulsionar carreiras.
        </S.SubTitle>
        <br />
        <Subtitle>Digite o seu CPF para acessar</Subtitle>
        <S.InputLabel>CPF</S.InputLabel>
        <InputSanar
          placeholder="000.000.000-00"
          mask="999.999.999-99"
          width="50%"
          onChange={(e) => setCpf(e.target.value)}
          value={cpf}
        />
        <br />
        <Button footerButton text="Avançar" onClick={onClick} disabled={cpf.length < 14} />
        <S.ContainerLogoProvi>
          <MadePossibleProvi2 />
        </S.ContainerLogoProvi>
      </S.Content>
    </S.Container>
  )
}
