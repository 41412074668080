import styled from 'styled-components'

export const ModalText = styled.p`
  font-size: 14px;
  line-height: 24px;
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
`

export const ModalStrong = styled.p`
  font-size: 15px;
  font-weight: 600;
  padding: 20px 0;
`
