import styled from 'styled-components'

import { theme } from '../../theme'
import AnalyzingImgPath from '../../assets/images/analyzing-website.gif'

export const Container = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 94vh;
  padding: 5% 5% 40px 5%;

  @media (min-width: ${theme.breakpoints.md}px) {
    justify-content: flex-start;
  }
`

export const Title = styled.h2`
  color: ${theme.colors.orangePrimary};
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 10px;
  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 26px;
  }
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  @media (min-width: ${theme.breakpoints.md}px) {
    font-size: 22px;
  }
`

export const AnalyzingImg = styled.img.attrs({
  src: AnalyzingImgPath,
  alt: 'Analizando, aguarde...',
})`
  width: 90%;
  max-width: 500px;
  height: auto;
`
