import { LOCALSTORAGE_TOKENS } from '../constants'
import { history } from '../navigation/history'

/**
 * @description Transform any object into query params
 * @function
 * @name transformObjectIntoQueryParams
 * @param {object} data
 * @returns {string}
 */
const transformObjectIntoQueryParams = (data) => {
  return Object.keys(data)
    .map((key) => `${key}=${data[key]}`)
    .join('&')
}

/**
 * @description Navigate to route passed with params or without params
 * @function
 * @name navigateWithParams
 * @param {string} route
 */
export const navigateWithParams = (route) => {
  const dataUser = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKENS.dataUser))
  const objQueryParam = dataUser && dataUser.utmSourcesCode ? { ...dataUser.utmSourcesCode } : null

  objQueryParam
    ? history.push({
        pathname: route,
        search: transformObjectIntoQueryParams(objQueryParam),
      })
    : history.push(route)
}
