import { useEffect, useState } from 'react'
import { uploadImages } from '../../services/api'

export const useProofOfEnrollment = (creditPath, goToNextScreen, setIsLoading) => {
  const [files, setFiles] = useState([])
  const [filesUploaded, setFilesUploaded] = useState([])
  const [modal, setModal] = useState(false)

  useEffect(() => {
    setFilesUploaded(creditPath?.readProofOfEnrollment?.map(({ data, ...file }) => ({ url: data, ...file })) || [])
  }, [creditPath])

  const onClickNextScreen = async () => {
    setIsLoading(true)
    const urls = await uploadImages(files)

    goToNextScreen({
      data: [...filesUploaded, ...urls],
    })
  }

  return [onClickNextScreen, files, setFiles, filesUploaded, modal, setModal]
}
