import styled from 'styled-components'

export const SelectorWrapper = styled.div``

export const SelectContent = styled.select`
  width: 100%;
  height: 48px;
  border: 2px solid #cfcfcf;
  border-radius: 3px;
  padding: 8px;
  font-family: 'Montserrat', sans-serif;
  background: ${({ theme }) => theme.colors.white100};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;

  :focus,
  :focus-visible,
  :focus-within {
    outline: 2px auto ${({ theme }) => theme.colors.orangePrimary};
  }

  /* Change default <select /> arrow */
  background: url("data:image/svg+xml,<svg height='20px' width='20px' viewBox='0 0 20 20' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path></svg>")
    no-repeat;
  background-position: calc(100% - 8px) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;
    font-weight: 500;
  }
`

export const LabelSelector = styled.p`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 13.2px;
    line-height: 20px;
    letter-spacing: 0.005em;
  }
`
