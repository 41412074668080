import React, { useContext, useEffect } from 'react'

import * as S from './styles'
import Button from '../../components/Button'
import { SanarLogo2 } from '../../assets/svg/SanarLogo2'
import { ProviLogo } from '../../assets/svg/ProviLogo'
import { MadePossibleProvi2 } from '../../assets/svg/MadePossibleProvi2'
import { TemplateContext } from '../../components/template/context'
import { getSlugPartner } from '../../utils/getSlugPartner'
import { ROOT_URL } from '../../constants'

export const LinkExpired = () => {
  const { isMobile } = useContext(TemplateContext)

  useEffect(() => {
    localStorage.clear()
  }, [])

  return (
    <S.Container>
      <S.Header>
        <SanarLogo2 />
        <S.ContainerLogo>{isMobile ? <ProviLogo /> : <></>}</S.ContainerLogo>
      </S.Header>
      <S.Content>
        <S.Title>Seu Link expirou!</S.Title>
        <S.SubTitle>
          Clique no botão abaixo para receber outro link {!isMobile ? <br /> : <></>} de acesso e iniciar uma nova sessão.
        </S.SubTitle>
        <Button footerButton text="Avançar" onClick={() => (window.location.href = `${ROOT_URL.login}/${getSlugPartner()}`)} />
      </S.Content>
      <S.ContainerLogoProvi>{!isMobile ? <MadePossibleProvi2 /> : <></>}</S.ContainerLogoProvi>
    </S.Container>
  )
}
