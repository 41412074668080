import React, { forwardRef } from 'react'

import * as S from './styles'

export const Paragraph = forwardRef((props, ref) => {
  return (
    <S.Paragraph ref={ref} {...props}>
      {props.children}
    </S.Paragraph>
  )
})
