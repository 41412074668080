import React from 'react'
import * as S from './styles'

export const MobileCard = ({ title, mobileImage, onClickButton }) => {
  return (
    <S.Card id={title} onClick={onClickButton}>
      <S.CardTitle>{title}</S.CardTitle>
      {mobileImage}
    </S.Card>
  )
}
