import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom'

import { theme } from '../../../theme'

export const Link = styled(ReactRouterLink)`
  color: ${theme.colors.orangePrimary};
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;

  padding: 10px 0;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${theme.breakpoints.xs}px) {
    font-size: 16px;
  }
`
