export const formatCurrancy = (value) => {
  if (typeof value === 'string') value = Number(value)

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  })

  return formatter.format(value)
}
