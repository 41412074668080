import React, { useContext } from 'react'

import { Template } from '../../components/template'
import * as S from './styles'
import { InputSanar } from '../../components/InputSanar'
import Button from '../../components/Button'
import { useEmail } from './hooks'
import { SanarProgressBar } from '../../components/SanarProgressBar'
import { history } from '../../navigation/history'
import { TemplateContext } from '../../components/template/context'
import { Title } from '../../components/Texts'

export const Email = () => {
  const { checkToken, setIsLoading } = useContext(TemplateContext)
  const [email, setEmail, onClickNextScreen, data, disabled] = useEmail(checkToken, setIsLoading)

  return (
    <Template haveHeader>
      <S.Container>
        <SanarProgressBar onClickBack={() => history.push('/avalista/confirmacao')} title="E-mail" percent={26.66} />
        <S.ContainerInputs>
          <Title>Qual o seu e-mail?</Title>
          <S.Subtitle>
            Precisa ser um e-mail pessoal de {data?.firstName}. <br /> Você vai precisar verificar esse e-mail.
          </S.Subtitle>
          <br />
          <InputSanar
            label="E-mail"
            width="370px"
            mobileWidth="370px"
            placeholder="cecilia@email.com.br"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </S.ContainerInputs>
        <Button text="Avançar" disabled={disabled} footerButton onClick={onClickNextScreen} />
      </S.Container>
    </Template>
  )
}
