export const radioOptions = [
  { value: 'CONTA_CORRENTE', label: 'Conta corrente' },
  { value: 'CONTA_POUPANCA', label: 'Conta poupança' },
]

export const messageLimitError = 'Limite máximo de 5 tentativas de registro de contas bancárias atingido'

export const statusCodeTimeout = 408

export const messages = ['Agência inválida', 'Conta inválida', 'Tipo de conta inválido', 'Código do banco inválido']
