import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-KKNCD8N',
}

/**
 * @description Init sanar GTM
 * @function
 * @name initProviGtm
 */
export const initProviGtm = () => {
  TagManager.initialize(tagManagerArgs)
}
