const sanarUp = {
  titles: {
    unapproved: 'Poxa, não podemos completar o seu pedido',
    pendingPayment: 'Você tem pagamentos em atraso',
    notMedic: 'A Mesada só está disponível para alunos de medicina',
  },

  subtitles: {
    underage: 'A Mesada só está disponível para pessoas maiores de 18 anos.',
    unapproved: 'A Mesada não está disponível para você. Sentimos muito por não poder te ajudar nesse momento.',
    pendingPaymentOne:
      'Só é possível solicitar sua Mesada depois de pagar o valor em aberto. Entre em contato pelo WhatsApp (11) 95771-2414 para regularizar a sua situação.',
    pengingPaymentTwo:
      'Você precisa pagar o valor em aberto. Para regularizar a sua situação, entre em contato pelo WhatsApp (11) 95771-2414.',
    notMedic: `Por enquanto, a Mesada de Sanar UP é exclusiva para alunos de medicina entre o 4º e o 5º ano da graduação ou na residência, por isso não podemos completar seu pedido.`,
    notMedic2: `Você pode entrar em contato pelo WhatsApp (11) 95771-2414 se tiver alguma dúvida.`,
  },
}

const sanarUpFinanciamento = {
  titles: {
    unapproved: 'Poxa, não podemos completar o seu pedido',
    pendingPayment: 'Você tem pagamentos em atraso',
    notMedic: 'O Financiamento só está disponível para alunos de medicina',
  },

  subtitles: {
    underage: 'O Financiamento só está disponível para pessoas maiores de 18 anos.',
    unapproved: 'O Financiamento não está disponível para você. Sentimos muito por não poder te ajudar nesse momento.',
    pendingPaymentOne:
      'Só é possível solicitar seu Financiamento depois de pagar o valor em aberto. Entre em contato pelo WhatsApp (11) 95771-2414 para regularizar a sua situação.',
    pengingPaymentTwo:
      'Você precisa pagar o valor em aberto. Para regularizar a sua situação, entre em contato pelo WhatsApp (11) 95771-2414.',
    notMedic: `Por enquanto, o Financiamento está disponível apenas para estudantes de medicina do 6° ano da graduação, por isso não podemos completar seu pedido.`,
    notMedic2: `Você pode entrar em contato pelo WhatsApp (11) 95771-2414 se tiver alguma dúvida.`,
  },
}

export { sanarUp, sanarUpFinanciamento }
