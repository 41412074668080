import React from 'react'
import ArrowForward from '@material-ui/icons/ArrowForward'
import * as S from './styles'

const Button = ({
  onClick = () => {},
  text = '',
  width = '184px',
  mobileWidth = '148px',
  height = '48px',
  disabled = false,
  icon = false,
  footerButton = false,
  buttonProps = {},
  ...props
}) => {
  return (
    <S.ButtonContainer
      buttonWidth={width}
      mobileWidth={mobileWidth}
      buttonHeight={height}
      onClick={disabled ? null : onClick}
      disabled={disabled}
      footerButton={footerButton}
      {...props}
    >
      <S.ButtonWrapper
        variant="contained"
        endIcon={icon && <ArrowForward />}
        disableElevation
        disableFocusRipple
        disabled={disabled}
        {...buttonProps}
      >
        {text}
      </S.ButtonWrapper>
    </S.ButtonContainer>
  )
}

export default Button
