import React, { forwardRef } from 'react'

import { Label, Box, Mark, Container } from './styles'

const CheckboxWrapper = forwardRef(
  (
    {
      text = 'Checked',
      name = 'checkbox',
      value = 'Checked',
      selectedColor = '#F56B00',
      textColor = '',
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const { readOnly, onChange } = props

    return (
      <Container>
        <Label
          disabled={disabled}
          onClick={readOnly ? null : onChange}
          htmlFor={name}
          selectedColor={selectedColor}
          textColor={textColor}
          data-testid="test-label"
        >
          <Box disabled={disabled} ref={ref} name={name} value={value} {...props} />
          <span data-testid="test-span">{text}</span>

          <Mark selectedColor={selectedColor} />
        </Label>
      </Container>
    )
  },
)

export { CheckboxWrapper as CheckBox }
