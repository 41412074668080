import React from 'react'

import { Modal } from '../../../../components/Modal'
import Button from '../../../../components/Button'

import * as S from './styles'

export const InvalidDateModal = ({ show, setShow }) => {
  return (
    <Modal show={show} title="Parece que algo está errado" onDismiss={() => setShow(false)}>
      <S.ModalText>
        A data prevista para você se formar não corresponde ao esperado para a etapa que você está cursando.
      </S.ModalText>
      <S.ModalText>Vamos te ajudar a continuar. Fale com a gente direto no WhatsApp:</S.ModalText>
      <S.ModalWrapper>
        <Button
          text="Entrar em contato"
          width="220px"
          mobileWidth="220px"
          onClick={() =>
            window
              .open(
                'https://api.whatsapp.com/send?phone=5511957712414&text=Oi%2C%20Provi%21%20Pode%20me%20ajudar%20?',
                '_blank',
              )
              .focus()
          }
        />
      </S.ModalWrapper>
    </Modal>
  )
}
